// Descrição das classes do CNJ a partir do identificador
//
// Criado a partir do esquema sgt_consulta, utilizando a seguinte query:
// SELECT CONCAT("\"",i.cod_item,"\":\"",trim(i.nome),"|",coalesce(i.cod_item_pai, ''),"|",trim(coalesce(c.polo_ativo, '')),"|",trim(coalesce(c.polo_passivo, '')),"\",") FROM sgt_consulta.itens i left join sgt_consulta.classes c on i.cod_item = c.cod_classe where i.tipo_item = 'C'
//
export default {
  nome: function(id) {
    var a = this.classes[id];
    if (!a) return;
    return a.split("|")[0];
  },
  pai: function(id) {
    var a = this.classes[id];
    if (!a) return;
    return a.split("|")[1];
  },
  poloAtivo: function(id) {
    var a = this.classes[id];
    if (!a) return;
    var r = a.split("|")[2];
    return r !== "" ? r : undefined;
  },
  poloPassivo: function(id) {
    var a = this.classes[id];
    if (!a) return;
    var r = a.split("|")[3];
    return r !== "" ? r : undefined;
  },
  nomeCompleto: function(id) {
    var s;
    for (;;) {
      var nome = this.nome(id);
      if (!nome) return;
      var pai = this.pai(id);
      if (s) s = nome + " - " + s;
      else s = nome;
      if (pai === "") return s;
      id = pai;
    }
  },
  classes: {
    "2": "PROCESSO CÍVEL E DO TRABALHO|||",
    "5": "SUPERIOR TRIBUNAL DE JUSTIÇA|||",
    "7": "Procedimento Comum|1107|Autor|Réu",
    "22": "Procedimento Sumário|1107|Autor|Réu",
    "26": "Procedimentos Especiais|1107||",
    "27": "Procedimentos Especiais de Jurisdição Contenciosa|26||",
    "28": "Anulação e Substituição de Títulos ao Portador|27|Autor|Réu",
    "29": "Apreensão e Depósito de Coisa Vendida com Reserva de Domínio|27|Autor|Réu",
    "30": "Arrolamento Comum|27|Requerente|Requerido",
    "31": "Arrolamento Sumário|27|Requerente|Requerido",
    "32": "Consignação em Pagamento|27|Autor|Réu",
    "34": "Demarcação / Divisão|27|Autor|Réu",
    "35": "Depósito|27|Autor|Réu",
    "37": "Embargos de Terceiro|27|Embargante|Embargado",
    "38": "Habilitação|27|Requerente|Requerido",
    "39": "Inventário|27|Requerente|Inventariado",
    "40": "Monitória|27|Autor|Réu",
    "41": "Nunciação de Obra Nova|27|Nunciante|Nunciado",
    "44": "Prestação de Contas - Oferecidas|27|Autor|Réu",
    "45": "Ação de Exigir Contas|27|Autor|Réu",
    "46": "Restauração de Autos|27|Autor|Réu",
    "47": "Ação Rescisória|27|Autor|Réu",
    "48": "Sobrepartilha|27|Requerente|Requerido",
    "49": "Usucapião|27|Autor|Réu",
    "50": "Procedimentos Especiais de Jurisdição Voluntária|26||",
    "51": "Abertura, Registro e Cumprimento de Testamento|50|Requerente|",
    "52": "Alienação Judicial de Bens|50|Requerente|Interessado",
    "53": "Arrecadação das Coisas Vagas|50|Requerente|",
    "54": "Confirmação de Testamento|50|Requerente|",
    "55": "Declaração de Ausência|50|Requerente|Interessado",
    "56": "Especialização de Hipoteca Legal|50|Requerente|Interessado",
    "57": "Herança Jacente|50|Requerente|",
    "58": "Interdição|50|Requerente|Requerido",
    "59": "Organização e Fiscalização de Fundação|50|Requerente|Interessado",
    "60": "Separação Consensual|50|Requerentes|",
    "61": "Tutela e Curatela -  Nomeação|50|Requerente|Interessado",
    "62": "Procedimentos Regidos por Outros Códigos, Leis Esparsas e Regimentos|26||",
    "63": "Ação Civil Coletiva|62|Autor|Réu",
    "64": "Ação Civil de Improbidade Administrativa|62|Autor|Réu",
    "65": "Ação Civil Pública|62|Autor|Réu",
    "66": "Ação Popular|62|Autor|Réu",
    "69": "Alimentos - Lei Especial Nº 5.478/68|62|Autor|Réu",
    "72": "Alteração do Regime de Bens|62|Interessado|Interessado",
    "74": "Alvará Judicial - Lei 6858/80|62|Requerente|Interessado",
    "76": "Apreensão de Embarcações|62|Requerente|Requerido",
    "77": "Arribadas Forçadas|62|Requerente|Requerido",
    "79": "Regulação de Avaria Grossa|62|Requerente|Requerido",
    "80": "Avarias|62|Requerente|Requerido",
    "81": "Busca e Apreensão em Alienação Fiduciária|62|Autor|Réu",
    "82": "Cancelamento de Naturalização|62|Autor|Denunciado",
    "83": "Cautelar Fiscal|175|Requerente|Requerido",
    "84": "Cobrança de Cédula de Crédito Industrial|62|Autor|Réu",
    "85": "Compromisso Arbitral|62|Autor|Réu",
    "86": "Consignatória de Aluguéis|62|Autor|Réu",
    "87": "Conversão de Separação Judicial em Divórcio|62|Requerente|Requerido",
    "88": "Correição Parcial ou Reclamação Correicional|1198|Corrigente|Corrigido",
    "89": "Depósito da Lei 8. 866/94|62|Autor|Réu",
    "90": "Desapropriação|62|Autor|Réu",
    "91": "Desapropriação Imóvel Rural por Interesse Social|62|Autor|Réu",
    "92": "Despejo|62|Autor|Réu",
    "93": "Despejo por Falta de Pagamento|62|Autor|Réu",
    "94": "Despejo por Falta de Pagamento Cumulado Com Cobrança|62|Autor|Réu",
    "95": "Direta de Inconstitucionalidade|62|Autor|",
    "96": "Discriminatória|62|Autor|Réu",
    "97": "Dissolução e Liquidação de Sociedade|62|Autor|Réu",
    "98": "Divórcio Consensual|62|Requerente|Requerente",
    "99": "Divórcio Litigioso|62|Requerente|Requerido",
    "100": "Dúvida|62|Requerente|Interessado",
    "107": "Expropriação da Lei 8.257/91|62|Expropriante|Expropriado",
    "108": "Falência de Empresários, Sociedades Empresáriais, Microempresas e Empresas de Pequeno Porte|62|Autor|Réu",
    "110": "Habeas Data|62|Impetrante|Impetrado",
    "111": "Habilitação de Crédito|62|Requerente|Requerido",
    "112": "Homologação de Transação Extrajudicial|62|Requerentes|Requerentes",
    "113": "Imissão na Posse|62|Autor|Autor",
    "114": "Impugnação de Crédito|62|Impugnante|Impugnado",
    "115": "Inquérito Extrajudicial|62|Requerente|Requerido",
    "118": "Mandado de Injunção|62|Impetrante|Impetrado",
    "119": "Mandado de Segurança Coletivo|62|Impetrante|Impetrado",
    "120": "Mandado de Segurança|62|Impetrante|Impetrado",
    "121": "Naturalização|62|Requerente|Interessado",
    "122": "Opção de Nacionalidade|62|Requerente|Não Consta",
    "123": "Averiguação de Paternidade|62|Requerente|Requerido",
    "124": "Pedido de Resposta ou Retificação da Lei de Imprensa|62|Autor|Réu",
    "127": "Protesto Formado a Bordo|62|Requerente|Requerido",
    "128": "Recuperação Extrajudicial|62|Requerente|",
    "129": "Recuperação Judicial|62|Autor|Réu",
    "134": "Registro Torrens|62|Requerente|",
    "135": "Relatório Falimentar|62|Relatante|",
    "136": "Remição do Imóvel Hipotecado|62|Autor|Réu",
    "137": "Renovatória de Locação|62|Autor|Réu",
    "138": "Restituição de Coisa ou Dinheiro na Falência do Devedor Empresário|62|Requerente|Requerido",
    "140": "Revisional de Aluguel|62|Autor|Réu",
    "141": "Separação Litigiosa|62|Autor|Réu",
    "142": "Sonegados|62|Autor|Réu",
    "143": "Suprimento de Idade e/ou Consentimento|62|Autor|Réu",
    "144": "Suspensão de Liminar ou Antecipação de Tutela|62|Autor|Réu",
    "145": "Suspensão de Execução de Sentença|62|Autor|Réu",
    "150": "Procedimento de Liquidação|1106||",
    "151": "Liquidação por Arbitramento|150|Autor|Réu",
    "152": "Liquidação de Sentença pelo Procedimento Comum|150|Autor|Réu",
    "153": "Liquidação Provisória por Arbitramento|150|Requerente|Requerido",
    "154": "Liquidação Provisória de Sentença pelo Procedimento Comum|150|Requerente|Requerido",
    "155": "Procedimento de Cumprimento de Sentença/Decisão|1106||",
    "156": "Cumprimento de sentença|155|Exequente|Executado",
    "157": "Cumprimento Provisório de Sentença|155|Exequente|Executado",
    "158": "Processo de Execução|2||",
    "159": "Execução de Título Extrajudicial|158|Exequente|Executado",
    "165": "Insolvência Civil|158|Autor|Réu",
    "166": "Insolvência Requerida pelo Credor|165|Exequente|Executado",
    "167": "Insolvência Requerida pelo Devedor ou pelo Espólio|165|Exequente|Executado",
    "169": "Embargos|158||",
    "170": "Embargos à Adjudicação|169|Embargante|Embargado",
    "171": "Embargos à Arrematação|169|Embargante|Embargado",
    "172": "Embargos à Execução|169|Embargante|Embargado",
    "173": "Embargos de Retenção por Benfeitorias|169|Embargante|Embargado",
    "175": "Processo Cautelar|2||",
    "176": "Alimentos - Provisionais|175|Requerente|Requerido",
    "177": "Apreensão de  Títulos|175|Requerente|Requerido",
    "178": "Arresto|175|Requerente|Requerido",
    "179": "Arrolamento de Bens|175|Requerente|Requerido",
    "180": "Atentado|175|Requerente|Requerido",
    "181": "Busca e Apreensão|175|Requerente|Requerido",
    "182": "Caução|175|Requerente|Requerido",
    "183": "Cautelar Inominada|175|Requerente|Requerido",
    "186": "Exibição|175|Requerente|Requerido",
    "188": "Homologação do Penhor Legal|175|Requerente|Requerido",
    "190": "Justificação|175|Requerente|Requerido",
    "191": "Protesto|175|Requerente|Requerido",
    "192": "Posse em Nome do Nascituro|175|Requerente|Requerido",
    "193": "Produção Antecipada da Prova|175|Requerente|Requerido",
    "194": "Regulamentação de Visitas|175|Requerente|Requerido",
    "195": "Separação de Corpos|175|Requerente|Requerido",
    "196": "Seqüestro|175|Requerente|Requerido",
    "197": "Recursos|2||",
    "198": "Apelação|197|Apelante|Apelado",
    "199": "Remessa Necessária|197|Juízo Recorrente|Recorrido",
    "200": "Agravos|197||",
    "202": "Agravo de Instrumento|200|Agravante|Agravado",
    "203": "Agravo de Instrumento em Recurso Especial|200|Agravante|Agravado",
    "204": "Agravo de Instrumento em Recurso Extraordinário|200|Agravante|Agravado",
    "206": "Agravo Regimental|200|Agravante|Agravado",
    "207": "Embargos|197||",
    "208": "Embargos Infringentes|207|Embargante|Embargado",
    "210": "Embargos Infringentes na Execução Fiscal|207|Embargante|Embargado",
    "211": "Recurso Ordinário|197|Recorrente|Recorrido",
    "212": "Recurso Extraordinário|197|Recorrente|Recorrido",
    "213": "Recurso Especial|197|Recorrente|Recorrido",
    "214": "Outros Procedimentos|2||",
    "215": "Incidentes|214|Requerente|Requerido",
    "216": "Incidente De Arguição de Inconstitucionalidade|215|Arguinte|Arguído",
    "218": "Assistência Judiciária|215||",
    "221": "Conflito de competência|215|Suscitante|Suscitado",
    "224": "Exceções|215||",
    "228": "Exibição de Documento ou Coisa|215|Autor|Réu",
    "229": "Impugnação ao Cumprimento de Sentença|155||",
    "230": "Impugnação ao Pedido de Assistência Litisconsorcial ou Simples|215|Impugnante|Impugnado",
    "231": "Impugnação ao Valor da Causa|215|Impugnante|Impugnado",
    "232": "Incidente de Falsidade|215|Autor|Réu",
    "233": "Incidente de Uniformização de Jurisprudência|215|Suscitante|",
    "234": "Remoção de Inventariante|215|Requerente|Requerido",
    "236": "Oposição|215|Opoente|Oposto",
    "237": "Atos e expedientes|214||",
    "238": "Avocatória|237|Suscitante|Suscitado",
    "239": "Habilitação para Casamento|237|Requerente|",
    "240": "Instrução de Rescisória|237|Autor|Réu",
    "241": "Petição|237|Requerente|Requerido",
    "242": "Comunicação|237|Comunicante|Comunicado",
    "244": "Reclamação|62|Reclamante|Reclamado",
    "251": "Registro de Casamento Nuncupativo|237|Autor|Réu",
    "256": "Representação por Excesso de Prazo|1198|Representante|Representado",
    "257": "Cartas|214||",
    "258": "Carta de Ordem Cível|257|Ordenante|Ordenado",
    "261": "Carta Precatória Cível|257|Deprecante|Deprecado",
    "264": "Carta Rogatória Cível|257|Rogante|Rogado",
    "268": "PROCESSO CRIMINAL|||",
    "269": "Medidas Preparatórias|268||",
    "270": "Notícia-Crime|269|Noticiante|Noticiado",
    "271": "Representação Criminal|277||",
    "272": "Representação Criminal/Notícia de Crime|271|Representante/Noticiante|Representado",
    "273": "Pedido de Arquivamento em Representação Criminal|271|Representante|Representado",
    "274": "Interpelações|269||",
    "275": "Notificação para Explicações|274|Notificante|Notificado",
    "276": "Notificação para Explicações (Lei de Imprensa)|274|Notificante|Notificado",
    "277": "Procedimentos Investigatórios|268||",
    "278": "Termo Circunstanciado|277|Autoridade|Autor do Fato",
    "279": "Inquérito Policial|277|Autor|Investigado",
    "280": "Auto de Prisão em Flagrante|277|Autoridade|Flagranteado",
    "281": "Procedimento Comum|268||",
    "282": "Ação Penal de Competência do Júri|281|Autor|Réu",
    "283": "Ação Penal - Procedimento Ordinário|281|Autor|Réu",
    "284": "Processo Especial|268||",
    "285": "Processo Especial do Código de Processo Penal|284||",
    "287": "Crimes de Responsabilidade dos Funcionários Públicos|285|Autor|Réu",
    "288": "Crimes de Calúnia, Injúria e Difamação de Competência do Juiz Singular|285|Autor|Réu",
    "289": "Crimes Contra a Propriedade Imaterial|285|Autor|Réu",
    "290": "Processo Sumário (Detenção)|285|Autor|Réu",
    "291": "Restauração de Autos|285|Autor|Réu",
    "292": "Processo Especial de Leis Esparsas|284||",
    "293": "Crimes Ambientais|292|Autor|Réu",
    "294": "Crimes Contra a Propriedade Industrial|292|Autor|Réu",
    "295": "Crimes Contra a Propriedade Intelectual|292|Autor|Réu",
    "297": "Crimes de Imprensa|292|Querelante|Querelado",
    "299": "Procedimento do Juizado Especial Criminal - Sumariíssimo|292|Autor|Réu",
    "300": "Procedimento Especial da Lei Antitóxicos|292|Autor|Réu",
    "302": "Procedimento Especial dos Crimes de Abuso de Autoridade|292|Autor|Réu",
    "303": "Medidas Garantidoras|268||",
    "304": "Liberdade|303||",
    "305": "Liberdade Provisória com ou sem fiança|304|Requerente|Requerido",
    "306": "Relaxamento de Prisão|304|Acusado|Autoridade",
    "307": "Habeas Corpus|303|Impetrante|Impetrado",
    "308": "Medidas Cautelares|268||",
    "309": "Pedido de Busca e Apreensão Criminal|308|Requerente|Acusado",
    "310": "Pedido de Quebra de Sigilo de Dados e/ou Telefônico|308|Requerente|Acusado",
    "311": "Medidas Investigatórias Sobre Organizações Criminosas|308|Autoridade|Investigado",
    "312": "Pedido de Prisão|308||",
    "313": "Pedido de Prisão Preventiva|312|Requerente|Acusado",
    "314": "Pedido de Prisão Temporária|312|Requerente|Acusado",
    "315": "Pedido de Prisão/ Liberdade Vigiada para Fins de Expulsão|312|Requerente|Acusado",
    "316": "Questões e Processos Incidentes|268||",
    "317": "Exceções|316||",
    "318": "Exceção de Suspeição|317|Excipiente|Excepto",
    "319": "Exceção de Incompetência de Juízo|317|Excipiente|Excepto",
    "320": "Exceção de Litispendência|317|Excipiente|Excepto",
    "321": "Exceção de Ilegitimidade de Parte|317|Excipiente|Excepto",
    "322": "Exceção de Coisa Julgada|317|Excipiente|Excepto",
    "323": "Exceção de Impedimento|317|Excipiente|Excepto",
    "324": "Exceção da Verdade|317|Excipiente|Excepto",
    "325": "Conflito de Jurisdição|331|Suscitante|Suscitado",
    "326": "Restituição de Coisas Apreendidas|316|Requerente|Requerido",
    "327": "Embargos de Terceiro|316|Embargante|Embargado",
    "328": "Medidas Assecuratórias|316||",
    "329": "Seqüestro|328|Requerente|Acusado",
    "330": "Arresto / Hipoteca Legal|328|Requerente|Acusado",
    "331": "Incidentes|316||",
    "332": "Incidente de Falsidade|331|Suscitante|Suscitado",
    "333": "Insanidade Mental do Acusado|331|Requerente|Acusado",
    "334": "Cartas|268||",
    "335": "Carta de Ordem Criminal|334|Ordenante|Ordenado",
    "355": "Carta Precatória Criminal|334|Deprecante|Deprecado",
    "375": "Carta Rogatória Criminal|334|Rogante|Rogado",
    "385": "Execução Criminal|268||",
    "386": "Execução da Pena|385||",
    "406": "Incidentes|385||",
    "407": "Conversão de Pena|406|Requerente|Requerido",
    "408": "Excesso ou Desvio|406|Requerente|Requerido",
    "409": "Anistia|406|Requerente|",
    "410": "Indulto|406|Requerente|",
    "411": "Comutação de Pena|406|Requerente|",
    "412": "Recursos|268||",
    "413": "Agravo de Execução Penal|412|Agravante|Agravado",
    "416": "Apelação em Mandado de Segurança|412|Apelante|Apelado",
    "417": "Apelação|412|Apelante|Apelado",
    "418": "Carta Testemunhável|412|Requerente|Requerido",
    "419": "Correição Parcial|412|Corrigente|Corrigido",
    "420": "Embargos de Declaração|412|Embargante|Embargado",
    "421": "Embargos Infringentes e de Nulidade|412|Embargante|Embargado",
    "422": "Protesto por Novo Júri|412|Recorrente|Recorrido",
    "424": "Recurso de Medida Cautelar|412|Recorrente|Recorrido",
    "425": "Recurso de Sentença Criminal|412|Recorrente|Recorrido",
    "426": "Recurso em Sentido Estrito|412|Recorrente|Recorrido",
    "427": "Remessa Necessária|412|Juízo Recorrente|Recorrido",
    "428": "Revisão Criminal|412|Requerente|Requerido",
    "432": "Desaforamento de Julgamento|331|Autor|Réu",
    "433": "Incidente de Uniformização de Jurisprudência|331|Parte Autora|Parte Ré",
    "436": "Procedimento do Juizado Especial Cível|1107|Autor|Réu",
    "457": "Pedido de Uniformização de Interpretação de Lei|215|Parte Autora|Parte Ré",
    "460": "Recurso Inominado|197|Recorrente|Recorrido",
    "547": "JUIZADOS DA INFÂNCIA E DA JUVENTUDE|||",
    "976": "Ação Anulatória de Cláusulas Convencionais|1067|Autor|Réu",
    "980": "Ação de Cumprimento|1067|Reclamante|Reclamado",
    "985": "Ação Trabalhista - Rito Ordinário|1067|Reclamante|Reclamado",
    "986": "Inquérito para Apuração de Falta Grave|1067|Requerente|Requerido",
    "987": "Dissídio Coletivo|1067|Suscitante|Suscitado",
    "988": "Dissídio Coletivo de Greve|1067|Suscitante|Suscitado",
    "990": "Execução de Título Extrajudicial|1068|Exequente|Executado",
    "991": "Execução de Termo de Ajuste de Conduta|1068|Exequente|Executado",
    "992": "Execução de Termo de Conciliação de CCP|1068|Exequente|Executado",
    "993": "Execução de Certidão de Crédito Judicial|1068|Exequente|Executado",
    "994": "Execução Provisória em Autos Suplementares|1068|Exequente|Executado",
    "1000": "Agravo|1209|Agravante|Agravado",
    "1001": "Agravo de Instrumento em Agravo de Petição|1209|Agravante|Agravado",
    "1002": "Agravo de Instrumento em Recurso de Revista|1209|Agravante|Agravado",
    "1003": "Agravo de Instrumento em Recurso Ordinário|1209|Agravante|Agravado",
    "1004": "Agravo de Petição|1209|Agravante|Agravado",
    "1005": "Agravo Regimental|1209|Agravante|Agravado",
    "1006": "Embargos|1071|Embargante|Embargado",
    "1007": "Embargos Infringentes|1071|Embargante|Embargado",
    "1008": "Recurso de Revista|1071|Recorrente|Recorrido",
    "1009": "Recurso Ordinário|1071|Recorrente|Recorrido",
    "1013": "Agravo de Instrumento em Recurso de Habeas Corpus|5|Agravante|Agravado",
    "1015": "Embargos de Divergência em Recurso de Mandado de Segurança|5|Embargante|Embargado",
    "1016": "Embargos em Agravo de Instrumento|5|Embargante|Embargado",
    "1017": "Embargos à Execução em Mandado de Segurança|5|Embargante|Embargado",
    "1018": "Embargos à Execução em Ação Recisoria|5|Embargante|Embargado",
    "1019": "Embargos à Execução em Medida Cautelar|5|Embargante|Embargado",
    "1020": "Execução em Ação Rescisória|5|Exeqüente|Executado",
    "1021": "Execução em Sentença Estrangeira|5|Exeqüente|Executado",
    "1022": "Execução em Sentença Estrangeira Contestada|5|Exeqüente|Executado",
    "1023": "Execução em Mandado de Segurança|5|Exeqüente|Executado",
    "1024": "Execução em Medida Cautelar|5|Exeqüente|Executado",
    "1025": "Incidente de Deslocamento de Competência|5|Suscitante|Suscitado",
    "1026": "Sentença Estrangeira|5|Requerente|",
    "1027": "Sentença Estrangeira Contestada|5|Requerente|Requerido",
    "1028": "Sindicância|5|Requerente|",
    "1029": "Mandado de Segurança|5|Impetrante|Impetrado",
    "1030": "Reclamação|5|Reclamante|Reclamado",
    "1031": "Recurso Ordinário|5|Recorrente|Recorrido",
    "1032": "Recurso Especial|5|Recorrente|Recorrido",
    "1033": "Ação Penal|5|Autor|Réu",
    "1034": "Interpelação Judicial|5|Interpelante|Interpelado",
    "1035": "Apelação Cível|5|Apelante|Apelado",
    "1036": "Suspensão de Liminar e de Sentença|5|Requerente|Requerido",
    "1037": "Embargos Infringentes em Apelação Cível|5|Embargante|Embargado",
    "1038": "Representação|5|Representante|Representado",
    "1040": "Requisição de Pequeno Valor|5|Requerente|Requerido",
    "1042": "Inquérito|5|Autor|Investigado",
    "1044": "Agravo de Instrumento|5|Agravante|Agravado",
    "1045": "Agravo de Instrumento em Recurso Extraordinário|5|Agravante|Agravado",
    "1046": "Agravo de Instrumento em Recurso de Mandado de Segurança|5|Agravante|Agravado",
    "1047": "Carta Rogatória|5|Rogante|Rogado",
    "1049": "Habeas Data|5|Impetrante|Impetrado",
    "1053": "Conflito de Atribuição|5|Autor|Réu",
    "1054": "Conflito de Competência|5|Suscitante|Suscitado",
    "1057": "Petição|5|Requerente|Requerido",
    "1062": "Medida Cautelar|5|Requerente|Requerido",
    "1063": "Exceção da Verdade|5|Excipiente|Excepto",
    "1064": "Recurso Ordinário em Habeas Corpus|5|Recorrente|Recorrido",
    "1066": "Embargos Infringentes em Ação Rescisória|5|Embargante|Embargado",
    "1067": "Procedimentos Trabalhistas|1107||",
    "1068": "Processo de Execução Trabalhista|158|Exequente|Executado",
    "1070": "Incidentes Trabalhistas|214||",
    "1071": "Recursos Trabalhistas|197||",
    "1072": "Pedido de Revisão do Valor da Causa|1070|Requerente|Requerido",
    "1106": "Processo de Conhecimento|2||",
    "1107": "Procedimento de Conhecimento|1106||",
    "1111": "Execução de Título Judicial|158||",
    "1112": "Execução de Alimentos|1111|Exequente|Executado",
    "1114": "Execução Contra a Fazenda Pública|1111|Exequente|Executado",
    "1116": "Execução Fiscal|158|Exequente|Executado",
    "1117": "Execução Hipotecária do Sistema Financeiro da Habitação|158|Exequente|Executado",
    "1118": "Embargos à Execução Fiscal|169|Exequente|Executado",
    "1122": "Tutela e Curatela - Remoção e Dispensa|50|Requerente|Requerido",
    "1124": "Justificação de Dinheiro a Risco|62|Requerente|Requerido",
    "1125": "Ação Trabalhista - Rito Sumaríssimo|1067|RECLAMANTE|RECLAMADO",
    "1126": "Ação Trabalhista - Rito Sumário (Alçada)|1067|RECLAMANTE|RECLAMADO",
    "1137": "Embargos de Divergência em Recurso Especial|5|Embargante|Embargado",
    "1145": "Conflito de Competência|1070|Suscitante|Suscitado",
    "1178": "Incidente de Arguição de Inconstitucionalidade|331||",
    "1198": "PROCEDIMENTOS ADMINISTRATIVOS||Requerente|Requerido",
    "1199": "Pedido de Providências|1198||",
    "1202": "Reclamação|1067|Reclamante|Reclamado",
    "1208": "Agravo Interno|200|Agravante|Agravado",
    "1209": "Agravos|1071||",
    "1230": "Exceção de Impedimento|224||",
    "1231": "Exceção de Suspeição|224|excipiente|excepto",
    "1232": "Exceção de Incompetência|224||",
    "1233": "Efeito Suspensivo|175|Requerente|Requerido",
    "1262": "Processo Administrativo Disciplinar em face de Servidor|1198|Processante|Processado",
    "1264": "Processo Administrativo Disciplinar em face de Magistrado|1198|Processante|Processado",
    "1265": "Precatório|1198|Requerente|Requerido",
    "1266": "Requisição de Pequeno Valor|1198|Requerente|Requerido",
    "1267": "Recurso de Multa|1071|Recorrente|Recorrido",
    "1268": "Medidas Protetivas de urgência (Lei Maria da Penha)|308|Requerente|Requerido",
    "1269": "Habeas Corpus|62||",
    "1271": "Recurso de Medida Cautelar|197|Recorrente|Recorrido",
    "1283": "Superveniência de doença mental|406|Requerente|Requerido",
    "1284": "Unificação de penas|406|Requerente|",
    "1285": "Conflito de atribuição|215||",
    "1288": "Transferência entre estabelecimentos penais|406|Requerente|Requerido",
    "1289": "Outras medidas provisionais|175||",
    "1291": "Reabilitação|331||",
    "1294": "Outros procedimentos de jurisdição voluntária|50|Requerente|Requerido",
    "1295": "Alvará Judicial|50||",
    "1296": "Declaratória de Constitucionalidade|62|Requerente|Requerido",
    "1297": "Intervenção em Município|62|Requerente|Requerido",
    "1298": "Processo Administrativo|1198||",
    "1299": "Recurso Administrativo|1198|Recorrente|Recorrido",
    "1301": "Reclamação Disciplinar|1198|Reclamente|Reclamado",
    "1303": "Correição Extraordinária|1198|Corrigente|Corrigido",
    "1304": "Inspeção|1198||",
    "1306": "Recurso em Processo Administrativo Disciplinar em face de Servidor|1198|Recorrente|Recorrido",
    "1307": "Correição Ordinária|1198|Corrigente|Corrigido",
    "1308": "Sindicância|1198|Sindicante|Sindicado",
    "1310": "SUPREMO TRIBUNAL FEDERAL|||",
    "1311": "Ação Cautelar|1310||",
    "1312": "Ação Civil Originária|1310||",
    "1313": "Ação Declaratória de Constitucionalidade|1310||",
    "1314": "Ação Direta de Inconstitucionalidade|1310||",
    "1315": "Ação Originária|1310||",
    "1316": "Ação Originária Especial|1310||",
    "1317": "Ação Penal|1310||",
    "1318": "Ação Rescisória|1310||",
    "1319": "Agravo|1310||",
    "1320": "Agravo de Instrumento|1310||",
    "1321": "Agravo Regimental|1310||",
    "1322": "Arguição de Descumprimento de Preceito Fundamental|1310||",
    "1323": "Arguição de Suspeição|1310||",
    "1324": "Carta Rogatória|1310||",
    "1325": "Comunicação|1310||",
    "1326": "Conflito de Competência|1310||",
    "1327": "Embargos de Declaração|1310||",
    "1328": "Embargos de Divergência|1310||",
    "1329": "Embargos Infringentes|1310||",
    "1330": "Extradição|1310||",
    "1331": "Habeas Corpus|1310||",
    "1332": "Habeas Data|1310||",
    "1333": "Inquerito|1310|Autor|Investigado",
    "1334": "Intervenção Federal|1310||",
    "1335": "Mandado de Injunção|1310||",
    "1336": "Mandado de Segurança|1310||",
    "1337": "Medida Cautelar|1310||",
    "1338": "Petição|1310||",
    "1339": "Prisão Preventiva para Extradição|1310||",
    "1340": "Processo Administrativo|1310||",
    "1341": "Questão de Ordem|1310||",
    "1342": "Reclamação|1310||",
    "1343": "Recurso Criminal|1310||",
    "1344": "Recurso em Habeas Corpus|1310||",
    "1345": "Recurso em Habeas Data|1310||",
    "1346": "Recurso em Mandado de Injunção|1310||",
    "1347": "Recurso em Mandado de Segurança|1310||",
    "1348": "Recurso Extraordinário|1310||",
    "1349": "Revisão Criminal|1310||",
    "1350": "Sentença Estrangeira|1310||",
    "1351": "Sentença Estrangeira Contestada|1310||",
    "1352": "Suspensão de Liminar|1310||",
    "1353": "Suspensão de Segurança|1310||",
    "1354": "Suspensão de Tutela Antecipada|1310||",
    "1355": "Siglas dos Processos Extintos|1310||",
    "1356": "Ação Ordinária Regressiva|1355||",
    "1357": "Ação Regressiva|1355||",
    "1358": "Apelação Cível|1355||",
    "1359": "Apelação Criminal|1355||",
    "1360": "Arguição de Relevância|1355||",
    "1361": "Carta Testemunhável|1355||",
    "1362": "Conflito de Atribuições|1355||",
    "1363": "Conflito de Jurisdição|1355||",
    "1364": "Denúncia|1355||",
    "1365": "Embargos Remetidos|1355||",
    "1366": "Exceção da Verdade|1355||",
    "1367": "Exceção de Suspeição|1355||",
    "1368": "Inquérito Administrativo|1355||",
    "1369": "Inquérito Policial|1355||",
    "1370": "Inquérito Policial Especial|1355||",
    "1371": "Interpelação|1355||",
    "1372": "Liquidação de Sentença|1355||",
    "1373": "Notificação|1355||",
    "1374": "Pedido de Avocação|1355||",
    "1375": "Processo Crime|1355||",
    "1376": "Processo Judicial|1355||",
    "1377": "Queixa Crime|1355||",
    "1378": "Recurso de Apreensão de Livro|1355||",
    "1379": "Recurso de Liquidação de Sentença|1355||",
    "1380": "Recurso de Revista|1355||",
    "1381": "Representação|1355||",
    "1382": "Retificação de Nome Estrangeiro|1355||",
    "1383": "Sentença Arbitral|1355||",
    "1384": "Suspensão de Direitos|1355||",
    "1385": "Seção Cível|547||",
    "1386": "Processo de Conhecimento|1385||",
    "1389": "Ação de Alimentos|1386|Requerente|Requerido",
    "1390": "Revisão Judicial de Decisão do Conselho Tutelar|1386|Requerente|Requerido",
    "1391": "Apuração de Irregularidades em Entidades de Atendimento|1386|Requerente|Requerido",
    "1392": "Apuração de Infração Administrativa às Normas de Proteção à Criança ou Adolescente|1386|Requerente|Requerido",
    "1396": "Tutela|1386|Requerente|Requerido",
    "1399": "Tutela c/c Destituição do Poder Familiar|1386|Requerente|Requerido",
    "1401": "Adoção|1386|Requerente|Requerido",
    "1412": "Adoção c/c Destituição do Poder Familiar|1386|Requerente|Requerido",
    "1414": "Suprimento de Capacidade ou de Consentimento para Casar|1386|Requerente|Requerido",
    "1415": "Emancipação|1386|Requerente|",
    "1417": "Regularização de Registro Civil|1386|Requerente|Requerido",
    "1420": "Guarda|1386||",
    "1424": "Providência|1386|Requerente|Requerido",
    "1425": "Prestação de Contas|1386|Requerente|Requerido",
    "1426": "Perda ou Suspensão do Poder Familiar|1386|Requerente|Requerido",
    "1430": "Processo de Execução|1385||",
    "1432": "Execução de Alimentos|1430|Exequente|Executado",
    "1434": "Medidas de Proteção à Criança e Adolescente|1430|Requerente|Requerido",
    "1435": "Execução de Multa|1430|Exequente|Executado",
    "1436": "Processos Cautelares|1385||",
    "1438": "Busca e Apreensão|1436|Requerente|Requerido",
    "1440": "Cautelar Inominada|1436|Requerente|Requerido",
    "1450": "Cartas|1385||",
    "1451": "Carta de Ordem Infância e Juventude|1450|Ordenante|Ordenado",
    "1455": "Carta Precatória Infância e Juventude|1450|Deprecante|Deprecado",
    "1459": "Seção Infracional|547||",
    "1460": "Procedimentos Investigatórios|1459|Autoridade|Adolescente",
    "1461": "Auto de Apreensão em Flagrante|1460|Autoridade|Adolescente",
    "1462": "Relatório de Investigações|1460|Autoridade|Adolescente",
    "1463": "Boletim de Ocorrência Circunstanciada|1460|Autoridade|Autor do Fato",
    "1464": "Processo de Apuração de Ato Infracional|1459|Autor|Adolescente",
    "1465": "Execução de Medidas Sócio-Educativas|1459|Requerente|Adolescente",
    "1466": "Internação com Atividades Externas|1465|Requerente|Adolescente",
    "1467": "Internação sem Atividades Externas|1465|Requerente|Adolescente",
    "1468": "Semiliberdade|1465|Requerente|Adolescente",
    "1469": "Liberdade Assistida|1465|Requerente|Adolescente",
    "1470": "Prestação de Serviços a Comunidade|1465|Requerente|Adolescente",
    "1471": "Obrigação de Reparar o Dano|1465|Requerente|Adolescente",
    "1472": "Advertência|1465|Requerente|Adolescente",
    "1473": "Cartas|1459||",
    "1474": "Carta de Ordem Infracional|1473|Ordenante|Ordenado",
    "1478": "Carta Precatória Infracional|1473|Deprecante|Deprecado",
    "1669": "Ação Rescisória|5|Autor|Réu",
    "1670": "Ação de Improbidade Administrativa|5|Requerente|Requerido",
    "1671": "Comunicação|5|Comunicante|Comunicado",
    "1672": "Exceção de Impedimento|5|Excipiente|Excepto",
    "1673": "Exceção de Suspeição|5|Excipiente|Excepto",
    "1674": "Intervenção Federal|5|Requisitante|",
    "1675": "Mandado de Injunção|5|Impetrante|Impetrado",
    "1677": "Precatório|5|Requerente|Requerido",
    "1678": "Revisão Criminal|5|Requerente|Requerido",
    "1679": "Suspensão de Segurança|5|Requerente|Requerido",
    "1680": "Consulta|1198|Consulente|Consultado",
    "1682": "Retificação ou Suprimento ou Restauração de Registro Civil|62||",
    "1683": "Retificação de Registro de Imóvel|62||",
    "1685": "Reexame Necessário|1071|Juízo Recorrente|Recorrido",
    "1689": "Embargos de Declaração|207|Embargante|Embargado",
    "1690": "Ação Civil Pública|1386|Autor|Réu",
    "1691": "Mandado de Segurança|1386||",
    "1701": "Nomeação de Advogado|237||",
    "1702": "Impugnação de Assistência Judiciária|215||",
    "1703": "Autorização judicial|1386||",
    "1704": "Embargos de Terceiro|1386||",
    "1705": "Remoção, modificação  e dispensa de tutor ou curador|1386||",
    "1706": "Procedimento ordinário|1386||",
    "1707": "Reintegração / Manutenção de Posse|27||",
    "1709": "Interdito Proibitório|27||",
    "1710": "Mandado de Segurança|292|Impetrante|Impetrado",
    "1711": "Agravo de Instrumento em Recurso Especial|412||",
    "1712": "Agravo de Instrumento em Recurso Extraordinário|412||",
    "1714": "Execução Provisória|385||",
    "1715": "Embargos do Acusado|316|Embargante|Embargado",
    "1717": "Alienação de Bens do Acusado|316||",
    "1719": "Avaliação para atestar dependência de drogas|331||",
    "1720": "Habeas Corpus|5|Impetrante|Impetrado",
    "1721": "Recurso Ordinário em Mandado de Segurança|5|Recorrente|Recorrido",
    "1722": "Recurso Ordinário em Habeas Corpus|5|Recorrente|Recorrido",
    "1723": "Contraprotesto Judicial|175|Requerente|Requerido",
    "1725": "Notificação|175||",
    "1726": "Interpelação|175||",
    "1727": "Petição|268|Requerente|Requerido",
    "1728": "Apelação / Remessa Necesária|197|Apelante|Apelado",
    "1729": "Agravo Regimental|412|Agravante|Agravado",
    "1730": "Recurso Ordinário|412||",
    "1731": "Investigação contra magistrado|277||",
    "1733": "Procedimento Investigatório Criminal (PIC-MP)|277||",
    "10933": "Habilitação para Adoção|1386||",
    "10942": "Correição Parcial|197||",
    "10943": "Ação Penal - Procedimento Sumário|281|Autor|Réu",
    "10944": "Ação Penal - Procedimento Sumaríssimo|281|Autor|Réu",
    "10960": "Incidente de Sanidade Mental|1459||",
    "10967": "Medidas Protetivas - Estatuto do Idoso|308||",
    "10969": "Incidentes|1385||",
    "10970": "Conflito de Competência|10969|Suscitante|Suscitado",
    "10971": "Exceções|10969||",
    "10972": "Exibição de Documento ou Coisa|10969|Autor|Réu",
    "10973": "Impugnação ao Valor da Causa|10969||",
    "10974": "Incidente de Falsidade|10969||",
    "10975": "Exceção de Impedimento|10971|Excipiente|Excepto",
    "10976": "Exceção de Incompetência|10971|Excepto|Excipiente",
    "10977": "Exceção de Suspeição|10971|Excepto|Excipiente",
    "10979": "Petição|1459|Requerente|Requerido",
    "10980": "Cumprimento Provisório de Decisão|155|Exequente|Executado",
    "10981": "Impugnação ao Cumprimento  de Decisão|155|Impugnante|Impugnado",
    "11026": "Petição|1385|Requerente|Requerido",
    "11027": "Reexame Necessário / Recurso Ordinário|1071|Juízo Recorrente|Recorrido",
    "11028": "PROCESSO MILITAR|||",
    "11029": "PROCEDIMENTOS ESPECIAIS PREVISTOS EM LEIS ESPARSAS|11028||",
    "11030": "PROCESSO CRIMINAL|11028||",
    "11031": "Procedimento Especial|11030||",
    "11032": "Procedimentos Investigatórios|11030||",
    "11033": "Recursos|11030||",
    "11034": "Conselho de Justificação|11029||",
    "11035": "Representação p/ Declaração de Indignidade/Incompatibilidade|11029||",
    "11036": "Representação p/ Perda da Graduação|11029||",
    "11037": "Ação Penal Militar - Procedimento Ordinário|11030|Autor|Réu",
    "11038": "Embargos em Ação Penal Militar|11033||",
    "11039": "Reclamação|11033||",
    "11040": "Recurso Inominado|11033||",
    "11041": "Inquérito Policial Militar|11032|Autor|Investigado",
    "11042": "Correição Parcial|11031|Corrigente|Corrigido",
    "11043": "Deserção|11031|Autor|Réu",
    "11044": "Insubmissão|11031|Autor|Réu",
    "11045": "Deserção de Oficial|11043||",
    "11046": "Deserção de Praça|11043||",
    "11099": "PROCEDIMENTOS PRÉ-PROCESSUAIS DE RESOLUÇÃO CONSENSUAL DE CONFLITOS|||",
    "11397": "Extinção das obrigações do falido|62||",
    "11398": "Recurso em sentido estrito/Recurso ex officio|412|Recorrente|Recorrido",
    "11399": "Execução de Medida de Segurança|385||",
    "11400": "Agravo de Instrumento em Recurso Ordinário para o STF|1209|Agravante|Agravado",
    "11427": "PROCESSO ELEITORAL|||",
    "11525": "Processos Cíveis-Eleitorais|11427||",
    "11526": "Ação de Impugnação de Mandato Eletivo|11525|Autor|Réu",
    "11527": "Ação de Investigação Judicial Eleitoral|11525|Autor|Réu",
    "11528": "Ação Penal Eleitoral|11427|Autor|Réu",
    "11529": "Procedimentos Relativos a Realização de Eleição|11427||",
    "11530": "Apuração de Eleição|11529|Interessado|Requerido",
    "11531": "Prestação de Contas|11529|Interessado|",
    "11532": "Registro de Candidatura|11529|Requerente|",
    "11533": "Recurso contra Expedição de Diploma|11529|Recorrente|Recorrido",
    "11534": "Procedimentos Relativos a Partidos Políticos|11427||",
    "11535": "Cancelamento de Registro de Partido Político|11534|Requerente|Requerido",
    "11536": "Propaganda Partidária|11534|Interessado|",
    "11537": "Registro de Órgão de Partido Político em Formação|11534|Requerente|",
    "11538": "Registro de Comitê Financeiro|11529|Requerente|",
    "11539": "Registro de Partido Político|11534|Requerente|",
    "11540": "Procedimentos Administrativos da Justiça Eleitoral|11427||",
    "11541": "Representação|11529|Representante|Representado",
    "11542": "Correição|11540|Interessado|",
    "11543": "Criação de Zona Eleitoral ou Remanejamento|11540|Interessado|",
    "11544": "Instrução|11540|Interessado|",
    "11545": "Lista Tríplice|11540|Interessado|",
    "11546": "Revisão de Eleitorado|11540|Interessado|",
    "11547": "Recursos Eleitorais|11427||",
    "11548": "Recurso Eleitoral|11547|Recorrente|Recorrido",
    "11549": "Recurso Especial Eleitoral|11547|Recorrente|Recorrido",
    "11550": "Recurso Ordinário|11547|Recorrente|Recorrido",
    "11551": "Consulta|11427|Consulente|",
    "11552": "Pedido de Desaforamento|11427|Requerente|Requerido",
    "11553": "Instrução Provisória de Deserção|11043||",
    "11555": "Suspensão de Liminar e de Sentença|62|Requerente|Requerido",
    "11556": "Suspensão de Segurança|62|Requerente|Requerido",
    "11787": "Assistência Judiciária|331||",
    "11788": "Exibição de Documento ou Coisa|331|Requerente|Requerido",
    "11789": "Impugnação ao Valor da Causa|331|Impugnante|Impugnado",
    "11790": "Impugnação de Assistência Judiciária|331||",
    "11791": "Pedido de Uniformização de Interpretação de Lei|331||",
    "11793": "Justificação Criminal|308|Requerente|Requerido",
    "11794": "Restituição de Coisas Apreendidas - Infracional|1459|Requerente|Requerido",
    "11798": "Processo de Aplicação de Medida de Segurança Por Fato Não Criminoso|285|Requerente|Requerido",
    "11799": "Instrução Provisória de Insubmissão|11032|Requerente|Requerido",
    "11800": "Instrução Provisória de Deserção|11032|Requerente|Requerido",
    "11875": "Reclamação Pré-processual|11099|Reclamante|Reclamado",
    "11880": "Recurso Extraordinário com Agravo|1310|Agravante|Agravado",
    "11881": "Agravo em Recurso Especial|5|Agravante|Agravado",
    "11882": "Recurso de Revista com Agravo|1071|Agravante|Agravado",
    "11886": "Recurso Ordinário - Rito Sumaríssimo|1071||",
    "11887": "Acompanhamento de Cumprimento de Decisão|1198|Requerente|Requerido",
    "11888": "Ato Normativo|1198|Requerente|Requerido",
    "11889": "Nota Técnica|1198|Requerente|Requerido",
    "11890": "Parecer de Mérito sobre Anteprojeto de Lei|1198|Requerente|Requerido",
    "11891": "Procedimento de Controle Administrativo|1198|Requerente|Requerido",
    "11892": "Revisão Disciplinar|1198|Requerente|Requerido",
    "11893": "Reclamação para Garantia das Decisões|1198|Requerente|Requerido",
    "11894": "Procedimento de Competência de Comissão|1198|Requerente|Requerido",
    "11953": "Arguição de Suspeição e de Impedimento|1198|Arguinte|Arguído",
    "11955": "Cautelar Inominada Criminal|308|Requerente|Requerido",
    "11956": "Embargos de Divergência em Agravo em Recurso Especial|5|Embargante|Embargado",
    "11957": "Remição de Pena|406|Requerente|Requerido",
    "11976": "Pedido de Busca e Apreensão Infracional|1460||",
    "12057": "Reexame Necessário|11547||",
    "12058": "Suspensão de Segurança|11427||",
    "12059": "Suspensão de Segurança|11525||",
    "12060": "Exceção|11525||",
    "12061": "Ação Cautelar|11525||",
    "12070": "Pedido de Medida de Proteção|1386||",
    "12071": "Procedimentos Cautelares|1459||",
    "12072": "Busca e Apreensão|12071||",
    "12073": "Internação Provisória|12071||",
    "12074": "Pedido De Desinternação/Reavaliação/Substituição/Suspensão da Medida|12071||",
    "12075": "Procedimento Conciliatório|215|REQUERENTE|REQUERIDO",
    "12076": "Restabelecimento do Poder Familiar|1386|Requerente|Requerido",
    "12077": "Homologação em Acordo de Colaboração Premiada|331||",
    "12078": "Cumprimento de Sentença contra a Fazenda Pública|155||",
    "12079": "Execução de Título Extajudicial contra a Fazenda Pública|159||",
    "12080": "Incidente de Impedimento|215||",
    "12081": "Incidente de Suspeição|215||",
    "12082": "Carta Arbitral|257|Requerente|Requerido",
    "12083": "Tutela Antecipada Antecedente|175|requerente|requerido",
    "12084": "Tutela Cautelar Antecedente|175|requerente|requerido",
    "12085": "Incidente de Resolução de Demandas Repetitivas|215|Suscitante|Suscitado",
    "12086": "Dissolução Parcial de Sociedade|27|autor|réu",
    "12087": "Incidente de Assunção de Competência|215|suscitante|suscitado",
    "12088": "Liquidação Provisória de Sentença pelo Procedimento Comum|150|autor|réu",
    "12089": "Renovação de Permanência em Estabelecimento Penal Federal|406|Requerente|Requerido",
    "12117": "Suspensão em Incidente de Resolução de Demandas Repetitivas|1310||",
    "12118": "Suspensão em Incidente de Resolução de Demandas Repetitivas|5||",
    "12119": "Incidente de Desconsideração de Personalidade Jurídica|215|Suscitante|Suscitado",
    "12120": "Agravo em Execução de Medidas Sócio-Educativas|1459|Agravante|Agravado",
    "12121": "Auto de Prisão|277|Autoridade|Acusado",
    "12122": "Reclamação Criminal|292|Reclamante|Reclamação",
    "12123": "Roteiro de Pena|406|Autoridade|Executado",
    "12124": "Roteiro de Pena|406||",
    "12125": "Progressão de Regime|406|Autoridade|Executado",
    "12126": "Regressão de Regime|406|Autoridade|Executado",
    "12127": "Remição|406|Autoridade|Execução",
    "12128": "Livramento Condicional|406|Autoridade|Executado",
    "12129": "Outros Incidentes de Execução Iniciados de Ofício|406|Autoridade|Executado",
    "12132": "Incidente de Julgamento de Recurso de Revista e de Embargos Repetitivos|1070|Requerente|Requerido",
    "12133": "Tutela Provisória|2|Requerente|Requerido",
    "12134": "Tutela Cautelar Antecedente|12133|Requerente|Requerido",
    "12135": "Tutela Antecipada Antecedente|12133|Requerente|Requerido",
    "12136": "Pedido de Mediação Pré-Processual|11099|Requerente|Requerido",
    "12137": "Requerimento de Apreensão de Veículo|62|Requerente|Requerido",
    "12138": "Requerimento de Reintegração de Posse|62|Requerente|Requerido",
    "12139": "Contestação em Foro Diverso|237|Requerente|Requerido"
  }
};
