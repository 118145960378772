// Descrição das classes do CNJ a partir do identificador.
// Contem a informação do código do assunto pai para possibilitar a criação do nome completo.
//
export default {
  pai: function(id) {
    var a = this.assuntos[id];
    if (!a) return;
    return a.split("|")[1];
  },
  nome: function(id) {
    var a = this.assuntos[id];
    if (!a) return;
    return a.split("|")[0];
  },
  nomeCompleto: function(id) {
    var s;
    for (;;) {
      var nome = this.nome(id);
      if (!nome) return;
      var pai = this.pai(id);
      if (s) s = nome + " - " + s;
      else s = nome;
      if (pai === "") return s;
      id = pai;
    }
  },
  assuntos: {
    "14": "DIREITO TRIBUTÁRIO|",
    "195": "DIREITO PREVIDENCIÁRIO|",
    "287": "DIREITO PENAL|",
    "864": "DIREITO DO TRABALHO|",
    "899": "DIREITO CIVIL|",
    "1146": "DIREITO MARÍTIMO|",
    "1156": "DIREITO DO CONSUMIDOR|",
    "1209": "DIREITO PROCESSUAL PENAL|",
    "1681": "Periculosidade|2594",
    "1690": "Contribuição / Taxa Assistencial|1697",
    "1691": "Contribuição Confederativa|1697",
    "1695": "Acordo e Convenção Coletivos de Trabalho|864",
    "1697": "Direito Sindical e Questões Análogas|864",
    "1703": "Eleição|1697",
    "1705": "Registro de Entidade Sindical|1697",
    "1721": "Salário In Natura|2458",
    "1723": "Assédio Moral|1855",
    "1724": "Assédio Sexual|1855",
    "1767": "Cesta Básica|2581",
    "1773": "Contribuição Sindical|1697",
    "1783": "Comissão|2581",
    "1789": "Complemento Temporário Variável de Ajuste ao Piso de Mercado|2581",
    "1849": "Culpa Recíproca|2620",
    "1855": "Indenizaçao por Dano Moral|2567",
    "1888": "Descontos Salariais - Devolução|2581",
    "1904": "Despedida / Dispensa Imotivada|2620",
    "1907": "Justa Causa / Falta Grave|2620",
    "1920": "Diárias|2581",
    "1929": "Dirigente Sindical|2656",
    "1937": "Responsabilidade Solidária / Subsidiária|864",
    "1965": "Empregado Público|2656",
    "1966": "Portador de HIV|2656",
    "1976": "Contrato Suspenso|2656",
    "1977": "Estabilidade decorrente de Norma Coletiva|2656",
    "1978": "Gestante|2656",
    "1981": "Membro da Cipa|2656",
    "1998": "Multa de 40% do FGTS|2546",
    "2019": "Fruição / Gozo|2662",
    "2021": "Indenização / Terço Constitucional|2662",
    "2055": "Gratificação|2581",
    "2117": "Supressão de Horas Extras Habituais - Indenização|2581",
    "2135": "Sentença Normativa|864",
    "2210": "Multa do Artigo 467 da CLT|2546",
    "2212": "Multa do Artigo 477 da CLT|2546",
    "2215": "Multa Prevista em Norma Coletiva|2581",
    "2243": "Plano de Demissão Voluntária|2620",
    "2273": "PIS - Indenização|2581",
    "2275": "Piso Salarial da Categoria / Salário Mínimo Profissional|2458",
    "2331": "Prêmio|2581",
    "2349": "Contribuição de Previdência Privada - Resgate|2581",
    "2364": "Plano de Saúde|2581",
    "2435": "Rescisão Indireta|2620",
    "2449": "Reajuste Salarial|2458",
    "2450": "Gorjeta|2581",
    "2452": "Salário Vencido / Retido|2458",
    "2458": "Salário / Diferença Salarial|2581",
    "2461": "Salário Família|2458",
    "2463": "Salário Paternidade|2458",
    "2466": "Salário Por Fora - Integração|2458",
    "2468": "Salário Por Safra|2458",
    "2477": "Seguro de Vida|2581",
    "2478": "Seguro Desemprego|2620",
    "2479": "Indenização|2478",
    "2480": "Liberação das Guias|2478",
    "2493": "Tarefa|2581",
    "2506": "Tíquete Alimentação|2581",
    "2540": "Vale Transporte|2581",
    "2546": "Verbas Rescisórias|2620",
    "2557": "Contrato de Aprendizagem|7628",
    "2558": "Cooperativa de Trabalho|7628",
    "2559": "Contrato de Estágio|7628",
    "2567": "Responsabilidade Civil do Empregador|864",
    "2569": "Acidente de Trabalho|1855",
    "2581": "Remuneração, Verbas Indenizatórias e Benefícios|864",
    "2583": "Abono|2581",
    "2594": "Adicional|2581",
    "2604": "Adicional de Transferência|2594",
    "2606": "Ajuda de Custo|2581",
    "2620": "Rescisão do Contrato de Trabalho|864",
    "2622": "Aposentadoria e Pensão|864",
    "2624": "Complementação de Aposentadoria / Pensão|2622",
    "2641": "AVISO PRÉVIO|2546",
    "2656": "Reintegração / Readmissão ou Indenização|2620",
    "2657": "Anistia|2656",
    "2661": "Estabilidade Acidentária|2656",
    "2662": "Férias|864",
    "2663": "Abono Pecuniário|2662",
    "2666": "Décimo Terceiro Salário|2581",
    "2697": "Salário por Equiparação / Isonomia|2458",
    "2704": "Tomador de Serviços / Terceirização|1937",
    "3369": "Crimes contra a vida|287",
    "3370": "Homicídio Simples|3369",
    "3371": "Homicídio Privilegiado|3369",
    "3372": "Homicídio Qualificado|3369",
    "3373": "Induzimento, Instigação ou Auxílio a Suicídio|3369",
    "3375": "Infanticídio|3369",
    "3385": "Lesão Corporal|287",
    "3386": "Leve|3385",
    "3387": "Seguida de Morte|3385",
    "3388": "Periclitação da Vida e da Saúde e Rixa|287",
    "3389": "Perigo de contágio de moléstia grave|3388",
    "3390": "Perigo para a vida ou saúde de outrem|3388",
    "3391": "Abandono de incapaz|3388",
    "3392": "Omissão de socorro|3388",
    "3393": "Rixa|3388",
    "3394": "Crimes contra a Honra|287",
    "3395": "Calúnia|3394",
    "3396": "Difamação|3394",
    "3397": "Injúria|3394",
    "3400": "Crimes contra a liberdade pessoal|287",
    "3401": "Constrangimento ilegal|3400",
    "3402": "Ameaça|3400",
    "3403": "Seqüestro e cárcere privado|3400",
    "3404": "Redução a condição análoga à de escravo|3400",
    "3405": "Crimes contra a inviolabilidade de domicílio|287",
    "3406": "Violação de domicílio|3405",
    "3407": "Crimes contra a inviolabilidade de correspondência|287",
    "3408": "Violação de correspondência|3407",
    "3409": "Sonegação ou destruição de correspondência|3407",
    "3410": "Violação de comunicação telegráfica, radioelétrica ou telefônica|3407",
    "3411": "Violação de Correspondência comercial|3407",
    "3412": "Crimes contra a inviolabilidade de segredo|287",
    "3413": "Divulgação de segredo|3412",
    "3414": "Violação do segredo profissional|3412",
    "3415": "Crimes contra o Patrimônio|287",
    "3416": "Furto|3415",
    "3417": "Furto Qualificado|3415",
    "3418": "Furto de coisa comum|3415",
    "3419": "Roubo|3415",
    "3420": "Extorsão|3415",
    "3421": "Extorsão mediante seqüestro|3415",
    "3422": "Extorsão indireta|3415",
    "3423": "Alteração de limites|3415",
    "3424": "Usurpação de águas|3415",
    "3425": "Esbulho possessório|3415",
    "3426": "Dano|3415",
    "3427": "Introdução ou abandono de animais em propriedade alheia|3415",
    "3428": "Dano em coisa de valor artístico, arqueológico ou histórico|3415",
    "3429": "Alteração de local especialmente protegido|3415",
    "3430": "Apropriação indébita Previdenciária|3415",
    "3431": "Estelionato|3415",
    "3432": "Estelionato Majorado|3415",
    "3433": "Outras fraudes|3415",
    "3434": "Fraude à execução|3415",
    "3435": "Receptação|3415",
    "3436": "Apropriação indébita|3415",
    "3437": "Apropriação de Coisa Havida por Erro, Caso Fortuito ou Força da Natureza|3415",
    "3438": "Crimes contra a Propriedade Industrial|3603",
    "3442": "Crimes contra a Propriedade Intelectual|287",
    "3443": "Violação de direito autoral|3442",
    "3445": "Crimes contra a Organização do Trabalho|287",
    "3446": "Atentado contra a liberdade de trabalho|3445",
    "3447": "Atentado contra a liberdade de contrato de trabalho e boicotagem violenta|3445",
    "3448": "Atentado contra a liberdade de associação|3445",
    "3449": "Paralisação de trabalho, seguida de violência ou perturbação da ordem|3445",
    "3450": "Paralisação de trabalho de interesse coletivo|3445",
    "3451": "Invasão de estabelecimento industrial, comercial ou agrícola. Sabotagem|3445",
    "3452": "Frustração de direitos assegurados por lei trabalhista|3445",
    "3453": "Exercício de atividade com infração de decisão administrativa|3445",
    "3454": "Aliciamento para fins de emigração|3445",
    "3455": "Aliciamento de trabalhadores de um local para outro do território nacional|3445",
    "3456": "Frustração de lei sobre a nacionalização do trabalho|3445",
    "3457": "Crimes contra o sentimento religioso e contra o respeito aos mortos|287",
    "3458": "Destruição / Subração / Ocultação de Cadáver|3457",
    "3459": "Impedimento ou Perturbação de Cerimônia Funerária|3457",
    "3460": "Vilipêndio a Cadáver|3457",
    "3461": "Violação a Sepultura|3457",
    "3462": "Ultraje / Impedimento ou Perturbação de Culto Religioso|3457",
    "3463": "Crimes contra a Dignidade Sexual|287",
    "3465": "Estupro|3463",
    "3466": "Atentado Violento ao Pudor|3463",
    "3467": "Atentado ao Pudor Mediante Fraude|3463",
    "3468": "Corrupção de Menores|3463",
    "3469": "Ultraje Público ao Pudor (Ato/Escrito Obsceno)|3463",
    "3471": "Mediação para Servir a Lascívia de Outrem|3463",
    "3472": "Crimes contra a Família|287",
    "3473": "Crimes Contra a Assistência Familiar|3472",
    "3474": "Abandono Material|3473",
    "3475": "Abandono Intelectual|3473",
    "3476": "Entrega de Filho Menor a Pessoa Inidônea|3473",
    "3477": "Crimes contra o Casamento|3472",
    "3479": "Bigamia|3477",
    "3480": "Conhecimento Prévio de Impedimento|3477",
    "3481": "Induzimento a erro essencial e ocultação de impedimento|3477",
    "3482": "Simulação de Casamento|3477",
    "3483": "Simulação de Autoridade para Celebração de Casamento|3477",
    "3484": "Crimes Contra o Estado de Filiação|3472",
    "3485": "Parto Suposto|3484",
    "3486": "Registro de Nascimento Inexistente|3484",
    "3487": "Sonegação do Estado de Filiação|3484",
    "3488": "Crime contra o Pátrio-Poder e Tutela|3472",
    "3489": "Induzimento a Fuga, Entrega Arbitrária ou Sonegação de Incapazes|3488",
    "3490": "Subtração de Incapazes|3488",
    "3491": "Crimes contra a Incolumidade Pública|287",
    "3492": "Incêndio|3491",
    "3493": "Explosão|3491",
    "3494": "Uso de gás tóxico ou asfixiante|3491",
    "3495": "Inundação / Perigo de Inundação|3491",
    "3496": "Desabamento ou desmoronamento|3491",
    "3497": "Subtração, ocultação ou inutilização de material de salvamento|3491",
    "3498": "Difusão de doença ou praga|3491",
    "3500": "Desastre ferroviário / Perigo de Desastre Ferroviário|3491",
    "3501": "Atentado contra a segurança de transporte público|3491",
    "3502": "Arremesso de projétil|3491",
    "3503": "Atentado contra a segurança de serviços de utilidade pública|3491",
    "3504": "Interrupção /perturbação de serviços telegráficos/telefônicos|3491",
    "3505": "Epidemia|3491",
    "3506": "Emprego de Processo Proibido/Substância não permitida|3491",
    "3507": "Invólucro ou recipiente com falsa indicação|3491",
    "3508": "Falsificação / Corrupção / Adulteração / Alteração de produto destinado a fins terapêuticos ou medicinais|3491",
    "3509": "Exercício Ilegal da Medicina, Arte Dentária ou Farmacêutica|3491",
    "3510": "Charlatanismo|3491",
    "3511": "Corrupção ou Poluição de Água Potável|3491",
    "3512": "Curandeirismo|3491",
    "3513": "Envenenamento de Água Potável / Substância Alimentícia ou Medicinal|3491",
    "3514": "Falsificação / Corrupção / Adulteração / Alteração de Substância ou Produtos Alimentícios|3491",
    "3515": "Infração de Medida Sanitária Preventiva|3491",
    "3516": "Omissão de Notificação de Doença|3491",
    "3517": "Outras Substâncias Nocivas a Saúde Pública|3491",
    "3519": "Substância Destinada à Falsificação|3491",
    "3520": "Crimes contra a Paz Pública|287",
    "3521": "Quadrilha ou Bando|3520",
    "3523": "Crimes contra a Fé Pública|287",
    "3524": "Moeda Falsa / Assimilados|3523",
    "3527": "Petrechos para falsificação de moeda|3523",
    "3528": "Emissão de título ao portador sem permissão legal|3523",
    "3529": "Falsificação de papéis públicos|3523",
    "3530": "Falsificação do selo ou sinal público|3523",
    "3531": "Falsificação de documento público|3523",
    "3532": "Falsificação de documento particular|3523",
    "3533": "Falsidade ideológica|3523",
    "3534": "Falso reconhecimento de firma ou letra|3523",
    "3535": "Certidão ou atestado ideologicamente falso|3523",
    "3536": "Falsidade material de atestado ou certidão|3523",
    "3537": "Falsidade de atestado médico|3523",
    "3538": "Reprodução ou adulteração de selo ou peça filatélica|3523",
    "3539": "Uso de documento falso|3523",
    "3540": "Supressão de documento|3523",
    "3541": "Falsificação do sinal empregado no contraste de metal precioso ou na fiscalização alfandegária, ou para outros fins|3523",
    "3542": "Falsa identidade|3523",
    "3543": "Fraude de lei sobre estrangeiros|3523",
    "3545": "Petrechos de Falsificação de Papéis Públicos|3523",
    "3546": "Adulteração de Sinal Identificador de Veículo Automotor|3523",
    "3547": "Crimes Praticados por Funcionários Públicos Contra a Administração em Geral|287",
    "3548": "Peculato|3547",
    "3550": "Peculato mediante erro de outrem|3547",
    "3551": "Extravio, sonegação ou inutilização de livro ou documento|3547",
    "3552": "Emprego irregular de verbas ou rendas públicas|3547",
    "3553": "Concussão|3547",
    "3554": "Excesso de exação|3547",
    "3555": "Corrupção passiva|3547",
    "3556": "Facilitação de contrabando ou descaminho|3547",
    "3557": "Prevaricação|3547",
    "3558": "Condescendência criminosa|3547",
    "3559": "Advocacia administrativa|3547",
    "3560": "Violência arbitrária|3547",
    "3561": "Abandono de função|3547",
    "3562": "Exercício funcional ilegalmente antecipado ou prolongado|3547",
    "3563": "Violação do sigilo funcional|3547",
    "3564": "Violação do sigilo de proposta de concorrência|3547",
    "3565": "Usurpação de função pública|5872",
    "3566": "Resistência|5872",
    "3567": "Tráfico de influência|5872",
    "3568": "Corrupção ativa|5872",
    "3569": "Impedimento, perturbação ou fraude de concorrência|5872",
    "3570": "Inutilização de edital ou de sinal|5872",
    "3571": "Subtração ou inutilização de livro ou documento|5872",
    "3572": "Desobediência|5872",
    "3573": "Desacato|5872",
    "3574": "Contrabando ou descaminho|5872",
    "3575": "Reingresso de estrangeiro expulso|5874",
    "3576": "Denunciação caluniosa|5874",
    "3577": "Comunicação falsa de crime ou de contravenção|5874",
    "3578": "Auto-acusação falsa|5874",
    "3579": "Falso testemunho ou falsa perícia|5874",
    "3580": "Coação no curso do processo|5874",
    "3581": "Exercício arbitrário das próprias razões|5874",
    "3582": "Fraude processual|5874",
    "3583": "Favorecimento pessoal|5874",
    "3584": "Favorecimento real|5874",
    "3585": "Exercício arbitrário ou abuso de poder|5874",
    "3586": "Fuga de pessoa presa ou submetida a medida de segurança|5874",
    "3587": "Evasão mediante violência contra a pessoa|5874",
    "3588": "Arrebatamento de preso|5874",
    "3589": "Motim de presos|5874",
    "3590": "Patrocínio infiel|5874",
    "3591": "Patrocínio simultâneo ou tergiversação|5874",
    "3592": "Sonegação de papel ou objeto de valor probatório|5874",
    "3593": "Exploração de prestígio|5874",
    "3594": "Violência ou fraude em arrematação judicial|5874",
    "3595": "Desobediência a decisão judicial sobre perda ou suspensão de direitos|5874",
    "3596": "Inserção de dados falsos em sistema de informações|3547",
    "3597": "Modificação ou alteração não autorizada de sistema de informações|3547",
    "3598": "Sonegação de contribuição previdenciária|5872",
    "3603": "Crimes Previstos na Legislação Extravagante|287",
    "3604": "Crimes de Responsabilidade|3603",
    "3605": "Crimes contra a Economia Popular|3603",
    "3606": "Crimes de Abuso de Autoridade|3603",
    "3607": "Crimes de Tráfico Ilícito e Uso Indevido de Drogas|3603",
    "3608": "Tráfico de Drogas e Condutas Afins|3607",
    "3609": "Responsabilidade Criminal por Danos Nucleares|3603",
    "3610": "Crimes contra o Serviço Postal e o Serviço de Telegrama|3603",
    "3611": "Crimes contra a Segurança Nacional, a Ordem Política e Social|3603",
    "3612": "Crimes contra o Sistema Financeiro Nacional|3603",
    "3613": "Crimes Resultante de Preconceito de Raça ou de Cor|3603",
    "3614": "Crimes contra a Ordem Tributária|3603",
    "3615": "Crimes contra a Ordem Econômica|3603",
    "3616": "Crimes contra as Relações de Consumo|3603",
    "3617": "Representação caluniosa|3603",
    "3618": "Crimes contra o Meio Ambiente e o Patrimônio Genético|3603",
    "3619": "Crimes contra a Fauna|3618",
    "3620": "Crimes contra a Flora|3618",
    "3621": "Da Poluição|3618",
    "3622": "Agrotóxicos|3618",
    "3623": "Atividades Nucleares|3618",
    "3624": "Caça|3618",
    "3626": "Liberação ou Descarte de OGM (Organismo Geneticamente Modificado)|3618",
    "3627": "Pesca|3618",
    "3628": "Crimes de &quot;Lavagem&quot; ou Ocultação de Bens, Direitos ou Valores|3603",
    "3629": "Crimes contra as Telecomunicações|3603",
    "3630": "Crimes Previstos na Lei de Estrangeiros|3603",
    "3631": "Crimes de Tortura|3603",
    "3632": "Crimes de Trânsito|3603",
    "3633": "Crimes do Sistema Nacional de Armas|3603",
    "3634": "Crimes de Imprensa|3603",
    "3637": "Crimes Previstos no Estatuto da criança e do adolescente|3603",
    "3638": "Crimes contra o mercado de capitais|3603",
    "3640": "Crimes de genocídio|3603",
    "3641": "Sigilo Telefônico|3603",
    "3642": "Crimes da Lei de licitações|3603",
    "3643": "Crimes da Lei de remoção de órgãos e tecidos|3603",
    "3647": "Crimes praticados contra os índios e a cultura indígena|3603",
    "3650": "Recusa, retardamento ou omissão de dados técnicos indispensáveis à propositura de Ação Civil Pública|3603",
    "3651": "Crimes Agrários|3603",
    "3653": "Crimes relacionados à Comissão Parlamentar de Inquérito|3603",
    "3654": "Crimes Eleitorais|3603",
    "3655": "Crime de Quebra de Sigilo Financeiro|3603",
    "3656": "Crimes do Código Brasileiro de Telecomunicações|3603",
    "3659": "Crimes Previstos no Estatuto do Idoso|3603",
    "3660": "Parcelamento do solo urbano|3603",
    "3661": "Crimes Falimentares|3603",
    "3664": "Crimes Militares|3603",
    "3692": "Contravenções Penais|287",
    "4263": "Ação Penal|1209",
    "4264": "Nulidade|4263",
    "4271": "Suspensão|4263",
    "4272": "Trancamento|4263",
    "4291": "Jurisdição e Competência|1209",
    "4305": "Recurso|1209",
    "4310": "Fiança|1209",
    "4355": "Prisão Preventiva|1209",
    "4368": "Denúncia/Queixa|1209",
    "4370": "Recebimento|4368",
    "4371": "Rejeição|4368",
    "4435": "Aplicabilidade|1695",
    "4437": "Revisão|2135",
    "4438": "Anulação|1695",
    "4442": "Restituição / Indenização de Despesa|2581",
    "4452": "Representação Sindical|1697",
    "4654": "Propriedade Intelectual / Industrial|10448",
    "4656": "Direito Autoral|4654",
    "4660": "Patente|4654",
    "4670": "Desenho Industrial|4654",
    "4680": "Marca|4654",
    "4701": "Ato / Negócio Jurídico|7947",
    "4703": "Defeito, nulidade ou anulação|4701",
    "4706": "Evicção ou Vicio Redibitório|4701",
    "4718": "Cessão de Crédito|7688",
    "4728": "Câmbio|9580",
    "4794": "Parceria Agrícola e/ou pecuária|9580",
    "4805": "Previdência privada|9580",
    "4813": "Representação comercial|9580",
    "4829": "Atraso de vôo|4862",
    "4830": "Cancelamento de vôo|4862",
    "4831": "Overbooking|4862",
    "4832": "Extravio de bagagem|4862",
    "4839": "Sistema Financeiro da Habitação|9580",
    "4840": "Equivalência salarial|4839",
    "4841": "Quitação|4839",
    "4842": "Reajuste de Prestações|4839",
    "4843": "Transferência de Financiamento (contrato de gaveta)|4839",
    "4846": "Sustação/Alteração de Leilão|4839",
    "4847": "Seguro|4839",
    "4854": "Revisão do Saldo Devedor|4839",
    "4862": "Transporte Aéreo|7771",
    "4897": "Associação|9981",
    "4899": "Assembléia|4897",
    "4902": "Eleição|4897",
    "4904": "Extinção|4897",
    "4905": "Fundação de Direito Privado|9981",
    "4907": "Assembléia|4905",
    "4909": "Eleição|4905",
    "4910": "Extinção|4905",
    "4933": "Apuração de haveres|5724",
    "4934": "Constituição|5724",
    "4935": "Dissolução|5724",
    "4939": "Desconsideração da Personalidade Jurídica|5724",
    "4940": "Ingresso e Exclusão dos Sócios na Sociedade|5724",
    "4942": "Responsabilidade dos sócios e administradores|5724",
    "4943": "Transferência de cotas|5724",
    "4949": "Títulos de Crédito|7681",
    "4951": "Anulação|4949",
    "4957": "Requisitos|4949",
    "4960": "Cédula de Crédito Bancário|7717",
    "4961": "Cédula de Crédito à Exportação|7717",
    "4962": "Cédula de Crédito Comercial|7717",
    "4963": "Cédula de Crédito Industrial|7717",
    "4964": "Cédula de Crédito Rural|7717",
    "4968": "Cédula de Produto Rural|7717",
    "4969": "Cédula Hipotecária|7717",
    "4970": "Cheque|7717",
    "4971": "Debêntures|7717",
    "4972": "Duplicata|7717",
    "4973": "Letra de Câmbio|7717",
    "4974": "Nota de Crédito Comercial|7717",
    "4975": "Nota de Crédito Industrial|7717",
    "4976": "Nota de Crédito Rural|7717",
    "4980": "Nota Promissória|7717",
    "4981": "Warrant|7717",
    "4993": "Recuperação judicial e Falência|9616",
    "4994": "Recuperação extrajudicial|4993",
    "4998": "Autofalência|4993",
    "5000": "Concurso de Credores|4993",
    "5001": "Liquidação|4993",
    "5003": "Revogação de atos praticados em prejuízo de credores e da massa|4993",
    "5009": "Mercado de Capitais|9616",
    "5010": "Bolsa de Valores|5009",
    "5193": "Engajamento e Profissionais Marítimos|7783",
    "5194": "Seguros Marítimos|7783",
    "5196": "Aluguel de Embarcações (Fretamento E Carta Partida)|7783",
    "5269": "Mínimo|2458",
    "5276": "Advogados|7644",
    "5277": "Aeronautas|7644",
    "5278": "Artistas|7644",
    "5279": "Atleta Profissional|7644",
    "5280": "Bancários|7644",
    "5281": "Cabineiros de Elevador|7644",
    "5282": "Doméstico|7644",
    "5284": "Engenheiro, Arquiteto e Engenheiro Agrônomo|7644",
    "5286": "Jornalistas|7644",
    "5287": "Marítimos|7644",
    "5288": "Digitadores / Mecanógrafos / Datilógrafos|7644",
    "5289": "Médicos|7644",
    "5290": "Mineiros de Subsolos|7644",
    "5291": "Músicos Profissionais|7644",
    "5292": "Operadores de Carga e Descarga (Estiva e Capatazia)|7644",
    "5293": "Operadores Cinematográficos|7644",
    "5294": "Professores|7644",
    "5295": "Químicos|7644",
    "5296": "Radialistas|7644",
    "5297": "Serviços de Telefonia ou Telegrafia|7644",
    "5299": "Trabalhadores em Petróleo|7644",
    "5301": "Vigia e Vigilantes|7644",
    "5356": "Grupo Econômico|1937",
    "5555": "Crime Tentado|287",
    "5556": "Grave|3385",
    "5557": "Gravíssima|3385",
    "5558": "Privilegiada|3385",
    "5560": "Decorrente de Violência Doméstica|3385",
    "5564": "Furto Privilegiado|3415",
    "5565": "Furto de Veículo Automotor a ser Transportado para outro Estado ou Exterior|3415",
    "5566": "Roubo Majorado|3415",
    "5567": "Latrocínio|3415",
    "5568": "Extorsão mediante Seqüestro Seguida de Morte|3415",
    "5569": "Extorsão mediante Sequestro Seguida de Lesão Corporal Grave|3415",
    "5570": "Supressão ou Alteração de Marca em Animais|3415",
    "5571": "Dano Qualificado|3415",
    "5572": "Apropriação de Tesouro|3415",
    "5573": "Apropriação de Coisa Achada|3415",
    "5575": "Abandono|7784",
    "5577": "Acidentes da Navegação|7784",
    "5585": "Registro / Cadastro do Armador|1146",
    "5589": "Arresto de Embarcação|7784",
    "5591": "Assistência / Salvamento|7784",
    "5592": "Avaria|7784",
    "5595": "Responsabilidade do Comandante ou Capitão|1146",
    "5603": "Inscrição / Registro da Embarcação|1146",
    "5609": "Hipoteca Marítima|7783",
    "5612": "Créditos / Privilégios Marítimos|7783",
    "5621": "Serviços Auxiliares da Navegação|1146",
    "5622": "Agenciamento|5621",
    "5623": "Corretagem de Embarcação|5621",
    "5624": "Praticagem|5621",
    "5626": "Família|899",
    "5632": "Prescrição e Decadência|7947",
    "5680": "Edição|9580",
    "5724": "Sociedade|9616",
    "5754": "Pessoas naturais|899",
    "5779": "Alimentos|5626",
    "5787": "Exoneração|5779",
    "5788": "Revisão|5779",
    "5801": "Busca e Apreensão de Menores|10577",
    "5802": "Guarda|10577",
    "5804": "Investigação de Paternidade|10577",
    "5805": "Regulamentação de Visitas|10577",
    "5808": "Casamento|5626",
    "5813": "Nulidade / Anulação|5808",
    "5825": "Nulidade e Anulação de Testamento|7673",
    "5829": "Nulidade e Anulação de Partilha e Adjudicação de Herança|7673",
    "5831": "Sub-rogação de Vinculo|7673",
    "5832": "Exclusão de herdeiro ou legatário|7673",
    "5833": "Petição de Herança|7673",
    "5834": "Deserdação|7673",
    "5835": "Disposição de coisa alheia como própria|3415",
    "5836": "Alienação ou Oneração Fraudulenta de Coisa Própria|3415",
    "5837": "Defraudação de Penhor|3415",
    "5838": "Fraude na Entrega de Coisa|3415",
    "5839": "Fraude para Recebimento de Indenização ou Valor de Seguro|3415",
    "5840": "Fraude no Pagamento por Meio de Cheque|3415",
    "5841": "Duplicata Simulada|3415",
    "5842": "Abuso de Incapazes|3415",
    "5843": "Induzimento à Especulação|3415",
    "5844": "Fraude no Comércio|3415",
    "5845": "Fraudes e Abusos na Fundação ou Administração de Sociedade por Ações|3415",
    "5846": "Emissão Irregular de Conhecimento de Depósito ou &quot;Warrant&quot;|3415",
    "5847": "Receptação Qualificada|3415",
    "5850": "Posse Sexual Mediante Fraude|3463",
    "5851": "Assédio Sexual|3463",
    "5852": "Favorecimento da Prostituição|3463",
    "5853": "Casa de Prostituição|3463",
    "5854": "Rufianismo|3463",
    "5855": "Tráfico Internacional de Pessoas|3463",
    "5856": "Tráfico Interno de Pessoas|3463",
    "5862": "Fabrico / Fornecimento / Aquisição / Posse ou Transporte de Explosivos ou Gás Tóxico ou Asfixiante|3491",
    "5865": "Crime Culposo|287",
    "5866": "Venda de Produtos ou Substância nas Condições dos Artigos 274 e 275|3491",
    "5867": "Medicamento em Desacordo com Receita Médica|3491",
    "5869": "Incitação ao Crime|3520",
    "5870": "Apologia de Crime ou Criminoso|3520",
    "5872": "Crimes Praticados por Particular Contra a Administração em Geral|287",
    "5873": "Crimes Praticados por Particular Contra a Administração Pública Estrangeira|287",
    "5874": "Crimes Contra a Administração da Justiça|287",
    "5875": "Crimes Contra as Finanças Públicas|287",
    "5876": "Corrupção Ativa em Transação Comercial Internacional|5873",
    "5877": "Tráfico de Influência em Transação Comercial Internacional|5873",
    "5878": "Crimes contra Patente de Invenção|3438",
    "5879": "Crimes contra os Desenhos Industriais|3438",
    "5880": "Crimes contra as Marcas|3438",
    "5881": "Crimes Cometidos por Meio de Marca, Título de Estabelecimento e Sinal de Propaganda|3438",
    "5882": "Crimes contra Indicações Geeográficas e Demais Indicações|3438",
    "5883": "Crimes de Concorrência Desleal|3438",
    "5885": "Posse de Drogas para Consumo Pessoal|3607",
    "5893": "Crime Contra a Administração da Justiça - Lei da Ação de Alimentos|3603",
    "5894": "Fabricação de Objeto Destinado a Produção de Drogas e Condutas Afins|3607",
    "5895": "Indução, Instigação ou Auxílio ao Uso de Drogas|3607",
    "5896": "Oferecimento de Drogas para Consumo Conjunto|3607",
    "5897": "Associação para a Produção e Tráfico e Condutas Afins|3607",
    "5898": "Financiamento ou Custeio de Produção ou Tráfico de Drogas|3607",
    "5899": "Colaboração com Grupo, Organização ou Associação Destinados à Produção ou Tráfico de Drogas|3607",
    "5900": "Prescrição Culposa de Drogas|3607",
    "5901": "Condução de Embarcação ou Aeronave sob Efeito de Drogas|3607",
    "5903": "Contratação de Operação de Crédito|5875",
    "5904": "Inscrição de Despesas Não Empenhadas|5875",
    "5905": "Assunção de Obrigação no Último Ano do Mandato ou Legislatura|5875",
    "5906": "Ordenação de Despesa Não Autorizada|5875",
    "5907": "Prestação de Garantia Graciosa|5875",
    "5908": "Não Cancelamento de Restos a Pagar|5875",
    "5909": "Aumento de Despesa com Pessoal no Último Ano do Mandato ou Legislatura|5875",
    "5910": "Oferta Pública ou Colocação de Títulos no Mercado|5875",
    "5911": "Perigo de Contágio Venéreo|3388",
    "5913": "Limitações ao Poder de Tributar|14",
    "5914": "Imunidade|5913",
    "5915": "Isenção|5913",
    "5916": "Impostos|14",
    "5917": "IRPF/Imposto de Renda de Pessoa Física|5916",
    "5918": "Incidência sobre PDV|5917",
    "5919": "Incidência sobre Aposentadoria|5917",
    "5920": "Incidência sobre Aplicações Financeiras|5917",
    "5921": "Incidência sobre Licença-Prêmio/Abono/Indenização|5917",
    "5922": "Retido na fonte|5917",
    "5923": "Incidência sobre Férias Compensadas|5917",
    "5924": "Incidência sobre Proventos de Previdência Privada|5917",
    "5925": "Incidência sobre Participação nos Lucros|5917",
    "5926": "Incidência sobre Lucro Imobiliário|5917",
    "5927": "Incidência sobre 1/3 de férias (art. 7º, XVII da CF)|5917",
    "5928": "Incidência sobre 13° Salário|5917",
    "5929": "Incidência sobre Função Comissionada|5917",
    "5930": "Incidência sobre Hora Extra|5917",
    "5931": "Incidência decorrente de Desligamento de Plano de Previdência Privada|5917",
    "5932": "Incidência decorrente de Liquidação de Entidade de Previdência Privada|5917",
    "5933": "IRPJ/Imposto de Renda de Pessoa Jurídica|5916",
    "5934": "Microempresa|5933",
    "5935": "Demonstrações Financeiras (DCTF)|5933",
    "5936": "Incidência sobre Aplicações Financeiras|5933",
    "5937": "Retido na fonte|5933",
    "5938": "Incidência sobre Lucro|5933",
    "5939": "Cooperativa|5933",
    "5940": "Compensação de Prejuízos|5933",
    "5941": "II/ Imposto sobre Importação|5916",
    "5942": "Importação de bens usados|5941",
    "5943": "Drawback|5941",
    "5944": "IE/ Imposto sobre Exportação|5916",
    "5945": "IPI/ Imposto sobre Produtos Industrializados|5916",
    "5946": "ICMS/ Imposto sobre Circulação de Mercadorias|5916",
    "5947": "ICMS/Importação|5946",
    "5948": "IOC/IOF Imposto sobre operações de crédito, câmbio e seguro, ou relativas a títulos ou valores mobiliários|5916",
    "5949": "Incidência sobre Ações de Companhias Abertas|5948",
    "5950": "ITR/ Imposto Territorial Rural|5916",
    "5951": "ISS/ Imposto sobre Serviços|5916",
    "5952": "IPTU/ Imposto Predial e Territorial Urbano|5916",
    "5953": "IPVA - Imposto Sobre Propriedade de Veículos Automotores|5916",
    "5954": "ITBI - Imposto de Transmissão Intervivos de Bens Móveis e Imóveis|5916",
    "5955": "ITCD - Imposto de Transmissão Causa Mortis|5916",
    "5956": "Taxas|14",
    "5957": "Federais|5956",
    "5958": "Taxa Anual por Hectare|5957",
    "5959": "Taxa de Armazenamento|5957",
    "5960": "Taxa de Despacho Aduaneiro|5957",
    "5961": "Taxa de Exploração Mineral|5957",
    "5962": "Taxa de Fiscalização Ambiental|5957",
    "5963": "Taxa de Guia de Importação|5957",
    "5964": "Taxa de Licença de Importação|5957",
    "5965": "Taxa de Melhoramento de Portos|5957",
    "5966": "Taxa de Permanência|5957",
    "5967": "Taxa de Saúde Suplementar|5957",
    "5968": "Taxa de Fiscalização da Comissão de Valores Mobiliários|5957",
    "5969": "Taxa Judiciária|5957",
    "5970": "Taxa de Aferição de Equipamentos de Metrologia|5957",
    "5971": "Estaduais|5956",
    "5972": "Municipais|5956",
    "5973": "Empréstimos Compulsórios|14",
    "5974": "Aquisição de veículos automotores|5973",
    "5975": "Aquisição de combustíveis|5973",
    "5976": "Aquisição de passagens e moedas - Viagem Exterior|5973",
    "5977": "Energia Elétrica|5973",
    "5978": "Obrigação Tributária|14",
    "5979": "Responsabilidade tributária|5978",
    "5980": "Responsabilidade Tributária do Sócio-Gerente / Diretor / Representante|5979",
    "5981": "Substituição Tributária|5979",
    "5982": "DIMOB/Declaração de Informações sobre Atividades Imobilíárias|5978",
    "5983": "CNPJ/Cadastro Nacional de Pessoas Jurídicas|5978",
    "5984": "CPF/Cadastro de Pessoas Físicas|5978",
    "5985": "Obrigação Acessória|5978",
    "5986": "Crédito Tributário|14",
    "5987": "Suspensão da Exigibilidade|5986",
    "5988": "Carta de fiança|5987",
    "5989": "Parcelamento|5987",
    "5990": "Extinção do Crédito Tributário|5986",
    "5991": "Compensação com Apólices da Dívida Pública|5990",
    "5992": "Prescrição|5990",
    "5993": "Decadência|5990",
    "5994": "Compensação|5990",
    "5995": "TDA/Títulos da Dívida Agrária|5990",
    "5996": "Anistia|5986",
    "5997": "Denúncia espontânea|5986",
    "5998": "Lançamento|5986",
    "5999": "CND/Certidão Negativa de Débito|5986",
    "6000": "Expedição de CND|5999",
    "6001": "Expedição de Certidão Positiva de Débito com Efeito de Negativa|5999",
    "6002": "Certificado de Regularidade - FGTS|5999",
    "6003": "Incentivos fiscais|5986",
    "6004": "Anulação de Débito Fiscal|5986",
    "6005": "Juros/Correção Monetária|5986",
    "6006": "Prazo de Recolhimento|5986",
    "6007": "Repetição de indébito|5986",
    "6008": "Base de Cálculo|5986",
    "6009": "Crédito Prêmio|5986",
    "6010": "Crédito Presumido|5986",
    "6011": "Fato Gerador/Incidência|5986",
    "6012": "Alíquota|5986",
    "6013": "Alíquota Zero|6012",
    "6014": "Alíquota Progressiva|6012",
    "6015": "Índice da Alíquota|6012",
    "6016": "Creditamento|5986",
    "6017": "Dívida Ativa|14",
    "6018": "Processo Administrativo Fiscal|14",
    "6019": "Depósito Prévio ao Recurso Administrativo|6018",
    "6020": "Arrolamento de Bens|6018",
    "6021": "Procedimentos Fiscais|14",
    "6022": "Sigilo Fiscal|6021",
    "6023": "Utilização de Dados Relativos à CPMF para Fins de Fiscalização|6022",
    "6024": "Liberação de mercadorias|6021",
    "6025": "Levantamento de depósito|6021",
    "6026": "Cadastro de Inadimplentes - CADIN|6021",
    "6027": "Autorização para Impressão de Documentos Fiscais - AIDF|6021",
    "6028": "Liberação de Veículo Apreendido|6021",
    "6029": "Perdimento de Bens|6021",
    "6030": "GATT - &quot;Acordo Geral sobre Tarifas e Comércio&quot;|14",
    "6031": "Contribuições|14",
    "6032": "Contribuições de Melhoria|6031",
    "6033": "Contribuições Sociais|6031",
    "6034": "Finsocial|6033",
    "6035": "Cofins|6033",
    "6036": "Contribuição Social sobre o Lucro Líquido|6033",
    "6037": "Salário-Educação|6033",
    "6038": "Seguro Acidentes do Trabalho|6033",
    "6039": "PIS|6033",
    "6040": "Funrural|6033",
    "6041": "Contribuição INCRA|6033",
    "6042": "PASEP|6033",
    "6043": "Construção Civil|6033",
    "6044": "Contribuições Corporativas|6031",
    "6045": "Contribuições para o SEBRAE, SESC, SENAC, SENAI e outros|6044",
    "6046": "Conselhos Regionais e Afins (Anuidade)|6044",
    "6047": "Contribuição Sindical|6044",
    "6048": "Contribuições Previdenciárias|6031",
    "6049": "Servidores Ativos|6048",
    "6050": "Servidores Inativos|6048",
    "6051": "Mandato Eletivo/Lei 9.506/97|6048",
    "6052": "Aposentadoria/Retorno aoTrabalho|6048",
    "6053": "Cargo em Comissão|6048",
    "6054": "Tempo de Serviço Urbano/Contribuições não Recolhidas|6048",
    "6055": "Tempo de Serviço Rural/Contribuições não Recolhidas|6048",
    "6056": "Gratificação Natalina/13º Salário|6048",
    "6057": "Produção Agropecuária|6048",
    "6058": "Indenização Trabalhista|6048",
    "6059": "Reembolso auxílio-creche|6048",
    "6060": "Contribuição sobre a folha de salários|6048",
    "6061": "Devolução de contribuições previdenciárias pagas além do teto|6048",
    "6062": "1/3 de férias|6048",
    "6063": "Auxílio-Alimentação|6048",
    "6064": "Custeio de Assistência Médica|6048",
    "6065": "Hora Extra|6048",
    "6066": "Massa Falida- Recolhimento|6048",
    "6067": "Nota Fiscal ou Fatura|6048",
    "6068": "Salário-Maternidade|6048",
    "6069": "Contribuição sobre Nota Fiscal de Execução de Serviços|6048",
    "6070": "Contribuição de Autônomos, Empresários (Pró-labore) e Facultativos|6048",
    "6071": "Contribuições Especiais|6031",
    "6072": "FNT/Fundo Nacional de Telecomunicações|6071",
    "6073": "ATP/Adicional de Tarifa Portuária|6071",
    "6074": "AITP/Adicional de Indenização do Trabalhador Portuário Avulso|6071",
    "6075": "Adicional de Tarifa Aeroportuária|6071",
    "6076": "AFRMM/Adicional ao Frete para Renovação da Marinha Mercante|6071",
    "6077": "FUNDEF/Fundo de Manutenção e Desenvolvimento do Ensino Fundamental e de Valorização do Magistério|6071",
    "6078": "Seguro Apagão (Lei 10.438/02)|6071",
    "6079": "Contribuição sobre licença de uso ou transferência de tecnologia|6071",
    "6080": "Contribuição de Intervenção no Domínio Econômico (CIDE)|6071",
    "6081": "FUNDAF/Fundo Especial de Desenvolvimento e Aperfeiçoamento das Atividades de Fiscalização|6071",
    "6082": "FUSEX/FUNSA/FUSMA/Fundo de Saúde das Forças Armadas|6071",
    "6083": "Contribuição de Iluminação Pública|6071",
    "6084": "IUEE/Imposto Único sobre Energia Elétrica|6071",
    "6085": "FGTS/Fundo de Garantia Por Tempo de Serviço|6071",
    "6086": "Contribuição sobre Açúcar e Álcool|6071",
    "6087": "CPMF/Contribuição Provisória sobre Movimentação Financeira|6071",
    "6088": "Discriminação Tributária MERCOSUL|14",
    "6089": "Regimes Especiais de Tributação|14",
    "6090": "REFIS/Programa de Recuperação Fiscal|6089",
    "6091": "PAES/Parcelamento Especial|6089",
    "6092": "SIMPLES|6089",
    "6093": "Super SIMPLES|6089",
    "6094": "Benefícios em Espécie|195",
    "6095": "Aposentadoria por Invalidez|6094",
    "6096": "Aposentadoria por Idade (Art. 48/51)|6094",
    "6097": "Urbana (Art. 48/51)|6096",
    "6098": "Rural (Art. 48/51)|6096",
    "6099": "Aposentadoria por Tempo de Serviço (Art. 52/4)|6094",
    "6100": "Aposentadoria Especial (Art. 57/8)|6094",
    "6101": "Auxílio-Doença Previdenciário|6094",
    "6102": "Salário-Família (Art. 65/70)|6094",
    "6103": "Salário-Maternidade (Art. 71/73)|6094",
    "6104": "Pensão por Morte (Art. 74/9)|6094",
    "6105": "Auxílio-Reclusão (Art. 80)|6094",
    "6106": "Pecúlios (Art. 81/5)|6094",
    "6107": "Auxílio-Acidente (Art. 86)|6094",
    "6108": "Incapacidade Laborativa Parcial|6107",
    "6109": "Incapacidade Laborativa Permanente|6107",
    "6110": "Incapacidade Laborativa Temporária|6107",
    "6111": "Movimentos Repetitivos/Tenossinovite/LER/DORT|6107",
    "6112": "Redução da Capacidade Auditiva|6107",
    "6113": "Abono de Permanência em Serviço (Art. 87)|6094",
    "6114": "Benefício Assistencial (Art. 203,V CF/88)|6094",
    "6115": "Ferroviário|6094",
    "6116": "Ex-combatentes|6094",
    "6117": "Renda Mensal Vitalícia|6094",
    "6118": "Aposentadoria por Tempo de Contribuição (Art. 55/6)|6094",
    "6119": "RMI - Renda Mensal Inicial, Reajustes e Revisões Específicas|195",
    "6120": "RMI - Renda Mensal Inicial|6119",
    "6121": "RMI pelo art. 202 CF/88 (média dos 36 últimos salários-de-contribuição)|6120",
    "6122": "RMI pelo art. 1º da Lei 6.423/77 - índices de atualização dos 24 1ºs salários-de-contribuição, anteriores aos 12 últimos)|6120",
    "6123": "RMI pela equivalência entre Salário-de-Benefício e Salário-de-Contribuição|6120",
    "6124": "RMI sem incidência de Teto Limitador|6120",
    "6125": "RMI da pensão de dependente de ex-combatente|6120",
    "6126": "Benefício mínimo a partir da CF/88 (art. 201, § 2º CF/88)|6120",
    "6127": "Parcelas e índices de correção do salário-de-contribuição|6120",
    "6128": "Limitação do salário-de-benefício e da renda mensal inicial|6120",
    "6129": "RMI cuja salário-de-benefício supera menor valor teto|6120",
    "6130": "Escala de Salário-Base|6120",
    "6131": "Contribuição sobre vinte salários mínimos|6120",
    "6132": "Cálculo do Benefício de acordo com a Sistemática anterior à Lei 9.876/99|6120",
    "6133": "IRSM de Fevereiro de 1994(39,67%)|6120",
    "6134": "Alteração do coeficiente de cálculo de pensão|6120",
    "6135": "Alteração do coeficiente de cálculo do benefício|6120",
    "6136": "Cálculo do benefício de segurado especial de acordo com a Lei 9.876/99|6120",
    "6137": "Cálculo do fator previdenciário - Lei 9.876/99|6120",
    "6138": "Reajustes e Revisões Específicos|6119",
    "6139": "Reajuste pela Súmula 260 do TFR|6138",
    "6140": "Art. 58 ADCT da CF/88|6138",
    "6141": "Utilização do PNS no Reajuste de Benefícios|6138",
    "6142": "Manutenção do Benefício pela equivalência salarial|6138",
    "6143": "Expurgos inflacionários sobre os benefícios|6138",
    "6144": "Salário Mínimo de Ncz$ 120,00 para junho/89|6138",
    "6145": "Reajuste de 147%|6138",
    "6146": "Reajustamento pelo IGP-DI|6138",
    "6147": "Aplicação de coeficiente de cálculo diverso do fixado na Lei n.º 8.213/91|6138",
    "6148": "Reajuste aplicado ao salário mínimo em setembro/94|6138",
    "6149": "Sistemática de conversão dos benefícios previdenciários em URVs|6138",
    "6150": "Reajustamento pelo INPC|6138",
    "6151": "Art. 144 da Lei 8.213/91 e/ou diferenças decorrentes|6138",
    "6152": "Gratificação Natalina a partir da CF/88 (Art. 201, § 6º CF/88)|6138",
    "6153": "Abono da Lei 8.178/91|6138",
    "6154": "Índice de 4,02% da Lei 8.222/91|6138",
    "6155": "Desconto do DL 1.910/81|6138",
    "6156": "Descontos dos benefícios|6138",
    "6157": "Correção Monetária pela Súmula 71 TFR|6138",
    "6158": "Correção Monetária de Benefício pago com atraso|6138",
    "6159": "Reajuste conforme Portaria MPAS 714/1993|6138",
    "6160": "Disposições Diversas Relativas às Prestações|195",
    "6161": "Habilitação e Reabilitação Profissional|6160",
    "6162": "Decadência/Prescrição|6160",
    "6164": "Declaração de Ausência|6160",
    "6165": "Atividade concomitante|6160",
    "6166": "Renúncia ao benefício|6160",
    "6167": "Demonstrativo das importâncias pagas|6160",
    "6168": "Períodos de Carência|6160",
    "6169": "Limite Mínimo de Idade|6160",
    "6170": "Perda da qualidade de segurado|6160",
    "6171": "Inclusão de Dependente|6160",
    "6172": "Regra de Transição para Aposentadoria - &quot;Pedágio&quot;|6160",
    "6173": "Pedidos Genéricos Relativos aos Benefícios em Espécie|195",
    "6174": "Data de Início de Benefício (DIB)|6173",
    "6175": "Cumulação|6173",
    "6176": "Parcelas de benefício não pagas|6173",
    "6177": "Concessão|6173",
    "6178": "Restabelecimento|6173",
    "6179": "Conversão|6173",
    "6181": "Tempo de serviço|195",
    "6182": "Averbação/Cômputo/Conversão de tempo de serviço especial|6181",
    "6183": "Averbação/Cômputo de tempo de serviço de segurado especial (regime de economia familiar)|6181",
    "6184": "Averbação/Cômputo de tempo de serviço  rural (empregado/empregador)|6181",
    "6185": "Averbação/Cômputo do tempo de serviço como aluno aprendiz|6181",
    "6186": "Averbação/Cômputo do tempo de serviço militar|6181",
    "6187": "Averbação/Cômputo de tempo de serviço de empregado doméstico|6181",
    "6188": "Averbação/Cômputo de tempo de serviço urbano|6181",
    "6189": "Certidão de Tempo de Serviço|6181",
    "6190": "Contagem Recíproca de Tempo de Serviço|6181",
    "6191": "DIREITO INTERNACIONAL|",
    "6192": "Nacionalidade|6191",
    "6193": "Aquisição|6192",
    "6194": "Perda|6192",
    "6195": "Concessão de Naturalização|6192",
    "6196": "Cancelamento de Naturalização|6192",
    "6197": "Estrangeiro|6191",
    "6198": "Admissão / Entrada / Permanência / Saída|6197",
    "6199": "Asilo|6197",
    "6200": "Livre Trânsito MERCOSUL|6197",
    "6201": "Trabalhador Migrante ou Fronteiriço do MERCOSUL|6197",
    "6202": "Proteção Internacional a Direitos Humanos|6191",
    "6203": "Cooperação Internacional|6191",
    "6206": "Auxílio Direto|6203",
    "6207": "Mercosul|6203",
    "6212": "Tratado Internacional|6191",
    "6213": "Normas do Mercosul|6191",
    "6214": "Casamento e Divórcio|6191",
    "6215": "Sucessão de Bens de Estrangeiro|6191",
    "6216": "Prestação de Alimentos|6191",
    "6217": "Funcionamento de Empresa Estrangeira|6191",
    "6218": "Pessoa Jurídica Estrangeira|6191",
    "6219": "Contratos Internacionais|6191",
    "6220": "Responsabilidade do Fornecedor|1156",
    "6226": "Inclusão Indevida em Cadastro de Inadimplentes|7779",
    "6233": "Planos de Saúde|7771",
    "6238": "Oferta|5779",
    "6239": "Fixação|5779",
    "7617": "Telefonia|7771",
    "7618": "Turismo|7771",
    "7619": "Consórcio|7771",
    "7620": "Estabelecimentos de Ensino|7771",
    "7621": "Seguro|7771",
    "7626": "Assinatura Básica Mensal|7617",
    "7627": "Pulsos Excedentes|7617",
    "7628": "Outras Relações de Trabalho|864",
    "7629": "Empreitada|7628",
    "7630": "Representante Comercial Autônomo|7628",
    "7631": "Honorários Profissionais|7628",
    "7632": "Trabalhador Eventual|7628",
    "7633": "Trabalhador Avulso|7628",
    "7639": "Direito de Greve / Lockout|864",
    "7640": "Interdito Proibitório|7639",
    "7644": "Categoria Profissional Especial|864",
    "7645": "Aeroviários|7644",
    "7646": "Rural|7644",
    "7649": "Aplicabilidade|2135",
    "7656": "União Estável ou Concubinato|5626",
    "7657": "Tutela e Curatela|5626",
    "7659": "Regime de Bens Entre os Cônjuges|5626",
    "7660": "Usufruto e Administração dos Bens de Filhos Menores|5626",
    "7661": "Bem de Família|5626",
    "7664": "Dissolução|5808",
    "7667": "Investigação de Maternidade|10577",
    "7671": "Adoção de Maior|10577",
    "7672": "União Homoafetiva|7656",
    "7673": "Sucessões|899",
    "7676": "Administração de herança|7673",
    "7677": "Reconhecimento / Dissolução|7656",
    "7681": "Obrigações|899",
    "7687": "Inventário e Partilha|7673",
    "7688": "Transmissão|7681",
    "7689": "Assunção de Dívida|7688",
    "7690": "Adimplemento e Extinção|7681",
    "7691": "Inadimplemento|7681",
    "7694": "Atos Unilaterais|7681",
    "7696": "Preferências e Privilégios Creditórios|7681",
    "7697": "Correção Monetária|7691",
    "7698": "Perdas e Danos|7691",
    "7699": "Juros de Mora - Legais / Contratuais|7691",
    "7700": "Cláusula Penal|7691",
    "7701": "Arras ou Sinal|7691",
    "7703": "Pagamento|7690",
    "7704": "Pagamento em Consignação|7690",
    "7705": "Pagamento com Sub-rogação|7690",
    "7706": "Imputação do Pagamento|7690",
    "7707": "Dação em Pagamento|7690",
    "7708": "Novação|7690",
    "7709": "Compensação|7690",
    "7710": "Confusão|7690",
    "7711": "Remissão das Dívidas|7690",
    "7712": "Promessa de Recompensa|7694",
    "7713": "Gestão de Negócios|7694",
    "7714": "Pagamento Indevido|7694",
    "7715": "Enriquecimento sem Causa|7694",
    "7717": "Espécies de Títulos de Crédito|7681",
    "7724": "REGISTROS PÚBLICOS|",
    "7725": "Registro Civil das Pessoas Naturais|7724",
    "7729": "Tabelionato de Protestos de Títulos|7724",
    "7732": "Registro de nascimento após prazo legal|7725",
    "7735": "Retificação de Nome|7725",
    "7737": "Cancelamento de Protesto|7729",
    "7748": "Acidente Aéreo|4862",
    "7752": "Bancários|7771",
    "7757": "Auxílio-Doença Acidentário|6094",
    "7760": "Fornecimento de Energia Elétrica|7771",
    "7761": "Fornecimento de Água|7771",
    "7767": "Substituição do Produto|6220",
    "7768": "Rescisão do contrato e devolução do dinheiro|6220",
    "7769": "Abatimento proporcional do preço|6220",
    "7770": "Interpretação / Revisão de Contrato|6220",
    "7771": "Contratos de Consumo|1156",
    "7772": "Cartão de Crédito|7771",
    "7773": "Financiamento de Produto|7771",
    "7774": "Serviços Profissionais|7771",
    "7775": "Serviços Hospitalares|7771",
    "7776": "Transporte Terrestre|7771",
    "7779": "Indenização por Dano Moral|6220",
    "7780": "Indenização por Dano Material|6220",
    "7781": "Protesto Indevido de Título|7779",
    "7783": "Responsabilidade Contratual|1146",
    "7784": "Responsabilidade Extracontratual|1146",
    "7785": "Prestação Pecuniária|7790",
    "7786": "Perda de Bens e Valores|7790",
    "7787": "Prestação de Serviços à Comunidade|7790",
    "7788": "Interdição Temporária de Direitos|7790",
    "7789": "Limitação de Fim de Semana|7790",
    "7790": "Pena Restritiva de Direitos|7942",
    "7791": "Pena Privativa de Liberdade|7942",
    "7792": "Pena de Multa|7942",
    "7793": "Medidas de Segurança|7942",
    "7794": "Tratamento Ambulatorial|7793",
    "7795": "Internação|7793",
    "7797": "Quanto à Embarcação|7783",
    "7798": "Quanto à Carga|7783",
    "7799": "Clandestinos|7784",
    "7834": "Retificação de Data de Nascimento|7725",
    "7835": "Retificação de Sexo|7725",
    "7895": "Registro de Imóveis|7724",
    "7896": "Cancelamento de Hipoteca|7895",
    "7897": "Inscrição na Matrícula de Registro Torrens|7895",
    "7898": "Instituição de Bem de Família|7895",
    "7899": "Bloqueio de Matrícula|7895",
    "7911": "Por Remição|7896",
    "7912": "Por Terceiro Prejudicado|7896",
    "7925": "Registro de Óbito após prazo legal|7725",
    "7926": "Registro de Nascimento de Filho de Brasileiro Nascido no Exterior|7725",
    "7928": "Liberdade Provisória|1209",
    "7929": "Prisão em flagrante|1209",
    "7935": "Perempção|4263",
    "7937": "Inclusão/exclusão de Jurado|1209",
    "7940": "Deserção|4305",
    "7941": "Denegação|4305",
    "7942": "Execução Penal|1209",
    "7945": "Falsidade|1209",
    "7947": "Fatos Jurídicos|899",
    "7949": "Direitos da Personalidade|5754",
    "7952": "Organizações Religiosas|9981",
    "7953": "Partido Político|9981",
    "8805": "Sucessão de Empregadores|1937",
    "8806": "Subempreitada|1937",
    "8807": "Sócio / Acionista|1937",
    "8808": "Indenização por Dano Material|2567",
    "8809": "Acidente de Trabalho|8808",
    "8810": "Salário por Acúmulo de Cargo / Função|2458",
    "8812": "Salário Maternidade|2458",
    "8813": "Licenças e Folgas - Conversão em Pecúnia|2581",
    "8816": "Salário Substituição|2458",
    "8817": "Gratificação por Tempo de Serviço|2055",
    "8818": "Gratificação de Função|2055",
    "8820": "Décimo Terceiro Salário Proporcional|2546",
    "8821": "Férias Proporcionais|2546",
    "8822": "Indenização Adicional|2546",
    "8823": "Saldo de Salário|2546",
    "8824": "Indenização do artigo 478 da CLT|2620",
    "8826": "DIREITO PROCESSUAL CIVIL E DO TRABALHO|",
    "8828": "Jurisdição e Competência|8826",
    "8829": "Competência|8828",
    "8838": "Imunidade de Jurisdição|8828",
    "8842": "Partes e Procuradores|8826",
    "8843": "Assistência Judiciária Gratuita|8842",
    "8859": "Intervenção de Terceiros|8842",
    "8865": "Litigância de Má-Fé|8842",
    "8866": "Litisconsórcio e Assistência|8842",
    "8867": "Substituição Processual|8842",
    "8868": "Procuração / Mandato|8842",
    "8873": "Representação em Juízo|8842",
    "8874": "Sucumbência|8842",
    "8875": "Ministério Público|8826",
    "8883": "Órgãos Judiciários e Auxiliares da Justiça|8826",
    "8884": "Do Juiz|8883",
    "8888": "Dos Auxiliares da Justiça|8883",
    "8893": "Atos Processuais|8826",
    "8919": "Nulidade|8893",
    "8928": "Prazo|8893",
    "8934": "Valor da Causa|8893",
    "8938": "Formação, Suspensão e Extinção do Processo|8826",
    "8939": "Suspensão do Processo|8938",
    "8941": "Modificação ou Alteração do Pedido|8938",
    "8942": "Extinção do Processo Sem Resolução de Mérito|8938",
    "8960": "Processo e Procedimento|8826",
    "8961": "Antecipação de Tutela / Tutela Específica|8960",
    "8986": "Erro de Procedimento|8960",
    "8990": "Provas|8960",
    "9024": "Revelia|8960",
    "9026": "Vícios Formais da Sentença|8960",
    "9045": "Recurso|8826",
    "9047": "Efeitos|9045",
    "9050": "Preparo / Deserção|9045",
    "9051": "Desconfiguração de Justa Causa|1855",
    "9060": "Tempestividade|9045",
    "9098": "Cabimento|9045",
    "9148": "Liquidação / Cumprimento / Execução|8826",
    "9149": "Valor da Execução / Cálculo / Atualização|9148",
    "9160": "Levantamento de Valor|9148",
    "9163": "Constrição / Penhora / Avaliação / Indisponibilidade de Bens|9148",
    "9166": "Multa de 10%|9148",
    "9178": "Nulidade / Inexigibilidade do Título|9148",
    "9180": "Expropriação de Bens|9148",
    "9189": "Remição|9148",
    "9192": "Medida Cautelar|8826",
    "9196": "Liminar|9192",
    "9258": "Honorários Periciais|8842",
    "9414": "Extinção da Execução|9148",
    "9418": "Concurso de Credores|9148",
    "9419": "Execução Previdenciária|9148",
    "9450": "Fraude à Execução|9148",
    "9453": "Imunidade de Execução|9148",
    "9484": "Sucessão|9148",
    "9493": "Capacidade Processual|8842",
    "9494": "Substituição da Parte|8842",
    "9517": "Causas Supervenientes à Sentença|9148",
    "9518": "Efeito Suspensivo / Impugnação / Embargos à Execução|9148",
    "9519": "Benefício de Ordem|9148",
    "9520": "Ato Atentatório à Dignidade da Justiça|9148",
    "9524": "Indenização do Prejuízo|9192",
    "9532": "Caução / Contracautela|9192",
    "9533": "Coligação|5724",
    "9534": "Transformação|5724",
    "9535": "Incorporação|5724",
    "9536": "Fusão|5724",
    "9537": "Cisão|5724",
    "9538": "Liquidação|5724",
    "9539": "Alteração de capital|5724",
    "9541": "Capacidade|5754",
    "9542": "Curadoria dos bens do ausente|5754",
    "9543": "Sucessão Provisória|5754",
    "9545": "Inclusão de associado|4897",
    "9546": "Exclusão de associado|4897",
    "9547": "Fiscalização|4905",
    "9549": "Exclusão de filiado|7953",
    "9550": "Fusão|7953",
    "9551": "Eleições - Diretórios|7953",
    "9555": "Ineficácia de atos  em relação à massa|4993",
    "9556": "Convolação de recuperação judicial em falência|4993",
    "9558": "Administração judicial|4993",
    "9559": "Classificação de créditos|4993",
    "9565": "Laudo Arbitral Internacional|6191",
    "9575": "Sustação de Protesto|4949",
    "9580": "Espécies de Contratos|7681",
    "9581": "Agêncie e Distribuição|9580",
    "9582": "Alienação Fiduciária|9580",
    "9583": "Arrendamento Rural|9580",
    "9584": "Arrendamento Mercantil|9580",
    "9585": "Cartão de Crédito|9580",
    "9586": "Comissão|9580",
    "9587": "Compra e Venda|9580",
    "9588": "Corretagem|9580",
    "9589": "Depósito|9580",
    "9590": "Doação|9580",
    "9591": "Empreitada|9580",
    "9592": "Fiança|9580",
    "9593": "Locação de Imóvel|9580",
    "9594": "Mandato|9580",
    "9595": "Troca ou Permuta|9580",
    "9596": "Prestação de Serviços|9580",
    "9597": "Seguro|9580",
    "9598": "Transação|9580",
    "9599": "Transporte de Coisas|9580",
    "9600": "Transporte de Pessoas|9580",
    "9601": "Estimatório|9580",
    "9602": "Comodato|9580",
    "9603": "Mútuo|9580",
    "9604": "Constituição de Renda|9580",
    "9605": "Jogo e Aposta|9580",
    "9606": "Compromisso|9580",
    "9607": "Contratos Bancários|9580",
    "9608": "Franquia|9580",
    "9609": "Locação de Móvel|9580",
    "9610": "Despejo para Uso Próprio|9593",
    "9611": "Despejo para Uso de Ascendentes e Descendentes|9593",
    "9612": "Despejo por Denúncia Vazia|9593",
    "9614": "Benfeitorias|9593",
    "9615": "Direito de Preferência|9593",
    "9616": "Empresas|899",
    "9617": "Espécies de Sociedades|9616",
    "9618": "Conta de Participação|9617",
    "9619": "Simples|9617",
    "9620": "Nome Coletivo|9617",
    "9621": "Comandita Simples|9617",
    "9622": "Limitada|9617",
    "9623": "Anônima|9617",
    "9624": "Comandita por Ações|9617",
    "9625": "Cooperativa|9617",
    "9626": "Coligadas|9617",
    "9627": "Dependente de Autorização|9617",
    "9629": "Estrangeira|9617",
    "9633": "DIREITO DA CRIANÇA E DO ADOLESCENTE|",
    "9634": "Ato Infracional|9633",
    "9635": "Contra a vida|9634",
    "9636": "Homicídio Simples|9635",
    "9637": "Homicídio Privilegiado|9635",
    "9638": "Homicídio Qualificado|9635",
    "9639": "Induzimento, Instigação ou Auxílio a Suicídio|9635",
    "9640": "Infanticídio|9635",
    "9641": "Lesões Corporais|9634",
    "9642": "Leve|9641",
    "9643": "Seguida de Morte|9641",
    "9644": "Grave|9641",
    "9645": "Gravíssima|9641",
    "9646": "Privilegiada|9641",
    "9647": "Decorrente de Violência Doméstica|9641",
    "9648": "Periclitação da Vida e da Saúde e Rixa|9634",
    "9649": "Perigo de contágio de moléstia grave|9648",
    "9650": "Perigo para a vida ou saúde de outrem (art. 132)|9648",
    "9651": "Abandono de incapaz (art. 133)|9648",
    "9652": "Omissão de socorro (art. 135)|9648",
    "9653": "Rixa (art. 137)|9648",
    "9654": "Perigo de Contágio Venéreo|9648",
    "9655": "Contra a Honra|9634",
    "9656": "Calúnia|9655",
    "9657": "Difamação|9655",
    "9658": "Injúria|9655",
    "9659": "Contra a liberdade pessoal|9634",
    "9660": "Constrangimento ilegal (art. 146)|9659",
    "9661": "Ameaça (art. 147)|9659",
    "9662": "Seqüestro e cárcere privado (art. 148)|9659",
    "9663": "Redução a condição análoga à de escravo (art. 149)|9659",
    "9664": "Contra a inviolabilidade de domicílio|9634",
    "9665": "Violação de domicílio (art. 150)|9664",
    "9666": "Contra a inviolabilidade de correspondência|9634",
    "9667": "Violação de correspondência (art. 151, caput)|9666",
    "9668": "Sonegação ou destruição de correspondência (art. 151, § 1º, I)|9666",
    "9669": "Violação de comunicação telegráfica, radioelétrica ou telefônica (art. 151, § 1º, II, III e IV)|9666",
    "9670": "Violação de Correspondência comercial (art. 152)|9666",
    "9671": "Contra a inviolabilidade de segredo|9634",
    "9672": "Divulgação de segredo (art. 153)|9671",
    "9673": "Violação do segredo profissional (art. 154)|9671",
    "9674": "Contra o Patrimônio|9634",
    "9675": "Furto (art. 155)|9674",
    "9676": "Furto Qualificado (Art. 155, § 4o.)|9674",
    "9677": "Furto de coisa comum (art. 156)|9674",
    "9678": "Roubo (art. 157)|9674",
    "9679": "Extorsão (art. 158)|9674",
    "9680": "Extorsão mediante seqüestro (art. 159)|9674",
    "9681": "Extorsão indireta (art. 160)|9674",
    "9682": "Alteração de limites (art. 161, caput)|9674",
    "9683": "Usurpação de águas (art. 161, § 1º, I)|9674",
    "9684": "Esbulho possessório (art. 161, § 1º, II)|9674",
    "9685": "Dano (art. 163)|9674",
    "9686": "Introdução ou abandono de animais em propriedade alheia (art. 164)|9674",
    "9687": "Dano em coisa de valor artístico, arqueológico ou histórico (art. 165)|9674",
    "9688": "Alteração de local especialmente protegido (art. 166)|9674",
    "9689": "Apropriação indébita Previdenciária (art. 168-A e Lei 8.212/91)|9674",
    "9690": "Estelionato|9674",
    "9691": "Estelionato Majorado (art. 171, § 3º)|9674",
    "9692": "Outras fraudes|9674",
    "9693": "Fraude à execução (art. 179)|9674",
    "9694": "Receptação|9674",
    "9695": "Apropriação indébita (art. 168, caput)|9674",
    "9696": "Apropriação de Coisa Havida por Erro, Caso Fortuito ou Força da Natureza (art.169)|9674",
    "9697": "Furto Privilegiado|9674",
    "9698": "Furto de Veículo Automotor a ser Transportado para outro Estado ou Exterior|9674",
    "9699": "Roubo Majorado|9674",
    "9700": "Latrocínio|9674",
    "9701": "Extorsão mediante Seqüestro Seguida de Morte|9674",
    "9702": "Extorsão mediante Sequestro Seguida de Lesão Corporal Grave|9674",
    "9703": "Supressão ou Alteração de Marca em Animais|9674",
    "9704": "Dano Qualificado|9674",
    "9705": "Apropriação de Tesouro|9674",
    "9706": "Apropriação de Coisa Achada|9674",
    "9707": "Disposição de coisa alheia como própria|9674",
    "9708": "Alienação ou Oneração Fraudulenta de Coisa Própria|9674",
    "9709": "Defraudação de Penhor|9674",
    "9710": "Fraude na Entrega de Coisa|9674",
    "9711": "Fraude para Recebimento de Indenização ou Valor de Seguro|9674",
    "9712": "Fraude no Pagamento por Meio de Cheque|9674",
    "9713": "Duplicata Simulada|9674",
    "9714": "Abuso de Incapazes|9674",
    "9715": "Induzimento à Especulação|9674",
    "9716": "Fraude no Comércio|9674",
    "9717": "Fraudes e Abusos na Fundação ou Administração de Sociedade por Ações|9674",
    "9718": "Emissão Irregular de Conhecimento de Depósito ou &quot;Warrant&quot;|9674",
    "9719": "Receptação Qualificada|9674",
    "9720": "Contra a Propriedade Intelectual|9634",
    "9721": "Violação de direito autoral|9720",
    "9722": "Contra a Organização do Trabalho|9634",
    "9723": "Atentado contra a liberdade de trabalho (art. 197)|9722",
    "9724": "Atentado contra a liberdade de contrato de trabalho e boicotagem violenta (art. 198)|9722",
    "9725": "Atentado contra a liberdade de associação (art. 199)|9722",
    "9726": "Paralisação de trabalho, seguida de violência ou perturbação da ordem (art. 200)|9722",
    "9727": "Paralisação de trabalho de interesse coletivo (art. 201)|9722",
    "9728": "Invasão de estabelecimento industrial, comercial ou agrícola. Sabotagem (art. 202)|9722",
    "9729": "Frustração de direitos assegurados por lei trabalhista (art. 203)|9722",
    "9730": "Exercício de atividade com infração de decisão administrativa (art. 205)|9722",
    "9731": "Aliciamento para fins de emigração (art. 206)|9722",
    "9732": "Aliciamento de trabalhadores de um local para outro do território nacional (art. 207)|9722",
    "9733": "Frustração de lei sobre a nacionalização do trabalho (art. 204)|9722",
    "9734": "Contra o sentimento religioso e contra o respeito aos mortos|9634",
    "9735": "Destruição / Subração / Ocultação de Cadáver|9734",
    "9736": "Impedimento ou Perturbação de Cerimônia Funerária (Art. 209)|9734",
    "9737": "Vilipêndio a Cadáver|9734",
    "9738": "Violação a Sepultura (Art. 210)|9734",
    "9739": "Ultraje / Impedimento ou Perturbação de Culto Religioso|9734",
    "9740": "Contra a dignidade sexual|9634",
    "9741": "Tráfico Internacional de Pessoa para Fim de Exploração Sexual|9740",
    "9742": "Estupro|9740",
    "9743": "Atentado Violento ao Pudor|9740",
    "9744": "Atentado ao Pudor Mediante Fraude|9740",
    "9745": "Corrupção de Menores|9740",
    "9746": "Ultraje Público ao Pudor (Ato/Escrito Obsceno)|9740",
    "9747": "Mediação para Servir a Lascívia de Outrem|9740",
    "9748": "Posse Sexual Mediante Fraude|9740",
    "9749": "Assédio Sexual|9740",
    "9750": "Favorecimento da Prostituição|9740",
    "9751": "Casa de Prostituição|9740",
    "9752": "Rufianismo|9740",
    "9753": "Tráfico Internacional de Pessoas|9740",
    "9754": "Tráfico Interno de Pessoas|9740",
    "9755": "Contra a Família|9634",
    "9756": "Crimes Contra a Assistência Familiar|9755",
    "9757": "Abandono Material|9756",
    "9758": "Abandono Intelectual|9756",
    "9759": "Entrega de Filho Menor a Pessoa Inidônea|9756",
    "9760": "Crimes contra o Casamento|9755",
    "9761": "Bigamia|9760",
    "9762": "Conhecimento Prévio de Impedimento|9760",
    "9763": "Induzimento a erro essencial e ocultação de impedimento|9760",
    "9764": "Simulação de Casamento|9760",
    "9765": "Simulação de Autoridade para Celebração de Casamento|9760",
    "9766": "Crimes Contra o Estado de Filiação|9755",
    "9767": "Parto Suposto (Art. 242)|9766",
    "9768": "Registro de Nascimento Inexistente (Art. 241)|9766",
    "9769": "Sonegação do Estado de Filiação (Art. 243)|9766",
    "9770": "Crime contra o Pátrio-Poder e Tutela|9755",
    "9771": "Induzimento a Fuga, Entrega Arbitrária ou Sonegação de Incapazes (Art. 248)|9770",
    "9772": "Subtração de Incapazes (Art. 249)|9770",
    "9773": "Contra a Incolumidade Pública|9634",
    "9774": "Incêndio|9773",
    "9775": "Explosão|9773",
    "9776": "Uso de gás tóxico ou asfixiante|9773",
    "9777": "Inundação / Perigo de Inundação|9773",
    "9778": "Desabamento ou desmoronamento (art. 256)|9773",
    "9779": "Subtração, ocultação ou inutilização de material de salvamento (art. 257)|9773",
    "9780": "Difusão de doença ou praga (art. 259)|9773",
    "9781": "Desastre ferroviário / Perigo de Desastre Ferroviário|9773",
    "9782": "Atentado contra a segurança de transporte público|9773",
    "9783": "Arremesso de projétil|9773",
    "9784": "Atentado contra a segurança de serviços de utilidade pública (art. 265)|9773",
    "9785": "Interrupção /perturbação de serviços telegráficos/telefônicos (art. 266)|9773",
    "9786": "Epidemia (art. 267)|9773",
    "9787": "Emprego de Processo Proibido/Substância não permitida|9773",
    "9788": "Invólucro ou recipiente com falsa indicação (art. 275)|9773",
    "9789": "Falsificação / Corrupção / Adulteração / Alteração de produto destinado a fins terapêuticos ou medicinais|9773",
    "9790": "Exercício Ilegal da Medicina, Arte Dentária ou Farmacêutica (art. 282)|9773",
    "9791": "Charlatanismo (Art. 283)|9773",
    "9792": "Corrupção ou Poluição de Água Potável (Art. 271)|9773",
    "9793": "Curandeirismo (Art. 284)|9773",
    "9794": "Envenenamento de Água Potável / Substância Alimentícia ou Medicinal (Art. 270)|9773",
    "9795": "Falsificação / Corrupção / Adulteração / Alteração de Substância ou Produtos Alimentícios|9773",
    "9796": "Infração de Medida Sanitária Preventiva (Art. 268)|9773",
    "9797": "Omissão de Notificação de Doença (Art. 269)|9773",
    "9798": "Outras Substâncias Nocivas a Saúde Pública (Art. 278)|9773",
    "9799": "Substância Destinada à Falsificação (Art. 277)|9773",
    "9800": "Fabrico / Fornecimento / Aquisição / Posse ou Transporte de Explosivos ou Gás Tóxico ou Asfixiante|9773",
    "9801": "Venda de Produtos ou Substância nas Condições dos Artigos 274 e 275|9773",
    "9802": "Medicamento em Desacordo com Receita Médica|9773",
    "9803": "Contra a Paz Pública|9634",
    "9804": "Quadrilha ou Bando (art. 288)|9803",
    "9805": "Incitação ao Crime|9803",
    "9806": "Apologia de Crime ou Criminoso|9803",
    "9807": "Contra a Fé Pública|9634",
    "9808": "Moeda Falsa / Assimilados|9807",
    "9809": "Petrechos para falsificação de moeda (art. 291)|9807",
    "9810": "Emissão de título ao portador sem permissão legal (art. 292)|9807",
    "9811": "Falsificação de papéis públicos (art. 293)|9807",
    "9812": "Falsificação do selo ou sinal público (art. 296)|9807",
    "9813": "Falsificação de documento público|9807",
    "9814": "Falsificação de documento particular (art. 298)|9807",
    "9815": "Falsidade ideológica (art. 299)|9807",
    "9816": "Falso reconhecimento de firma ou letra (art. 300)|9807",
    "9817": "Certidão ou atestado ideologicamente falso (art. 301, caput)|9807",
    "9818": "Falsidade material de atestado ou certidão (art. 301, § 1º)|9807",
    "9819": "Falsidade de atestado médico (art. 302)|9807",
    "9820": "Reprodução ou adulteração de selo ou peça filatélica (art. 303)|9807",
    "9821": "Uso de documento falso (art. 304)|9807",
    "9822": "Supressão de documento (art. 305)|9807",
    "9823": "Falsificação do sinal empregado no contraste de metal precioso ou na fiscalização alfandegária, ou para outros fins (art. 306)|9807",
    "9824": "Falsa identidade|9807",
    "9825": "Fraude de lei sobre estrangeiros|9807",
    "9826": "Petrechos de Falsificação de Papéis Públicos|9807",
    "9827": "Adulteração de Sinal Identificador de Veículo Automotor|9807",
    "9828": "Praticados por Funcionários Públicos Contra a Administração em Geral|9634",
    "9829": "Peculato (art. 312, caput e § 1º)|9828",
    "9830": "Peculato mediante erro de outrem (art. 313)|9828",
    "9831": "Extravio, sonegação ou inutilização de livro ou documento (art. 314)|9828",
    "9832": "Emprego irregular de verbas ou rendas públicas (art. 315)|9828",
    "9833": "Concussão (art. 316, caput)|9828",
    "9834": "Excesso de exação (art. 316, §§ 1º e 2º)|9828",
    "9835": "Corrupção passiva (art. 317)|9828",
    "9836": "Facilitação de contrabando ou descaminho (art. 318)|9828",
    "9837": "Prevaricação|9828",
    "9838": "Condescendência criminosa (art. 320)|9828",
    "9839": "Advocacia administrativa (art. 321)|9828",
    "9840": "Violência arbitrária (art. 322)|9828",
    "9841": "Abandono de função (art. 323)|9828",
    "9842": "Exercício funcional ilegalmente antecipado ou prolongado (art. 324)|9828",
    "9843": "Violação do sigilo funcional (art. 325)|9828",
    "9844": "Violação do sigilo de proposta de concorrência (art. 326)|9828",
    "9845": "Inserção de dados falsos em sistema de informações (art. 313-A)|9828",
    "9846": "Modificação ou alteração não autorizada de sistema de informações (art. 313-B)|9828",
    "9847": "Previstos na Legislação Extravagante|9634",
    "9848": "Contra a Propriedade Industrial|9847",
    "9849": "Contra Patente de Invenção|9848",
    "9850": "Contra os Desenhos Industriais|9848",
    "9851": "Contra as Marcas|9848",
    "9852": "Cometidos por Meio de Marca, Título de Estabelecimento e Sinal de Propaganda|9848",
    "9853": "Contra Indicações Geográficas e Demais Indicações|9848",
    "9854": "De Concorrência Desleal|9848",
    "9855": "de Responsabilidade|9847",
    "9856": "Contra a Economia Popular|9847",
    "9857": "de Abuso de Autoridade|9847",
    "9858": "De Tráfico Ilícito e Uso Indevido de Drogas|9847",
    "9859": "Tráfico de Drogas e Condutas Afins|9858",
    "9860": "Posse de Drogas para Consumo Pessoal|9858",
    "9861": "Fabricação de Objeto Destinado a Produção de Drogas e Condutas Afins|9858",
    "9862": "Indução, Instigação ou Auxílio ao Uso de Drogas|9858",
    "9863": "Oferecimento de Drogas para Consumo Conjunto|9858",
    "9864": "Associação para a Produção e Tráfico e Condutas Afins|9858",
    "9865": "Financiamento ou Custeio de Produção ou Tráfico de Drogas|9858",
    "9866": "Colaboração com Grupo, Organização ou Associação Destinados à Produção ou Tráfico de Drogas|9858",
    "9867": "Prescrição Culposa de Drogas|9858",
    "9868": "Condução de Embarcação ou Aeronave sob Efeito de Drogas|9858",
    "9869": "Responsabilidade Criminal por Danos Nucleares|9847",
    "9870": "Contra o Serviço Postal e o Serviço de Telegrama|9847",
    "9871": "Contra a Segurança Nacional, a Ordem Política e Social|9847",
    "9872": "Contra o Sistema Financeiro Nacional|9847",
    "9873": "Resultante de Preconceito de Raça ou de Cor|9847",
    "9874": "Contra a Ordem Tributária|9847",
    "9875": "Contra a Ordem Econômica|9847",
    "9876": "Contra as Relações de Consumo|9847",
    "9877": "Representação caluniosa|9847",
    "9878": "Contra o Meio Ambiente|9847",
    "9879": "Contra a Fauna|9878",
    "9880": "Contra a Flora|9878",
    "9881": "Da Poluição|9878",
    "9882": "Agrotóxicos (Lei 7.802/89)|9878",
    "9883": "Atividades Nucleares (Lei 6.453/77)|9878",
    "9884": "Caça (Lei nº 5.197/67)|9878",
    "9886": "Genética (Lei nº 8.974/95)|9878",
    "9887": "Pesca (Lei nº 5.197/67, Lei nº 7.643/87, Lei 7.679/88, DL 221/67)|9878",
    "9888": "De &quot;Lavagem&quot; ou Ocultação de Bens, Direitos ou Valores|9847",
    "9889": "Contra as Telecomunicações|9847",
    "9890": "Previstos na Lei de Estrangeiros|9847",
    "9891": "De Tortura|9847",
    "9892": "De Trânsito|9847",
    "9893": "Do Sistema Nacional de Armas|9847",
    "9894": "De Imprensa|9847",
    "9895": "Estatuto da criança e do adolescente|9847",
    "9896": "Contra o mercado de capitais|9847",
    "9897": "De genocídio|9847",
    "9898": "Sigilo Telefônico|9847",
    "9899": "Da Lei de licitações|9847",
    "9900": "Da Lei de remoção de órgãos e tecidos|9847",
    "9901": "Praticados contra os índios e a cultura indígena|9847",
    "9903": "Recusa, retardamento ou omissão de dados técnicos indispensáveis à propositura de Ação Civil Pública|9847",
    "9904": "Agrários|9847",
    "9905": "Relacionados à Comissão Parlamentar de Inquérito|9847",
    "9906": "Eleitorais|9847",
    "9907": "De Quebra de Sigilo Financeiro|9847",
    "9908": "Do Código Brasileiro de Telecomunicações|9847",
    "9909": "Estatuto do Idoso|9847",
    "9910": "Parcelamento do solo urbano|9847",
    "9911": "Falimentares|9847",
    "9912": "Militares|9847",
    "9913": "Contra a Administração da Justiça - Lei da Ação de Alimentos|9847",
    "9914": "Contravenções Penais|9634",
    "9915": "Análogo a Crime Tentado|9634",
    "9916": "Análogo a Crime Culposo|9634",
    "9917": "Praticados por Particular Contra a Administração em Geral|9634",
    "9918": "Usurpação de função pública (art. 328)|9917",
    "9919": "Resistência (art. 329)|9917",
    "9920": "Tráfico de influência (art. 332)|9917",
    "9921": "Corrupção ativa (art. 333)|9917",
    "9922": "Impedimento, perturbação ou fraude de concorrência (art. 335)|9917",
    "9923": "Inutilização de edital ou de sinal (art. 336)|9917",
    "9924": "Subtração ou inutilização de livro ou documento (art. 337, caput)|9917",
    "9925": "Desobediência (art. 330)|9917",
    "9926": "Desacato (art. 331)|9917",
    "9927": "Contrabando ou descaminho (art. 334)|9917",
    "9928": "Sonegação de contribuição previdenciária (art. 337-A e Lei 8.212/91)|9917",
    "9929": "Praticados por Particular Contra a Administração Pública Estrangeira|9634",
    "9930": "Corrupção Ativa em Transação Comercial Internacional|9929",
    "9931": "Tráfico de Influência em Transação Comercial Internacional|9929",
    "9932": "Contra a Administração da Justiça|9634",
    "9933": "Reingresso de estrangeiro expulso|9932",
    "9934": "Denunciação caluniosa|9932",
    "9935": "Comunicação falsa de crime ou de contravenção|9932",
    "9936": "Auto-acusação falsa|9932",
    "9937": "Falso testemunho ou falsa perícia|9932",
    "9938": "Coação no curso do processo|9932",
    "9939": "Exercício arbitrário das próprias razões|9932",
    "9940": "Fraude processual|9932",
    "9941": "Favorecimento pessoal|9932",
    "9942": "Favorecimento real|9932",
    "9943": "Exercício arbitrário ou abuso de poder|9932",
    "9944": "Fuga de pessoa presa ou submetida a medida de segurança|9932",
    "9945": "Evasão mediante violência contra a pessoa|9932",
    "9946": "Arrebatamento de preso|9932",
    "9947": "Motim de presos|9932",
    "9948": "Patrocínio infiel|9932",
    "9949": "Patrocínio simultâneo ou tergiversação|9932",
    "9950": "Sonegação de papel ou objeto de valor probatório|9932",
    "9951": "Exploração de prestígio|9932",
    "9952": "Violência ou fraude em arrematação judicial|9932",
    "9953": "Desobediência a decisão judicial sobre perda ou suspensão de direitos|9932",
    "9955": "Contra as Finanças Públicas|9634",
    "9956": "Contratação de Operação de Crédito|9955",
    "9957": "Inscrição de Despesas Não Empenhadas|9955",
    "9958": "Assunção de Obrigação no Último Ano do Mandato ou Legislatura|9955",
    "9959": "Ordenação de Despesa Não Autorizada|9955",
    "9960": "Prestação de Garantia Graciosa|9955",
    "9961": "Não Cancelamento de Restos a Pagar|9955",
    "9962": "Aumento de Despesa com Pessoal no Último Ano do Mandato ou Legislatura|9955",
    "9963": "Oferta Pública ou Colocação de Títulos no Mercado|9955",
    "9964": "Seção Cível|9633",
    "9965": "Abandono Material|9964",
    "9966": "Abandono Intelectual|9964",
    "9967": "Maus Tratos|9964",
    "9968": "Abuso Sexual|9964",
    "9969": "Exploração do Trabalho Infantil|9964",
    "9970": "Pobreza|9964",
    "9971": "Uso ou Tráfico de Drogas|9964",
    "9972": "Adoção Internacional|9964",
    "9973": "Adoção Nacional|9964",
    "9974": "Adoção de Criança|9964",
    "9975": "Adoção de Adolescente|9964",
    "9977": "Entrada e Permanência de Menores|9964",
    "9978": "Viagem ao Exterior|9964",
    "9979": "Trabalho do adolescente|9964",
    "9981": "Pessoas Jurídicas|899",
    "9984": "Em comum / De fato|9617",
    "9985": "DIREITO ADMINISTRATIVO E OUTRAS MATÉRIAS DE DIREITO PÚBLICO|",
    "9986": "Garantias Constitucionais|9985",
    "9988": "Anistia Política|9986",
    "9989": "Direitos Indígenas|9986",
    "9990": "Intervenção em Estado / Município|9986",
    "9991": "Responsabilidade da Administração|9985",
    "9992": "Indenização por Dano Moral|9991",
    "9994": "Indenização por Dano Ambiental|9991",
    "9995": "Erro Médico|9992",
    "9996": "Acidente de Trânsito|9992",
    "9997": "Atos Administrativos|9985",
    "9998": "Licenças|9997",
    "9999": "Declaração de Bagagem|9998",
    "10000": "Declaração de Trânsito Aduaneiro|9998",
    "10001": "Comercialização sem Restrições de Produtos Industrializados|9998",
    "10002": "Comercialização sem Restrições de Gêneros Alimentícios|9998",
    "10003": "Comercialização e/ou Utilização sem Restrições de Medicamentos|9998",
    "10004": "Funcionamento de Comércio de Derivados de Petróleo|9998",
    "10005": "Registro de Empresa|9998",
    "10006": "Funcionamento de Estabelecimentos Empresariais|9998",
    "10007": "Registro / Porte de arma de fogo|9998",
    "10008": "Registro de Aeronave|9998",
    "10009": "Inquérito / Processo / Recurso Administrativo|9997",
    "10010": "Ato Lesivo ao Patrimônio Artístico, Estético, Histórico ou Turístico|9997",
    "10011": "Improbidade Administrativa|9997",
    "10012": "Dano ao Erário|10011",
    "10013": "Enriquecimento ilícito|10011",
    "10014": "Violação aos Princípios Administrativos|10011",
    "10015": "Fiscalização|9997",
    "10016": "Inspeção Fitossanitária|10015",
    "10017": "Exportação/Vedações|10015",
    "10018": "Inspeção Sanitária de Origem Animal|10015",
    "10019": "Segurança e Medicina do Trabalho|10015",
    "10020": "Registro de Trabalho Portuário/Órgão de Gestão de Mão-de-Obra|9997",
    "10021": "Registro de Marcas, Patentes ou Invenções|9997",
    "10022": "Infração Administrativa|9997",
    "10023": "Multas e demais Sanções|10022",
    "10024": "Interdição|10022",
    "10025": "Apreensão|10022",
    "10026": "Registro de Direito Autoral|9997",
    "10027": "Registro de Programa de Computador|9997",
    "10028": "Serviços|9985",
    "10029": "Ensino Superior|10028",
    "10030": "Matrícula|10029",
    "10031": "Financiamento Público da Educação e/ou Pesquisa|10029",
    "10032": "Colação de Grau|10029",
    "10033": "Exigência de Estágio Profissionalizante|10029",
    "10034": "Transferência de Estudante|10029",
    "10035": "Freqüência às Aulas|10029",
    "10036": "Penalidades Disciplinares|10029",
    "10037": "Colisão de Horário|10029",
    "10038": "Vestibular|10029",
    "10039": "Mensalidades|10029",
    "10040": "Currículo Escolar|10029",
    "10041": "Ingresso no Curso Superior|10029",
    "10042": "Jubilamento de Aluno|10029",
    "10043": "Criação e/ou Autorização para funcionamento de Curso Superior|10029",
    "10044": "Omissão de Entrega de Notas|10029",
    "10045": "Diplomas/Certificado de Conclusão do Curso|10029",
    "10046": "Financiamento Privado da Educação e/ou Pesquisa|10029",
    "10047": "Residência Médica|10029",
    "10048": "Provão - Avaliação da Educação Superior|10029",
    "10049": "Sistemas de cotas - Lei 10.558/2002|10029",
    "10050": "Pós-Graduação|10029",
    "10051": "Ensino Fundamental e Médio|10028",
    "10052": "Mensalidade|10051",
    "10053": "Matrícula|10051",
    "10054": "Material Didático|10051",
    "10055": "Exame Nacional de Ensino Médio / ENEM|10051",
    "10056": "Penalidades Disciplinares|10051",
    "10057": "Transferência|10051",
    "10058": "Ensino Especial|10051",
    "10059": "Exame Supletivo|10051",
    "10060": "Merenda|10051",
    "10061": "Transporte|10051",
    "10062": "Educação Pré-escolar|10051",
    "10063": "Educação Profissionalizante|10051",
    "10064": "Saúde|10028",
    "10065": "Repasse de Verbas do SUS|10064",
    "10066": "Reajuste da Tabela do SUS|10064",
    "10067": "Convênio Médico com o SUS|10064",
    "10069": "Tratamento Médico-Hospitalar e/ou Fornecimento de Medicamentos|10064",
    "10070": "Ressarcimento ao SUS|10064",
    "10071": "Genética / Células Tronco|10064",
    "10072": "Sistema Nacional do Desporto|10028",
    "10073": "Concessão / Permissão / Autorização|10028",
    "10074": "Fornecimento de Gás|10073",
    "10075": "Energia Elétrica|10073",
    "10076": "Transporte Terrestre|10073",
    "10077": "Transporte Aéreo - Aeroporto|10073",
    "10078": "Radiodifusão|10073",
    "10079": "Loterias/Sorteio|9998",
    "10080": "Telefonia|10073",
    "10081": "Transporte Aquaviário|10073",
    "10082": "Serviço Postal|10073",
    "10083": "Tabelionatos, Registros, Cartórios|10073",
    "10084": "Jogos de Bingo e/ou Caça-níqueis|9998",
    "10085": "Água e/ou Esgoto|10073",
    "10086": "Recolhimento e Tratamento de Lixo|10073",
    "10087": "Defensoria Pública|10028",
    "10088": "Domínio Público|9985",
    "10089": "Bens Públicos|10088",
    "10090": "Locação / Permissão / Concessão / Autorização / Cessão de Uso|10089",
    "10091": "Terreno de Marinha|10089",
    "10092": "Taxa de Ocupação|10089",
    "10093": "Foro / Laudêmio|10089",
    "10094": "Terras Devolutas|10089",
    "10095": "Águas Públicas|10089",
    "10096": "Bloqueio de Valores de Contas Públicas|10089",
    "10097": "Imóvel Funcional|10088",
    "10098": "Alienação|10097",
    "10099": "Ocupação|10097",
    "10100": "Reintegração de Posse|10097",
    "10101": "Taxa de Ocupação|10097",
    "10102": "Terras Indígenas|10088",
    "10103": "Terreno Aldeado|10102",
    "10104": "Restituição de área - FUNAI|10102",
    "10105": "Demarcação|10102",
    "10106": "Recursos Minerais|10088",
    "10107": "Espaço Aéreo|10088",
    "10108": "Patrimônio Histórico / Tombamento|10088",
    "10109": "Ordenação da Cidade / Plano Diretor|10088",
    "10110": "Meio Ambiente|9985",
    "10111": "Revogação/Concessão de Licença Ambiental|10110",
    "10112": "Revogação/Anulação de multa ambiental|10110",
    "10113": "Flora|10110",
    "10114": "Fauna|10110",
    "10115": "Transgênicos|10110",
    "10116": "Agrotóxicos|10110",
    "10118": "Unidade de Conservação da Natureza|10110",
    "10119": "Gestão de Florestas Públicas|10110",
    "10120": "Intervenção do Estado na Propriedade|9985",
    "10121": "Desapropriação|10120",
    "10122": "Desapropriação por Utilidade Pública / DL 3.365/1941|10120",
    "10123": "Desapropriação por Interesse Social Comum / L 4.132/1962|10120",
    "10124": "Desapropriação por Interesse Social para Reforma Agrária|10120",
    "10125": "Desapropriação Indireta|10120",
    "10126": "Retrocessão|10120",
    "10127": "Vistoria|10120",
    "10128": "Servidão Administrativa|10120",
    "10129": "Ocupação Temporária|10120",
    "10130": "Limitação Administrativa|10120",
    "10131": "Privatização|10088",
    "10132": "Restituição de área|10120",
    "10133": "Direito de Preempção|10120",
    "10134": "Desapropriação de Imóvel Urbano|10120",
    "10135": "Requisição de Bem Particular|10120",
    "10136": "Intervenção no Domínio Econômico|9985",
    "10137": "Política de Preço Mínimo|10136",
    "10138": "Controle de Preços|10136",
    "10139": "Controle de Abastecimento|10136",
    "10140": "Importações|10136",
    "10141": "Desembaraço Aduaneiro|10140",
    "10142": "Regime ou Certificado de Origem MERCOSUL|10140",
    "10143": "Veículo de Turista Comunitário MERCOSUL|10140",
    "10144": "Incentivo|10136",
    "10145": "PROAGRO|10144",
    "10146": "Proteção à Livre Concorrência|10136",
    "10147": "Venda Casada|10146",
    "10148": "Preços Predatórios|10146",
    "10149": "Acordo de Exclusividade|10146",
    "10150": "Cartel|10146",
    "10151": "Atos de Concentração|10146",
    "10153": "Moeda Estrangeira|10136",
    "10154": "Expurgos Inflacionários / Planos Econômicos|10136",
    "10155": "Cruzados Novos / Bloqueio|10154",
    "10156": "Poupança|10154",
    "10157": "Organização Político-administrativa / Administração Pública|9985",
    "10158": "FGTS/Fundo de Garantia por Tempo de Serviço|10157",
    "10159": "Atualização de Conta|10158",
    "10160": "Liberação de Conta|10158",
    "10161": "Juros Progressivos|10158",
    "10162": "Localização de Contas|10158",
    "10163": "PIS/PASEP|10157",
    "10164": "Atualização de Conta|10163",
    "10165": "Liberação de Conta|10163",
    "10166": "Conselhos Regionais de Fiscalização Profissional e Afins|10157",
    "10167": "Registro Profissional|10166",
    "10168": "Multas e demais Sanções|10166",
    "10169": "Questões Funcionais|10166",
    "10170": "Exame da Ordem OAB|10166",
    "10171": "Eleições|10166",
    "10172": "Anuidades OAB|10166",
    "10173": "Exercício Profissional|10166",
    "10174": "Instituições Financeiras|10157",
    "10175": "Liberação de Depósitos de Instituição Liquidanda|10174",
    "10176": "Normatizações|10174",
    "10177": "Remuneração de Ativos Retidos|10174",
    "10178": "Cooperativas de Crédito|10174",
    "10179": "Liquidação extrajudicial|10174",
    "10180": "Fundo de Participação dos Municípios|10157",
    "10181": "Seguro desemprego|10157",
    "10182": "Dívida Pública Mobiliária|9985",
    "10183": "Apólices da Dívida Pública|10182",
    "10184": "Títulos da Dívida Pública|10182",
    "10185": "Títulos da Dívida Agrária|10182",
    "10186": "Agentes Políticos|9985",
    "10187": "Magistratura|10186",
    "10188": "Remuneração|10187",
    "10189": "Afastamento|10187",
    "10190": "Processo Disciplinar / Sindicância|10187",
    "10191": "Aposentadoria|10187",
    "10192": "Promoção|10187",
    "10193": "Remoção|10187",
    "10194": "Ministério Público|10186",
    "10195": "Remuneração|10194",
    "10196": "Afastamento|10194",
    "10197": "Processo Disciplinar / Sindicância|10194",
    "10198": "Aposentadoria|10194",
    "10199": "Promoção|10194",
    "10200": "Remoção|10194",
    "10201": "Prefeito|10186",
    "10202": "Afastamento do Cargo|10201",
    "10203": "Remuneração|10201",
    "10204": "Recondução|10201",
    "10205": "Prestação de Contas|10201",
    "10206": "Indisponibilidade de Bens|10201",
    "10207": "Governador|10186",
    "10208": "Afastamento do Cargo|10207",
    "10209": "Remuneração|10207",
    "10210": "Recondução|10207",
    "10211": "Prestação de Contas|10207",
    "10212": "Indisponibilidade de Bens|10207",
    "10213": "Parlamentares|10186",
    "10214": "Remuneração|10213",
    "10215": "Afastamento do Cargo|10213",
    "10216": "Recondução|10213",
    "10217": "Prestação de Contas|10213",
    "10218": "Indisponibilidade de Bens|10213",
    "10219": "Servidor Público Civil|9985",
    "10220": "Regime Estatutário|10219",
    "10221": "Isonomia/Equivalência Salarial|10288",
    "10222": "Estabilidade|10220",
    "10223": "Enquadramento|10220",
    "10224": "Recondução|10220",
    "10225": "Acumulação de Cargos|10220",
    "10226": "Reintegração|10220",
    "10227": "Direito de Greve|10220",
    "10228": "Transferência|10220",
    "10229": "Remoção|10220",
    "10230": "Regime Previdenciário|10220",
    "10231": "Anistia Administrativa|10220",
    "10232": "Reversão|10220",
    "10233": "Redistribuição|10220",
    "10234": "Disponibilidade / Aproveitamento|10220",
    "10235": "Lotação|10220",
    "10236": "Promoção / Ascensão|10220",
    "10237": "Readaptação|10220",
    "10238": "Estágio Probatório|10220",
    "10239": "Nomeação|10220",
    "10240": "Posse e Exercício|10220",
    "10241": "Exoneração|10220",
    "10243": "Complementação de Benefício/Ferroviário|10288",
    "10244": "Assistência à Saúde|10288",
    "10245": "Assistência Pré-escolar|10288",
    "10246": "Auxílio-Natalidade|10288",
    "10247": "Auxílio-Reclusão|10288",
    "10248": "Auxílio-Funeral|10288",
    "10249": "Salário-Família|10288",
    "10250": "Pensão|10219",
    "10251": "Provisória|10250",
    "10252": "Concessão|10250",
    "10253": "Restabelecimento|10250",
    "10254": "Aposentadoria|10219",
    "10255": "Invalidez Permanente|10254",
    "10256": "Compulsória|10254",
    "10257": "Voluntária|10254",
    "10258": "Licenças / Afastamentos|10219",
    "10259": "Dirigente Sindical|10258",
    "10260": "Licença Capacitação (Aperfeiçoamento Profissional)|10258",
    "10261": "Licença-Prêmio|10258",
    "10262": "Doença em Pessoa da Família|10258",
    "10263": "Tratamento da Própria Saúde|10258",
    "10264": "Gestante / Adotante / Paternidade|10258",
    "10265": "Interesse Particular|10258",
    "10266": "Serviço Militar|10258",
    "10267": "Atividade Política|10258",
    "10268": "Casamento|10258",
    "10269": "Luto|10258",
    "10270": "Amamentação|10258",
    "10271": "Doação de Sangue|10258",
    "10272": "Alistamento / Serviço Eleitoral|10258",
    "10273": "Acompanhamento de Cônjuge ou Companheiro|10258",
    "10274": "Exercício em Outro Município|10258",
    "10275": "Licença por Acidente em Serviço|10258",
    "10276": "Tempo de Serviço|10219",
    "10277": "Averbação / Contagem de Tempo Especial|10276",
    "10278": "Averbação / Contagem Recíproca|10276",
    "10279": "Processo Administrativo Disciplinar ou Sindicância|10219",
    "10280": "Demissão ou Exoneração|10279",
    "10281": "Advertência|10279",
    "10282": "Suspensão|10279",
    "10283": "Responsabilidade Civil do Servidor Público / Indenização ao Erário|10279",
    "10286": "Programa de Desligamento Voluntário (PDV)|10219",
    "10287": "Jornada de Trabalho|10219",
    "10288": "Sistema Remuneratório e Benefícios|10219",
    "10289": "Gratificações da Lei 8.112/1990|10288",
    "10290": "Gratificação de Incentivo|10288",
    "10291": "Adicional de Insalubridade|10288",
    "10292": "Adicional de Periculosidade|10288",
    "10293": "Adicional de Fronteira|10288",
    "10294": "Abono Pecuniário (Art. 78 Lei 8.112/1990)|10288",
    "10295": "Gratificação Incorporada / Quintos e Décimos / VPNI|10288",
    "10296": "Descontos Indevidos|10288",
    "10297": "Teto Salarial|10288",
    "10298": "Diárias e Outras Indenizações|10288",
    "10299": "Plano de Classificação de Cargos|10288",
    "10300": "Data Base|10288",
    "10301": "Férias|10288",
    "10302": "Adicional por Tempo de Serviço|10288",
    "10303": "Adicional de Horas Extras|10288",
    "10304": "Auxílio-Alimentação|10288",
    "10305": "Gratificações de Atividade|10288",
    "10306": "Auxílio-transporte|10288",
    "10307": "Revisão Geral Anual (Mora do Executivo - inciso X, art. 37, CF 1988)|10288",
    "10308": "Adicional de Serviço Noturno|10288",
    "10309": "Adicional de Produtividade|10288",
    "10310": "Gratificação Natalina/13º salário|10288",
    "10311": "Irredutibilidade de Vencimentos|10288",
    "10312": "Piso Salarial|10288",
    "10313": "Reajustes de Remuneração, Proventos ou Pensão|10219",
    "10314": "Índice da URV fev/1989|10313",
    "10315": "Índice de 84,32% março/1990|10313",
    "10316": "Índice de 45% Lei 8.237/1991|10313",
    "10317": "Índice de 28,86% Lei 8.622/1993 e 8.627/1993|10313",
    "10318": "Índice da URV Lei 8.880/1994|10313",
    "10319": "Reajuste da Lei 8.270/1991|10313",
    "10320": "Índice do IPC junho/1987|10313",
    "10321": "Índice de 10,87% Lei 10.192/2001|10313",
    "10322": "Índice de 47,94% Lei 8.676/1993|10313",
    "10323": "Inclusão de Dependente|10288",
    "10324": "Militar|9985",
    "10325": "Regime|10324",
    "10326": "Ingresso e Concurso|10325",
    "10327": "Curso de Formação|10325",
    "10328": "Reintegração|10325",
    "10329": "Estabilidade|10325",
    "10330": "Anistia Política|10325",
    "10331": "Adidos, Agregados e Adjuntos|10325",
    "10332": "Serviço Militar Obrigatório|10325",
    "10333": "Ex-combatentes|10325",
    "10334": "Promoção|10325",
    "10335": "Serviço Militar dos Profissionais da Saúde|10325",
    "10336": "Corpo Feminino|10325",
    "10337": "Sistema Remuneratório e Benefícios|10324",
    "10338": "Gratificações e Adicionais|10337",
    "10339": "Férias|10337",
    "10340": "Indenizações Regulares|10337",
    "10341": "Isonomia|10337",
    "10342": "Reajuste de Remuneração, Soldo, Proventos ou Pensão|10324",
    "10343": "Índice de 28,86% LL 8.622/1993 e 8.627/1993|10342",
    "10344": "Índice da URV Lei 8.880/1994|10342",
    "10345": "Índice do IPC  junho/1987|10342",
    "10346": "Índice de 84,32% IPC março/1990|10342",
    "10347": "Índice da URP abril e maio/1988 DL 2.425/1988|10342",
    "10349": "Reforma|10337",
    "10350": "Licenças|10337",
    "10351": "Agregação|10337",
    "10352": "Reserva Remunerada|10337",
    "10353": "Transferência ex-officio para reserva|10337",
    "10354": "Tempo de Serviço|10337",
    "10355": "Transferência para reserva|10337",
    "10356": "Assistência Médico-Hospitalar|10337",
    "10357": "Licença Prêmio|10337",
    "10358": "Licenciamento|10337",
    "10359": "Pensão|10324",
    "10360": "Concessão|10359",
    "10361": "Restabelecimento|10359",
    "10362": "Provisória|10359",
    "10363": "Processo Administrativo Disciplinar / Sindicância|10324",
    "10364": "Advertência / Repreensão|10363",
    "10365": "Impedimento / Detenção / Prisão|10363",
    "10366": "Licenciamento / Exclusão|10363",
    "10367": "Suspensão|10363",
    "10370": "Concurso Público / Edital|9985",
    "10371": "Reserva de Vagas para Deficientes|10370",
    "10372": "Inscrição / Documentação|10370",
    "10373": "Limite de Idade|10370",
    "10374": "Exigência de Prática Forense|10370",
    "10375": "Prova de Títulos|10370",
    "10376": "Exame de Saúde e/ou Aptidão Física|10370",
    "10377": "Curso de Formação|10370",
    "10378": "Exame Psicotécnico / Psiquiátrico|10370",
    "10379": "Anulação e Correção de Provas / Questões|10370",
    "10380": "Escolaridade|10370",
    "10381": "Classificação e/ou Preterição|10370",
    "10382": "Anulação|10370",
    "10383": "Prazo de Validade|10370",
    "10384": "Condições Especiais para Prestação de Prova|10370",
    "10385": "Licitações|9985",
    "10386": "Modalidade / Limite / Dispensa / Inexigibilidade|10385",
    "10387": "Habilitação / Registro Cadastral / Julgamento / Homologação|10385",
    "10388": "Edital|10385",
    "10389": "Revogação|10385",
    "10390": "Sanções Administrativas|10385",
    "10391": "Recursos Administrativos|10385",
    "10392": "Convênio|10385",
    "10393": "Adjudicação|10385",
    "10394": "Dívida Ativa não-tributária|9985",
    "10395": "Multas e demais Sanções|10394",
    "10396": "Ambiental|10395",
    "10397": "Sanitárias|10395",
    "10398": "Metrológica|10395",
    "10399": "Profissional|10395",
    "10400": "Segurança e/ou Medicina do Trabalho|10395",
    "10401": "Taxa de Ocupação / Laudêmio / Foro|10394",
    "10402": "Cessão de créditos não-tributários|10394",
    "10403": "Organização Sindical|9985",
    "10404": "Contribuição Sindical|10403",
    "10405": "Eleições Sindicais|10403",
    "10406": "Registro Sindical|10403",
    "10407": "Dilação e Extensão de Base Territorial|10403",
    "10408": "Filiação|10403",
    "10409": "Empregado Público / Temporário|9985",
    "10410": "Contrato Temporário de Mão de Obra L 8.745/1993|10409",
    "10411": "Admissão / Permanência / Despedida|10409",
    "10412": "Comissão Parlamentar de Inquérito - CPI|9985",
    "10413": "Quebra de Sigilo Bancário / Fiscal / Telefônico|10412",
    "10414": "Criação / Instalação / Prosseguimento / Encerramento|10412",
    "10415": "Limites dos Poderes de Investigação|10412",
    "10416": "Limites do Objeto|10412",
    "10417": "Sistema Nacional de Trânsito|9985",
    "10418": "CNH - Carteira Nacional de Habilitação|10417",
    "10419": "Liberação de Veículo Apreendido|10417",
    "10420": "Licenciamento de Veículo|10417",
    "10421": "Contratos Administrativos|9985",
    "10422": "Pagamento Atrasado / Correção Monetária|10421",
    "10423": "Anulação|10421",
    "10424": "Suspensão|10421",
    "10425": "Rescisão|10421",
    "10426": "Prorrogação|10421",
    "10427": "Termo Aditivo|10421",
    "10428": "Penalidades|10421",
    "10429": "Execução Contratual|10421",
    "10430": "Equilíbrio Financeiro|10421",
    "10431": "Responsabilidade Civil|899",
    "10432": "Coisas|899",
    "10433": "Indenização por Dano Moral|10431",
    "10434": "Erro Médico|10433",
    "10435": "Acidente de Trânsito|10433",
    "10436": "Lei de Imprensa|10433",
    "10437": "Direito de Imagem|10433",
    "10438": "Dano Ambiental|10431",
    "10439": "Indenização por Dano Material|10431",
    "10440": "Erro Médico|10439",
    "10441": "Acidente de Trânsito|10439",
    "10442": "Lei de Imprensa|10439",
    "10443": "Direito de Imagem|10439",
    "10444": "Posse|10432",
    "10445": "Esbulho / Turbação / Ameaça|10444",
    "10446": "Imissão|10444",
    "10447": "Aquisição|10444",
    "10448": "Propriedade|10432",
    "10449": "Perda da Propriedade|10448",
    "10450": "Adjudicação Compulsória|10448",
    "10451": "Divisão e Demarcação|10448",
    "10452": "Reivindicação|10448",
    "10453": "Retificação de Área de Imóvel|10448",
    "10454": "Alienação Judicial|10448",
    "10455": "Aquisição|10448",
    "10456": "Acessão|10455",
    "10457": "Usucapião Especial (Constitucional)|10455",
    "10458": "Usucapião Extraordinária|10455",
    "10459": "Usucapião Ordinária|10455",
    "10460": "Usucapião Especial Coletiva|10455",
    "10461": "Direito de Vizinhança|10448",
    "10462": "Condomínio|10448",
    "10463": "Condomínio em Edifício|10448",
    "10464": "Administração|10463",
    "10465": "Alteração de Coisa Comum|10463",
    "10466": "Assembléia|10463",
    "10467": "Despesas Condominiais|10463",
    "10468": "Direitos / Deveres do Condômino|10463",
    "10469": "Vaga de garagem|10463",
    "10470": "Incorporação Imobiliária|10448",
    "10480": "Propriedade Resolúvel|10448",
    "10481": "Propriedade Fiduciária|10448",
    "10482": "Enfiteuse|10432",
    "10483": "Servidão|10432",
    "10484": "Usufruto|10432",
    "10485": "Superfície|10432",
    "10486": "Uso|10432",
    "10487": "Habitação|10432",
    "10488": "Penhor|10432",
    "10489": "Rural - Agrícola/Pecuário|10488",
    "10490": "Industrial / Mercantil|10488",
    "10491": "Direitos e Títulos de Crédito|10488",
    "10492": "Veículos|10488",
    "10493": "Legal|10488",
    "10494": "Hipoteca|10432",
    "10495": "Anticrese|10432",
    "10496": "Promessa de Compra e Venda|10432",
    "10497": "Subsídios|10288",
    "10499": "Programa de Computador|4654",
    "10500": "Usucapião da L 6.969/1981|10455",
    "10501": "Crédito Rural|9580",
    "10502": "Indenização por Dano Material|9991",
    "10503": "Erro Médico|10502",
    "10504": "Acidente de Trânsito|10502",
    "10505": "Variação Cambial|10502",
    "10508": "Maus Tratos|3388",
    "10509": "Exposição ou Abandono de Recém Nascido|3388",
    "10512": "Responsabilidade Civil do Militar - Indenização ao Erário|10363",
    "10514": "Utilização de Embrião Humano em Desacordo com a Legislação|3618",
    "10515": "Prática de engenharia genética em célula germinal, zigoto ou embrião humanos|3618",
    "10516": "Clonagem Humana|3618",
    "10523": "Despenalização / Descriminalização|5885",
    "10527": "Livros / Jornais / Periódicos|5914",
    "10528": "Entidades Sem Fins Lucrativos|5914",
    "10529": "Partidos Políticos|5914",
    "10530": "Imunidade Recíproca|5914",
    "10531": "ICMS / Incidência Sobre o Ativo Fixo|5946",
    "10532": "Incidência sobre Auxílio-condução|5917",
    "10533": "Incidência sobre Auxílio-creche|5917",
    "10534": "Taxa de Limpeza Pública|5972",
    "10535": "Taxa de Iluminação Pública|5972",
    "10536": "Taxa de Coleta de Lixo|5972",
    "10537": "Taxa de Prevenção e Combate a Incêndio|5972",
    "10538": "Taxa de Licenciamento de Estabelecimento|5972",
    "10539": "Capacidade Tributária|5978",
    "10540": "Competência Tributária|5913",
    "10543": "Depósito Judicial|5987",
    "10544": "Constitucionalidade do artigo 4º da LC 118/05|5992",
    "10545": "Constitucionalidade do artigo 46 da Lei 8212/91|5992",
    "10546": "Suspensão|5992",
    "10547": "Interrupção|5992",
    "10548": "Decretação de Ofício|5992",
    "10549": "Arquivamento Administrativo - Crédito de Pequeno Valor|10546",
    "10550": "Despacho de Citação|10547",
    "10551": "Constitucionalidade do artigo 45 da Lei 8212/91|5993",
    "10556": "Exclusão - ICMS|6008",
    "10557": "Exclusão - IPI|6008",
    "10558": "Exclusão - Receitas Provenientes de Exportação|6008",
    "10559": "Cálculo de ICMS &quot;por dentro&quot;|6008",
    "10560": "Quebra de Sigilo Bancário|6021",
    "10561": "PIS - Importação|6033",
    "10562": "COFINS - Importação|6033",
    "10563": "Compensação de Prejuízo|6036",
    "10564": "Contribuição Sindical Rural|1697",
    "10565": "Contribuição Sindical Rural|6044",
    "10567": "Aposentadoria por Invalidez Acidentária|6094",
    "10568": "Prescrição|864",
    "10569": "Rural|10568",
    "10570": "FGTS|10568",
    "10571": "Acidente de Trabalho|10568",
    "10572": "Termo de Adesão da LC 110/2001|10159",
    "10573": "Prisão Civil|9148",
    "10577": "Relações de Parentesco|5626",
    "10582": "Rescisão / Resolução|7691",
    "10585": "Capitalização / Anatocismo|7699",
    "10586": "Limitação de Juros|7699",
    "10587": "Proteção da Intimidade e Sigilo de Dados|9986",
    "10588": "Vícios de Construção|4839",
    "10589": "Juros Compensatórios|10120",
    "10590": "Resgate de Contribuição|4805",
    "10591": "Inclusão de Dependente|10337",
    "10592": "Desconto em folha de pagamento|7690",
    "10593": "Remuneração Mínima|10337",
    "10595": "Multa|10463",
    "10596": "Auxílio-invalidez|10337",
    "10598": "Cobrança indevida de ligações|7617",
    "10599": "Quesitos|4264",
    "10600": "Impedimento|4264",
    "10601": "Suspeição|4264",
    "10602": "Condicional do processo|4271",
    "10603": "Parcelamento de crédito tributário|4271",
    "10604": "Investigação Penal|1209",
    "10605": "Competência do MP|10604",
    "10606": "Cerceamento de Defesa|10604",
    "10607": "Quebra do Sigilo Bancário|10604",
    "10608": "Quebra do Sigilo Fiscal|10604",
    "10609": "Quebra do Sigilo Telefônico|10604",
    "10610": "Trancamento|10604",
    "10612": "Tipicidade|10620",
    "10613": "Excludentes|10620",
    "10614": "Abolitio Criminis|10612",
    "10615": "Princípio da Insignificância|10612",
    "10616": "Legítima Defesa|10613",
    "10617": "Estado de Necessidade|10613",
    "10618": "Estrito Cumprimento do Dever Legal|10613",
    "10619": "Inexigibilidade de Conduta Diversa|10613",
    "10620": "Parte Geral|287",
    "10621": "Aplicação da Pena|10620",
    "10622": "Extinção da Punibilidade|10620",
    "10623": "Prescrição|10622",
    "10624": "Perdão|10622",
    "10625": "Anistia|10622",
    "10626": "Indulto|10622",
    "10627": "Pagamento ou Parcelamento do Crédito Tributário|10622",
    "10628": "Substituição da Pena|10621",
    "10630": "Transação|4263",
    "10631": "Desaforamento|4291",
    "10632": "Prisão Temporária|1209",
    "10633": "Regime inicial|10621",
    "10634": "Suspensão condicional da pena|10620",
    "10635": "Progressão de Regime|7791",
    "10636": "Livramento condicional|7791",
    "10637": "Remição|7791",
    "10638": "Acumulação de Proventos|10288",
    "10640": "Prisão Decorrente de Sentença Condenatória|4263",
    "10641": "Penas do Código Penal Militar|7942",
    "10642": "Efeitos da Condenação|10620",
    "10643": "Perda da Função Pública|10642",
    "10644": "Perda de Bens e Valores|10642",
    "10645": "Controle de Constitucionalidade|9985",
    "10646": "Inconstitucionalidade Material|10645",
    "10647": "Processo Legislativo|10645",
    "10651": "Competência dos Juizados Especiais|8829",
    "10652": "Competência da Justiça do Trabalho|8829",
    "10653": "Competência da Justiça Federal|8829",
    "10654": "Competência da Justiça Estadual|8829",
    "10655": "Honorários Advocatícios|8874",
    "10656": "Honorários Advocatícios em Execução Contra a Fazenda Pública|8874",
    "10657": "Honorários Advocatícios em FGTS|8874",
    "10658": "Custas|8874",
    "10659": "Suspeição|8884",
    "10660": "Impedimento|8884",
    "10661": "Incidência sobre Abono de Permanência|5917",
    "10662": "Abono de Permanência|10288",
    "10666": "Regularidade Formal|9045",
    "10667": "Gratificações Estaduais Específicas|10288",
    "10668": "Fac-símile|9060",
    "10669": "Protocolo Integrado / Descentralizado|9060",
    "10670": "Obrigação de Entregar|9148",
    "10671": "Obrigação de Fazer / Não Fazer|9148",
    "10672": "Precatório|9148",
    "10673": "Requisição de Pequeno Valor - RPV|9148",
    "10675": "Quinto Constitucional|10187",
    "10676": "Imissão na Posse|10670",
    "10677": "Busca e Apreensão|10670",
    "10678": "Sequestro de Verbas Públicas|10672",
    "10679": "Fracionamento|10672",
    "10680": "Crédito Complementar|10672",
    "10681": "Aposentadoria / Pensão Especial|10213",
    "10683": "Exceção de Pré-executividade|9148",
    "10684": "Juros|9149",
    "10685": "Correção Monetária|9149",
    "10686": "Multa Cominatória / Astreintes|9148",
    "10687": "Taxa SELIC|9149",
    "10688": "Medidas Sócio-educativas|9633",
    "10689": "Correção da Tabela|5917",
    "10690": "IPMF - Imposto Provisório sobre Movimentação Financeira|5916",
    "10691": "Questão Prejudicial|4271",
    "10692": "Tribunal de Contas|10157",
    "10693": "Cargo em Comissão|10239",
    "10695": "Categorias Especiais de Servidor Público|10219",
    "10696": "Professor|10695",
    "10697": "Procuradores de Órgãos / Entidades Públicos|10695",
    "10698": "Serventuários da Justiça|10695",
    "10699": "Extensão de Vantagem aos Inativos|10221",
    "10700": "Contagem em Dobro|10261",
    "10701": "Pagamento em Pecúnia|10261",
    "10702": "Índice de 3,17%|10318",
    "10703": "Índice de 11,98%|10318",
    "10705": "Subteto Salarial|10288",
    "10706": "Gratificações Municipais Específicas|10288",
    "10707": "Gratificação de Encargos Especiais - GEE|10667",
    "10708": "Gratificação de Estímulo à Produção Individual - GEPI|10667",
    "10709": "Gratificação Estadual - AM|10667",
    "10710": "Adicional de Etapa Alimentar|10667",
    "10711": "Gratificação de Suporte às Atividades Escolares - GSAE|10667",
    "10712": "Gratificação por Trabalho Educacional - GTE|10667",
    "10713": "Gratificação de Suporte Administrativo - GASA|10667",
    "10714": "Gratificação-Prêmio|10667",
    "10715": "Gratificação Complementar de Vencimento|10667",
    "10718": "Gratificações Por Atividades Específicas|10288",
    "10719": "Gratificação de Desempenho de Atividade Técnico Administrativa - GDATA|10718",
    "10720": "Gratificação de Desempenho de Atividade Tributária - GDAT|10718",
    "10721": "Gratificação de Desempenho de Atividade de Fiscalização Agropecuária - GDAFA|10718",
    "10722": "Gratificação de Desempenho de Atividade Jurídica - GDAJ|10718",
    "10723": "Gratificação de Atividade - GATA|10718",
    "10724": "Gratificação de Incentivo à Docência - GID|10718",
    "10725": "Gratificação de Produção Suplementar - GPS|10718",
    "10726": "Gratificação Eleitoral|10718",
    "10727": "Gratificação de Inatividade|10718",
    "10728": "Gratificação Extraordinária - GE|10718",
    "10729": "Gratificação de Desempenho de Função - GADF|10718",
    "10730": "Adicional de Desempenho|10288",
    "10731": "Adicional de Sexta-Parte|10288",
    "10732": "Verba de Representação|10214",
    "10733": "Extensão de Vantagem aos Inativos|10341",
    "10734": "Reserva de Plenário|8919",
    "10735": "Interesse Processual|8942",
    "10736": "Legitimidade para a Causa|8942",
    "10737": "Adequação da Ação / Procedimento|8942",
    "10738": "Sobrestamento|9045",
    "10739": "DIREITO ELEITORAL E PROCESSO ELEITORAL DO STF|",
    "10740": "Partido Político|10739",
    "10741": "Criação do Partido Político|10740",
    "10743": "Registro do Partido Político|10740",
    "10744": "Extinção do Partido Político|10740",
    "10745": "Filiação partidária|10740",
    "10746": "Convenção para escolha de candidatos|10740",
    "10747": "Inscrição fraudulenta|3654",
    "10748": "Calúnia|3654",
    "10749": "Difamação|3654",
    "10750": "Injúria|3654",
    "10751": "Captação ilícita de votos ou corrupção eleitoral|3654",
    "10752": "Aliciamento eleitoral|3654",
    "10753": "Falso eleitoral|3654",
    "10754": "Desobediência à determinações da Justiça Eleitoral|3654",
    "10755": "Boca de urna|3654",
    "10756": "Indução à inscrição indevida|3654",
    "10757": "Fidelidade partidária|10740",
    "10760": "Plebiscito|10739",
    "10762": "Referendo popular|10739",
    "10763": "Eleição|10739",
    "10764": "Sistema Majoritário / Proporcional|10763",
    "10765": "Número de vagas|10763",
    "10766": "Eleitor|10763",
    "10767": "Registro da candidatura|10763",
    "10768": "Campanha Eleitoral|10763",
    "10769": "Pleito|10763",
    "10770": "Diplomação|10763",
    "10771": "fixação|10765",
    "10772": "aumento|10765",
    "10773": "redução|10765",
    "10774": "Alistamento|10766",
    "10775": "Domicílio eleitoral|10766",
    "10776": "Isenção do dever eleitoral|10766",
    "10777": "Justificação de ausência de voto|10766",
    "10778": "Cancelamento da inscrição de eleitor|10774",
    "10779": "Desincompatibilização do candidato|10767",
    "10780": "Inelegibilidade|10767",
    "10781": "Reeleição|10767",
    "10782": "Cassação do registro da candidatura|10767",
    "10783": "Anistia|10767",
    "10784": "Propaganda eleitoral|10768",
    "10785": "Direito de resposta|10768",
    "10786": "Prestação de contas|10768",
    "10787": "Pesquisa eleitoral|10768",
    "10788": "Abuso do poder econômico|10768",
    "10789": "Abuso do poder político (uso indevido dos meios de comunicação)|10768",
    "10790": "Uso de bem público|10768",
    "10791": "Sistema eletrônico de votação|10769",
    "10792": "Sigilo na votação|10769",
    "10793": "Apuração/recontagem de votos|10769",
    "10794": "Nulidade da votação|10769",
    "10795": "Quociente eleitoral|10769",
    "10796": "Transporte em dia de eleição|10769",
    "10797": "Boca de urna|10769",
    "10798": "Cassação do diploma|10770",
    "10799": "Sustação da diplomação|10770",
    "10800": "Mandato|10739",
    "10801": "Prestação de contas|10800",
    "10802": "Declaração de inelegibilidade|10800",
    "10803": "Impugnação de mandato eletivo|10800",
    "10804": "Perda do mandato|10800",
    "10805": "Recondução|10800",
    "10806": "Suspensão de mandato|10800",
    "10807": "Convocação de suplente|10800",
    "10808": "Renúncia|10800",
    "10809": "Extinção do mandato|10800",
    "10810": "Afastamento do cargo|10800",
    "10811": "Cassação de mandato|10800",
    "10812": "Por cumulação de cargos|10809",
    "10813": "Por condenação criminal|10809",
    "10814": "Por suspensão dos direitos políticos|10809",
    "10815": "Desordem eleitoral|3654",
    "10816": "Falsificação de documento público para fins eleitorais|3654",
    "10817": "Uso de documento falso|3654",
    "10818": "Obtenção de documento falso|3654",
    "10819": "Aliciamento de eleitores|3654",
    "10820": "Divulgação de pesquisa de fraudulenta|3654",
    "10821": "Impedimento à fiscalização das pesquisas|3654",
    "10822": "Publicação de pesquisa irregular|3654",
    "10823": "Uso de símbolos governamentais em propaganda eleitoral|3654",
    "10824": "Coação eleitoral|3654",
    "10825": "Aliciamento violento de eleitores|3654",
    "10826": "Aliciamento comercial de eleitores|3654",
    "10827": "Ocultação de urna|3654",
    "10830": "Motim|3664",
    "10831": "Pederastia ou outro ato de libidinagem|3664",
    "10832": "Roubo|3664",
    "10833": "Estelionato|3664",
    "10834": "Incêndio|3664",
    "10835": "Peculato|3664",
    "10836": "Concussão|3664",
    "10837": "Corrupção ativa|3664",
    "10838": "Prevaricação|3664",
    "10839": "Deserção|3664",
    "10840": "Homicídio|3664",
    "10841": "Lesões Corporais|3664",
    "10842": "Lesão levíssima|10841",
    "10843": "Difamação|3664",
    "10844": "Injúria|3664",
    "10845": "Homicídio simples|10840",
    "10846": "Homicídio qualificado|10840",
    "10847": "Leve|10841",
    "10848": "Grave|10841",
    "10849": "Lesões qualificadas pelo resultado|10841",
    "10850": "Roubo simples|10832",
    "10851": "Roubo qualificado|10832",
    "10852": "Latrocínio|3664",
    "10854": "Auditores Fiscais|10695",
    "10855": "Comissão de Permanência|7691",
    "10856": "Prescrição por Médico não vinculado ao SUS|10069",
    "10857": "Gratificação de Desempenho de Atividade de Seguridade Social e do Trabalho - GDASST|10718",
    "10858": "Gratificação de Desempenho de Atividade de Ciência e Tecnologia - GDACT|10718",
    "10859": "Alimentos|10573",
    "10860": "Depositário Infiel|10573",
    "10861": "Alienação Fiduciária|10573",
    "10862": "Proibição de Privilégio Fiscal às Empresas Públicas e Sociedades de Economia Mista|10146",
    "10863": "Legitimidade para propositura de Ação Civil Pública|10736",
    "10864": "Crimes Hediondos|10635",
    "10865": "Cerceamento de Defesa|4264",
    "10866": "Ausência de Publicidade|4264",
    "10867": "Ausência de Fundamentação|4264",
    "10868": "Criação / Extinção / Reestruturação de Orgãos ou Cargos Públicos|10157",
    "10869": "Parcela Incontroversa|10672",
    "10870": "Cancelamento / Duplicidade de CPF|9992",
    "10871": "Não Cumulatividade|5945",
    "10872": "Nao Cumulatividade|5946",
    "10873": "Não Cumulatividade|6035",
    "10874": "Não Cumulatividade|6039",
    "10875": "Base de Cálculo|10291",
    "10877": "Policiais Civis|10695",
    "10878": "Especial|10254",
    "10879": "Limite de Renda Familiar|6160",
    "10880": "Execução Provisória|9148",
    "10881": "Nepotismo|10220",
    "10883": "Fruição / Gozo|10301",
    "10884": "Indenização / Terço Constitucional|10301",
    "10885": "Liquidação Parcelada|10672",
    "10886": "Nulidade do Decreto que autoriza a desapropriação|10120",
    "10887": "Ausência de Cobrança Administrativa Prévia|6017",
    "10888": "Inscrição Indevida no CADIN|9992",
    "10889": "Efeitos da Declaração de Inconstitucionalidade|10645",
    "10890": "Vício Formal do Julgamento|4264",
    "10891": "Habeas Corpus - Cabimento|1209",
    "10892": "Medicamento / Tratamento / Cirurgia de Eficácia não comprovada|10069",
    "10893": "Base de Cálculo|10302",
    "10894": "Abuso de Poder|9997",
    "10897": "Competência dos Juizados Especiais|4291",
    "10898": "Competência da Justiça Federal|4291",
    "10899": "Competência da Justiça Estadual|4291",
    "10900": "Competência por Prerrogativa de Função|4291",
    "10901": "Competência por Prerrogativa de Função|8829",
    "10902": "Excesso de prazo para instrução / julgamento|4263",
    "10903": "Incidência sobre desconto incondicional|5945",
    "10904": "Prisão Domiciliar / Especial|1209",
    "10905": "Passe livre em transporte|10073",
    "10906": "Regressão de Regime|7791",
    "10907": "Transferência de Preso|7791",
    "10908": "Execução Penal Provisória - Cabimento|7942",
    "10909": "Imunidade em razão de função, ofício ou profissão|4291",
    "10910": "Transferência de Unidade|10325",
    "10912": "Medidas Assecuratórias|1209",
    "10913": "Indisponibilidade / Seqüestro de Bens|10912",
    "10914": "Busca e Apreensão de Bens|10912",
    "10915": "Aborto|3369",
    "10917": "Aborto provocado pela gestante ou com o seu consentimento|10915",
    "10918": "Aborto provocado por terceiro|10915",
    "10919": "Aborto qualificado|10915",
    "10921": "Repatriação de Criança ou Adolescente|6203",
    "10922": "Cobrança de Multa Moratória de Massa Falida|6017",
    "10923": "Cobrança de Juros Moratórios de Massa Falida|6017",
    "10924": "Depósito Elisivo|4993",
    "10925": "Provas|4263",
    "10926": "Prova Ilícita|10925",
    "10928": "Competência do Órgão Fiscalizador|10015",
    "10930": "Município|10157",
    "10931": "Criação|10930",
    "10932": "Desmembramento|10930",
    "10935": "Desclassificação|4368",
    "10936": "Guarda com genitor ou responsável no exterior|10577",
    "10938": "Citação|8893",
    "10939": "Intimação / Notificação|8893",
    "10940": "Depoimento|8990",
    "10941": "Viagem Nacional|9964",
    "10945": "Expurgos Inflacionários / Planos Econômicos|7752",
    "10946": "Correção Monetária de Diferenças Pagas em Atraso|10313",
    "10947": "Princípio da Seletividade|5945",
    "10948": "Violência Doméstica Contra a Mulher|5626",
    "10949": "Violência Doméstica Contra a Mulher|287",
    "10950": "Crime / Contravenção contra Criança / Adolescente|287",
    "10951": "Crime / Contravenção contra Idoso|287",
    "10952": "Fato Atípico|287",
    "10954": "Orçamento|9985",
    "10955": "Criação de Dotação Orçamentária|10954",
    "10956": "Repasse de Duodécimos|10954",
    "10957": "Repasse de Verbas Públicas|10954",
    "10958": "Crédito Suplementar|10954",
    "10959": "Exclusão - Receitas Transferidas a outras Pessoas Jurídicas|6008",
    "10982": '"Lavagem" ou Ocultação de Bens, Direitos ou Valores Oriundos de Corrupção|3547',
    "10983": '"Lavagem" ou Ocultação de Bens, Direitos ou Valores Oriundos de Corrupção|5872',
    "10984": '"Lavagem" ou Ocultação de Bens, Direitos ou Valores Oriundos de Corrupção|5873',
    "10985": "Crimes contra a Ordem Tributária praticado por Funcionário Público|3603",
    "10986": "Crime contra a administração ambiental|3618",
    "10987": "Tráfico Ilícito de Drogas praticado por Funcionário Público|3607",
    "10988": "Má gestão|5875",
    "10989": "Má -gestão praticada por Prefeitos e Vereadores|10988",
    "10990": "Má-gestão praticada por Prefeitos e Vereadores|5875",
    "10991": "Corrupção Praticada por Prefeitos e Vereadores|5875",
    "10992": "Adulteração de Sinal Identificador de Veículo Automotor por Funcionário Público|3523",
    "10993": "Petrechos de Falsificação de Papéis Públicos por Funcionário Público|3523",
    "10994": "Petrechos para falsificação de moeda por Funcionário Público|3523",
    "10995": "Falsidade ideológica praticada por Funcionário Público|3523",
    "10996": "Falsificação de documento Público praticado por Funcionário Público|3523",
    "10997": "Falsificação de documento público praticada por Funcionário Público|10996",
    "10998": "Falsificação de documento Público praticada por Funcionário Público|3523",
    "10999": "Conversão em Agravo Retido|9045",
    "11000": "Cobrança de Aluguéis - Sem despejo|9593",
    "11001": "Consignação de Chaves|9593",
    "11004": "Falsificação do Selo ou Sinal Público Praticado por Funcionário Público|3523",
    "11005": "Falsificação de Papéis Públicos Praticado por Funcionário Público|3523",
    "11006": "Crime de Desvio e/ou Circulação de Moeda Não Autorizada|3523",
    "11007": "Crime de Moeda Falsa Praticado por Funcionário Público|3523",
    "11047": "Competência da Justiça Militar da União|8829",
    "11048": "Competência da Justiça Militar dos Estados|8829",
    "11049": "DIREITO PROCESSUAL PENAL MILITAR|",
    "11050": "Ação Penal Militar|11049",
    "11051": "Correição Parcial|11049",
    "11052": "Execução Penal|11049",
    "11053": "Jurisdição e Competência|11049",
    "11054": "Menagem|11049",
    "11055": "Competência da Justiça Militar da União|11053",
    "11056": "Competência da Justiça Militar Estadual|11053",
    "11057": "Penas restritivas de direitos|11052",
    "11058": "Cassação de licença para direção de veículo motorizado|11057",
    "11059": "Confisco|11057",
    "11060": "Exílio local|11057",
    "11061": "Interdição de estabecimento  ou associação|11057",
    "11062": "Proibição de frequentar determinados locais|11057",
    "11063": "Suspensão do exercício do posto, graduação, cargo ou função|11057",
    "11064": "Representação do Corregedor|11051",
    "11065": "Requerimento da Parte|11051",
    "11066": "Nulidade|11050",
    "11067": "Irregularidade na composição do Conselho|11066",
    "11068": "DIREITO PENAL MILITAR|",
    "11069": "Crime Culposo|11068",
    "11070": "Crime em tempo de guerra|11068",
    "11071": "Crime Tentado|11068",
    "11072": "Crimes contra a Administração da Justiça Militar|11068",
    "11073": "Crimes contra a Administração Militar|11068",
    "11074": "Crimes contra a Autoridade ou Disciplina Militar|11068",
    "11075": "Crimes contra a Pessoa|11068",
    "11076": "Crimes contra a Segurança Externa do País|11068",
    "11077": "Crimes contra Incolumidade Pública|11068",
    "11078": "Crimes contra o Patrimônio|11068",
    "11079": "Crimes contra o Serviço Militar e o Dever Militar|11068",
    "11080": "Parte Geral|11068",
    "11081": "Aplicação da Pena|11080",
    "11082": "Efeitos da Condenação|11080",
    "11083": "Excludentes|11080",
    "11084": "Extinção da punibilidade|11080",
    "11085": "Livramento Condicional|11080",
    "11086": "Penas Acessórias|11080",
    "11087": "Suspensão Condicional da Pena|11080",
    "11088": "Tipicidade|11080",
    "11089": "Abolitio criminis|11088",
    "11090": "Princípio da Insignificância|11088",
    "11091": "Exclusão das Forças Armadas|11086",
    "11092": "Inabilitação para o exercício de Função Pública|11086",
    "11093": "Incompatibilidade para o Oficialato|11086",
    "11094": "Indignidade para o Oficialato|11086",
    "11095": "Perda da Função Pública|11086",
    "11096": "Perda da Graduação das Praças|11086",
    "11097": "Perda do Posto e da Patente|11086",
    "11098": "Suspensão do pátrio poder, tutela ou curatela|11086",
    "11099": "Suspensão dos direitos políticos|11086",
    "11100": "Anistia|11084",
    "11101": "Indulto|11084",
    "11102": "Morte do Agente|11084",
    "11103": "Prescrição|11084",
    "11104": "Reabilitação|11084",
    "11105": "Ressarcimento do Dano|11084",
    "11106": "Estado de Necessidade|11083",
    "11107": "Estado de Necessidade exculpante|11083",
    "11108": "Estrito Cumprimento do Dever Legal|11083",
    "11109": "Exercício Regular do Direito|11083",
    "11110": "Inexigibilidade de Conduta Diversa|11083",
    "11111": "Legítima Defesa|11083",
    "11112": "Perda em favor da Fazenda Pública|11082",
    "11113": "Reparação do Dano|11082",
    "11114": "Conversão da Pena|11081",
    "11115": "Substituição da Pena|11081",
    "11116": "Abandono de posto e de outros crimes em serviço|11079",
    "11117": "Deserção|11079",
    "11118": "Exercício de comércio|11079",
    "11119": "Insubmissão|11079",
    "11120": "Criação ou simulação de incapacidade física|11119",
    "11121": "Favorecimento a convocado|11119",
    "11122": "Insubmissão|11119",
    "11123": "Insubmissão privilegiada|11119",
    "11124": "Substituição de convocado|11119",
    "11125": "Exercício de comércio por oficial|11118",
    "11126": "Concerto para deserção|11117",
    "11127": "Deserção|11117",
    "11128": "Deserção - casos assimilados|11117",
    "11129": "Deserção especial|11117",
    "11130": "Deserção por evasão ou fuga|11117",
    "11131": "Deserção privilegiada|11117",
    "11132": "Deserção qualificada|11117",
    "11133": "Favorecimento a desertor|11117",
    "11134": "Omissão de Oficial|11117",
    "11135": "Abandono de posto|11116",
    "11136": "Descumprimento de missão|11116",
    "11137": "Dormir em serviço|11116",
    "11138": "Embriaguez em serviço|11116",
    "11139": "Omissão de eficiência da força|11116",
    "11140": "Omissão de providências para evitar danos|11116",
    "11141": "Omissão de providências para salvar comandados|11116",
    "11142": "Omissão de socorro|11116",
    "11143": "Retenção indevida|11116",
    "11144": "Apropriação Indébita|11078",
    "11145": "Dano|11078",
    "11146": "Estelionato e outras fraudes|11078",
    "11147": "Furto|11078",
    "11148": "Receptação|11078",
    "11149": "Roubo e Extorsão|11078",
    "11150": "Usura|11078",
    "11151": "Usurpação|11078",
    "11152": "Alteração de Limites|11151",
    "11153": "Aposição, supressão ou alteração de marca|11151",
    "11154": "Usura pecuniária|11150",
    "11155": "Chantagem|11149",
    "11156": "Extorsão|11149",
    "11157": "Extorsão indireta|11149",
    "11158": "Extorsão mediante sequestro|11149",
    "11159": "Latrocínio|11149",
    "11160": "Roubo|11149",
    "11161": "Roubo qualificado|11149",
    "11162": "Receptação|11148",
    "11163": "Furto|11147",
    "11164": "Furto de uso|11147",
    "11165": "Furto qualificado|11147",
    "11166": "Abuso de pessoa|11146",
    "11167": "Estelionato|11146",
    "11168": "Dano atenuado|11145",
    "11169": "Dano em aparelhos e instalações|11145",
    "11170": "Dano em navio de guerra ou mercante em serviço militar|11145",
    "11171": "Dano material ou aparelhamento de guerra|11145",
    "11172": "Dano qualificado|11145",
    "11173": "Dano simples|11145",
    "11174": "Desaparecimento,consunção ou extravio|11145",
    "11175": "Apropriação de coisa achada|11144",
    "11176": "Apropriação de coisa havida acidentalmente|11144",
    "11177": "Apropriação indébita|11144",
    "11178": "Contra a Saúde|11077",
    "11179": "Crimes contra os Meios de Transporte e de Comunicação|11077",
    "11180": "Crimes de Perigo Comum|11077",
    "11181": "Abuso de radiação|11180",
    "11182": "Desabamento ou desmoronamento|11180",
    "11183": "Difusão de epizootia ou praga vegetal|11180",
    "11184": "Embriaguez ao volante|11180",
    "11185": "Emprego de gás tóxico ou asfixiante|11180",
    "11186": "Explosão|11180",
    "11187": "Fatos que expõem a perigo aparelhamento militar|11180",
    "11188": "Fuga após acidente de trânsito|11180",
    "11189": "Incêndio|11180",
    "11190": "Inundação|11180",
    "11191": "Perigo de inundação|11180",
    "11192": "Perigo resultante de violação de regra de trânsito|11180",
    "11193": "Subtração, ocultação ou inutilização de material de socorro|11180",
    "11194": "Arremesso de projétil|11179",
    "11195": "Atentado contra serviço de utilidade militar|11179",
    "11196": "Atentado contra transporte|11179",
    "11197": "Atentado contra viatura ou outro meio de transporte|11179",
    "11198": "Interrupção ou perturbação de serviço ou meio de comunicação|11179",
    "11199": "Perigo de desastre ferroviário|11179",
    "11200": "Corrupção ou poluição de água potável|11178",
    "11201": "Envenenamento com perigo extensivo|11178",
    "11202": "Epidemia|11178",
    "11203": "Fornecimento de substância alterada|11178",
    "11204": "Fornecimento de substancia nociva|11178",
    "11205": "Omissão de notificação de doença|11178",
    "11206": "Receita Ilegal|11178",
    "11207": "Tráfico, posse ou uso de entorpecente ou substância de efeito similar|11178",
    "11208": "Atos de jurisdição indevida|11076",
    "11209": "Consecução de notícia, informação ou documento para fim de espionagem|11076",
    "11210": "Desenho ou levantamento de plano ou planta de local militar|11076",
    "11211": "Entendimento para empenhar o Brasil à neutralidade ou à guerra|11076",
    "11212": "Entendimento para gerar conflito ou divergência com o Brasil|11076",
    "11213": "Hostilidade contra país estrangeiro|11076",
    "11214": "Hostilidade qualificada pelo resultado|11076",
    "11215": "Penetração com o fim de espionagem|11076",
    "11216": "Provocação a país estrangeiro|11076",
    "11217": "Revelação de notícia, informação ou documento|11076",
    "11218": "Revelação de notícia, informação ou documento, com o fim de espionagem militar|11076",
    "11219": "Sobrevôo em local interdito|11076",
    "11220": "Tentativa contra a soberania do Brasil|11076",
    "11221": "Turbação de objeto ou documento|11076",
    "11222": "Violação de território estrangeiro|11076",
    "11223": "Crimes contra a Honra|11075",
    "11224": "Crimes contra a Liberdade|11075",
    "11225": "Crimes Sexuais|11075",
    "11226": "Genocídio|11075",
    "11227": "Homicídio|11075",
    "11228": "Lesão Corporal e Rixa|11075",
    "11229": "Periclitação da Vida ou da Saúde|11075",
    "11230": "Ultraje Público ao Pudor|11075",
    "11231": "Ato obsceno|11230",
    "11232": "Escrito ou objeto obsceno|11230",
    "11233": "Abandono de pessoa|11229",
    "11234": "Abandono de pessoa seguida de morte ou lesão grave|11229",
    "11235": "Maus tratos|11229",
    "11236": "Maus tratos seguido de morte ou lesão grave|11229",
    "11237": "Lesão grave|11228",
    "11238": "Lesão leve|11228",
    "11239": "Lesão levíssima|11228",
    "11240": "Lesão privilegiada|11228",
    "11241": "Lesão seguida de morte|11228",
    "11242": "Participação em rixa|11228",
    "11243": "Homicídio|11227",
    "11244": "Homicidio qualificado|11227",
    "11245": "Homicido privilegiado|11227",
    "11246": "Provocação direta ou auxílio a suicídio|11227",
    "11247": "Provocação indireta ao suicídio|11227",
    "11248": "Genocídio|11226",
    "11249": "Atentado violento ao pudor|11225",
    "11250": "Corrupção de menores|11225",
    "11251": "Estupro|11225",
    "11252": "Pederastia ou outro ato de libidinagem|11225",
    "11253": "Ameaça|11224",
    "11254": "Constragimento ilegal|11224",
    "11255": "Desafio para duelo|11224",
    "11256": "Divulgação de segredo|11224",
    "11257": "Sequestro ou cárcere privado|11224",
    "11258": "Violação de correspondência|11224",
    "11259": "Violação de domicílio|11224",
    "11260": "Violação de recato|11224",
    "11261": "Violação de segredo profissional|11224",
    "11262": "Calúnia|11223",
    "11263": "Difamação|11223",
    "11264": "Injúria|11223",
    "11265": "Injuria real|11223",
    "11266": "Ofensa às forças armadas|11223",
    "11267": "Desrespeito a superior e a símbolo nacional ou farda|11074",
    "11268": "Aliciação e incitamento|11074",
    "11269": "Fuga, evasão, arrebatamento e amotinamento de presos|11074",
    "11270": "Insubordinação|11074",
    "11271": "Motim e Revolta|11074",
    "11272": "Resistência|11074",
    "11273": "Usurpação e excesso ou abuso de autoridade|11074",
    "11274": "Violência contra superior ou militar de serviço|11074",
    "11275": "Violência contra militar de serviço|11274",
    "11276": "Violência contra superior|11274",
    "11277": "Abuso de requisição militar|11273",
    "11278": "Assunção de comando sem ordem ou autorização|11273",
    "11279": "Conservação ilegal de comando|11273",
    "11280": "Ofensa aviltante a inferior|11273",
    "11281": "Operação militar sem ordem superior|11273",
    "11282": "Ordem arbitrária de invasão|11273",
    "11283": "Rigor excessivo|11273",
    "11284": "Uso indevido de uniforme, distintivo ou insígnia militar por qualquer pessoa|11273",
    "11285": "Uso indevido por militar de uniforme, distintivo ou insígnia|11273",
    "11286": "Violência contra inferior|11273",
    "11287": "Resistência mediante ameaça ou violência|11272",
    "11288": "Conspiração|11271",
    "11289": "Motim|11271",
    "11290": "Omissão de lealdade militar|11271",
    "11291": "Organização de grupo para prática de violência|11271",
    "11292": "Revolta|11271",
    "11293": "Oposição a ordem de sentinela|11270",
    "11294": "Publicação ou crítica indevida|11270",
    "11295": "Recusa a obediência|11270",
    "11296": "Reunião ilícita|11270",
    "11297": "Amotinamento|11269",
    "11298": "Arrebatamento de preso ou internado|11269",
    "11299": "Evasão de preso ou internado|11269",
    "11300": "Fuga de preso ou internado|11269",
    "11301": "Aliciação para motim ou revolta|11268",
    "11302": "Apologia de fato criminoso ou do seu autor|11268",
    "11303": "Incitamento|11268",
    "11304": "Despojamento desprezível|11267",
    "11305": "Desrespeito a comandante, oficial-general ou oficial de serviço|11267",
    "11306": "Desrespeito a símbolo nacional|11267",
    "11307": "Desrespeito a superior|11267",
    "11308": "Concussão, Excesso de Exação e Desvio|11073",
    "11309": "Corrupção|11073",
    "11310": "Crimes contra o dever funcional|11073",
    "11311": "Crimes praticados por particular contra a Administração Militar|11073",
    "11312": "Desacato e da Desobediência|11073",
    "11313": "Falsidade|11073",
    "11314": "Peculato|11073",
    "11315": "Peculato|11314",
    "11316": "Peculato mediante aproveitamento ou erro de outrem|11314",
    "11317": "Peculato-furto|11314",
    "11318": "Certidão ou atestado ideologicamente falso|11313",
    "11319": "Cheques sem fundos|11313",
    "11320": "Falsa identidade|11313",
    "11321": "Falsidade ideológica|11313",
    "11322": "Falsificação de documento|11313",
    "11323": "Supressão de documento|11313",
    "11324": "Uso de documento falso|11313",
    "11325": "Uso de documento pessoal alheio|11313",
    "11326": "Desacato a assemelhado ou funcionário|11312",
    "11327": "Desacato a militar|11312",
    "11328": "Desacato a superior|11312",
    "11329": "Desobediência|11312",
    "11330": "Ingresso clandestino|11312",
    "11331": "Impedimento, perturbação ou fraude de concorrencia|11311",
    "11332": "Inutilização de edital ou de sinal oficial|11311",
    "11333": "Subtração ou inutilização de livro, processo ou documento|11311",
    "11334": "Tráfico de influência|11311",
    "11335": "Usurpação de função|11311",
    "11336": "Abandono de cargo|11310",
    "11337": "Abuso de confiança ou boa fé|11310",
    "11338": "Aplicação ilegal de verba ou dinheiro|11310",
    "11339": "Condescendência criminosa|11310",
    "11340": "Exercício funcional ilegal|11310",
    "11341": "Extravio, sonegação ou inutilização de livro ou documento|11310",
    "11342": "Inobservancia de lei, regulamento ou instrução|11310",
    "11343": "Não inclusão de nome em lista|11310",
    "11344": "Obstáculo à hasta pública, concorrência ou tomada de preços|11310",
    "11345": "Patrocínio indébito|11310",
    "11346": "Prevaricação|11310",
    "11347": "Violação de sigilo de proposta de concorrência|11310",
    "11348": "Violação de sigilo funcional|11310",
    "11349": "Violação do dever funcional com o fim de lucro|11310",
    "11350": "Violação ou divulgação indevida de correspondência ou comunicação|11310",
    "11351": "Violência arbitrária|11310",
    "11352": "Corrupção ativa|11309",
    "11353": "Corrupção passiva|11309",
    "11354": "Participação ilícita|11309",
    "11355": "Concussão|11308",
    "11356": "Desvio|11308",
    "11357": "Excesso de exação|11308",
    "11358": "Auto-acusação falsa|11072",
    "11359": "Coação|11072",
    "11360": "Comunicação falsa de crime|11072",
    "11361": "Corrupção ativa de testemunha, perito ou intérprete|11072",
    "11362": "Denunciação caluniosa|11072",
    "11363": "Desacato|11072",
    "11364": "Desobediência a decisão judicial|11072",
    "11365": "Desobediência a decisão sobre perda ou suspensão de atividade ou direito|11072",
    "11366": "Exploração de prestígio|11072",
    "11367": "Falso testemunho ou falsa perícia|11072",
    "11368": "Favorecimento pessoal|11072",
    "11369": "Favorecimento real|11072",
    "11370": "Inutilização,sonegação ou descaminho de material probante|11072",
    "11371": "Publicidade opressiva|11072",
    "11372": "Recusa de função na Justiça Militar|11072",
    "11386": "Advertência|10688",
    "11387": "Internação com atividades externas|10688",
    "11388": "Internação sem atividades externas|10688",
    "11389": "Liberdade assistida|10688",
    "11390": "Obrigação de reparar o dano|10688",
    "11391": "Prestação de serviços à comunidade|10688",
    "11392": "Semiliberdade|10688",
    "11410": "Crimes contra portadores de deficiência|3603",
    "11412": "Conflito fundiário coletivo rural|10432",
    "11413": "Conflito fundiário coletivo urbano|10432",
    "11414": "Crime/contravenção decorrente de conflito fundiário coletivo|287",
    "11416": "Violação sexual mediante fraude|3463",
    "11417": "Estupro de vulnerável|3463",
    "11418": "Satisfação de lascívia mediante presença de criança ou adolescente|3463",
    "11419": "Favorecimento da prostituição ou outra forma de exploração sexual de vulnerável|3463",
    "11420": "Favorecimento da prostituição ou outra forma de exploração sexual|3463",
    "11421": "Tráfico internacional de pessoa para fim de exploração sexual|3463",
    "11422": "Tráfico interno de pessoa para fim de exploração sexual|3463",
    "11428": "DIREITO ELEITORAL|",
    "11429": "Crimes Eleitorais|11428",
    "11430": "Crimes contra a Organização Administrativa da Justiça Eleitoral|11429",
    "11431": "Intervenção Indevida de Autoridade Junto à Mesa Receptora|11430",
    "11432": "Não Observância de Chamamento de Eleitores para Votar|11430",
    "11433": "Práticas Irregulares que Determinam a Anulação da Votação|11430",
    "11434": "Votação de Eleitor na Seção Eleitoral na qual Não Está Inscrito ou Autorizado a Votar|11430",
    "11435": "Contagem de Votos de Eleitores em Relação aos quais Houve Impugnação|11430",
    "11436": "Fabricação, Aquisição, Fornecimento, Subtração ou Guarda de Materiais de Uso da Justiça Eleitoral|11430",
    "11437": "Crimes contra o Serviço da Justiça Eleitoral|11429",
    "11438": "Inscrição Fraudulenta|11437",
    "11439": "Induzimento à Inscrição de Eleitor em Infração às Normas Legais|11437",
    "11440": "Inscrição Fraudulenta Efetivada pelo Juiz|11437",
    "11441": "Negativa ou Retardamento de Inscrição sem Fundamento Legal|11437",
    "11442": "Perturbação ou Impedimento de Alistamento|11437",
    "11443": "Promoção de Desordem nos Trabalhos Eleitorais|11437",
    "11444": "Majoração de Preços de Utilidade e Serviços Necessários à Realização das Eleições|11437",
    "11445": "Ocultação, Sonegação ou Recusa de Fornecimento de Utilidades, Alimentos e Transporte no Dia da Eleição|11437",
    "11446": "Retardamento ou Não-Publicação de Decisões, Citações ou Intimações da Justiça Eleitoral|11437",
    "11447": "Não-Apresentação de Denúncia ou Não Execução de Sentença Penal Condenatória no Prazo Legal|11437",
    "11448": "Não Cumprimento, pela Autoridade Judiciária, do Dever de Representar Contra o Órgão do Ministério que Não Oferece Denúncia no Prazo Legal|11437",
    "11449": "Recusa ou Abandono do Serviço Eleitoral|11437",
    "11450": "Não Cumprimento dos Deveres Impostos pelo Código Eleitoral à Autoridade Judiciária e aos Funcionários da Justiça Eleitoral|11437",
    "11451": "Utilização de Prédios ou Serviços de Repartições Públicas para Beneficiar Partido ou Organização de Caráter Político|11437",
    "11452": "Desobediência a Ordens ou Instruções da Justiça Eleitoral|11437",
    "11453": "Descumprimento do Dever de Informar o Número, Espécie e Lotação de Veículos e Embarcações|11437",
    "11454": "Desatendimento à Requisição de Veículo ou Embarcação|11437",
    "11455": "Descumprimento da Proibição de Fornecimento de Transporte ou Refeições a Eleitores|11437",
    "11456": "Estupro de Vulnerável|9740",
    "11457": "Favorecimento de Prostituição ou Outra Forma de Exploração Sexual|9740",
    "11458": "Favorecimento de Prostituição ou Outra Forma de Exploração Sexual de Vulnerável|9740",
    "11459": "Satisfação de Lascívia Mediante Presença de Criança ou Adolescente|9740",
    "11460": "Tráfico Interno de Pessoa para Fim de Exploração Sexual|9740",
    "11461": "Violação Sexual Mediante Fraude|9740",
    "11464": "Obstamento do Transporte e Fornecimento de Alimentos a Eleitores pela Justiça Eleitoral|11437",
    "11465": "Utilização de Veículos e Embarcações Públicas em Campanha Eleitoral|11437",
    "11466": "Dano a Equipamento Eleitoral|11437",
    "11467": "Crimes contra a Fé Pública Eleitoral|11429",
    "11468": "Omissão na Expedição de Boletim de Apuração|11467",
    "11469": "Omissão no Recolhimento das Cédulas Apuradas, no Fechamento e Lacração da Urna|11467",
    "11470": "Alteração do Resultado de Votação nos Mapas ou Boletins de Apuração|11467",
    "11471": "Omissão no Recebimento e Registro de Protestos, bem como, de sua Remessa à Instância Superior|11467",
    "11472": "Falsificação ou Alteração de Documento Público para Fins Eleitorais|11467",
    "11473": "Falsidade Ideológica|11467",
    "11474": "Reconhecimento Indevido de Firma ou Letras para Fins Eleitorais|11467",
    "11475": "Uso de Documento Falso para Fins Eleitorais|11467",
    "11476": "Obtenção de Documento Falso para Fins Eleitorais|11467",
    "11477": "Omissão da Entrega do Boletim de Urna|11467",
    "11478": "Obtenção de Acesso a Sistema de Dados Eleitorais|11467",
    "11479": "Destruição de Dados Eleitorais|11467",
    "11480": "Cerceamento da Fiscalização Partidária|11467",
    "11481": "Recusa de Entrega de Boletim|11467",
    "11482": "Crimes contra a Propaganda Eleitoral|11429",
    "11483": "Divulgação de Fatos Inverídicos na Propaganda Eleitoral|11482",
    "11484": "Calúnia na Propaganda Eleitoral|11482",
    "11485": "Divulgação de Falsa Imputação|11482",
    "11486": "Difamação na Propaganda Eleitoral|11482",
    "11487": "Injúria na Propaganda Eleitoral|11482",
    "11488": "Injúria Eleitoral Violenta|11482",
    "11489": "Inutilização, Alteração ou Perturbação de Propaganda|11482",
    "11490": "Impedimento do Exercício da Propaganda|11482",
    "11491": "Utilização de Organização Comercial para Propaganda ou Aliciamento de Eleitores|11482",
    "11492": "Realização de Propaganda Eleitoral em Língua Estrangeira|11482",
    "11493": "Participação de Pessoa Não Detentora de Direitos Políticos em Atividades Partidárias e de Propaganda Eleitoral|11482",
    "11494": "Autorização de Transmissão de Pessoa Não Detentora de Direitos Políticos em Atividades Partidárias e de Propaganda Eleitoral|11482",
    "11495": "Pesquisa Fraudulenta|11482",
    "11496": "Uso, na Propaganda Eleitoral, de Símbolo de Órgãos de Governo|11482",
    "11497": "Crimes contra o Sigilo ou o Exercício do Voto|11429",
    "11498": "Retenção de Título Eleitoral ou do Comprovante de Alistamento Eleitoral|11497",
    "11499": "Impedimento ou Embaraço ao Exercício do Sufrágio|11497",
    "11500": "Prisão ou Detenção do Eleitor, Membro de Mesa Receptora, Fiscal, Delegado de Partido ou Candidato, Fora das Hipóteses Legais Permitidas|11497",
    "11501": "Corrupção Eleitoral|11497",
    "11502": "Coação Visando a Obtenção de Voto ou a sua Abstenção|11497",
    "11503": "Violência ou Grave Ameaça Visando a Obtenção de Voto ou a sua Abstenção|11497",
    "11504": "Concentração de Eleitores para Embaraçar ou Fraudar o Exercício do Voto|11497",
    "11505": "Fornecimento ao Eleitor de Cédula Oficial Já Assinalada ou Marcada|11497",
    "11506": "Fornecimento de Cédula Eleitoral Rubricada a Destempo|11497",
    "11507": "Votação Múltipla ou Realizada em Lugar de Outrem|11497",
    "11508": "Violação do Sigilo do Voto|11497",
    "11509": "Violação do Sigilo da Urna ou dos Invólucros|11497",
    "11510": "Destruição, Supressão ou Ocultação de Urna Contendo Votos ou Documentos Eleitorais|11497",
    "11511": "Destruição de Relação de Candidatos|11497",
    "11512": "Uso Proibido de Alto-Falantes|11497",
    "11513": "Arregimentação de Eleitor ou Boca de Urna|11497",
    "11514": "Divulgação de Propaganda Eleitoral no Dia da Eleição|11497",
    "11515": "Retenção de Título ou Comprovante de Alistamento Eleitoral|11497",
    "11516": "Subscrição de Mais de Uma Ficha de Filiação Partidária|11497",
    "11517": "Crimes contra os Partidos Políticos|11429",
    "11518": "Subscrição de Mais de Uma Ficha de Filiação Partidária|11517",
    "11519": "Inscrição Simultânea em Dois ou Mais Partidos|11517",
    "11520": "Coleta de Assinatura em Mais de Uma Ficha de Registro Partidário|11517",
    "11521": "Preterição à Prioridade Postal|11517",
    "11522": "Arguição de Ineligibilidade Temerária ou de Má-Fé|11517",
    "11523": "Não-Acesso dos Partidos aos Dados Relativos às Pesquisas Eleitorais|11517",
    "11524": "Irregularidades dos Dados Publicados em Pesquisas Eleitorais|11517",
    "11557": "Administração da Justiça Eleitoral|11428",
    "11558": "Conduta Vedada a Agente Público|11557",
    "11559": "Improbidade Administrativa|11557",
    "11560": "Descumprimento de Prazos Eleitorais|11557",
    "11561": "Garantia de Autoridade de Decisão do Tribunal|11557",
    "11562": "Magistrado|11557",
    "11563": "Afastamento de Magistrado|11562",
    "11564": "Designação de Juiz Eleitoral|11562",
    "11565": "Lista Tríplice|11562",
    "11566": "Servidor|11557",
    "11567": "Concurso Público|11566",
    "11568": "Remoção de Servidor|11566",
    "11569": "Requisição|11566",
    "11570": "Requisição de Servidor|11569",
    "11571": "Requisição de Servidor - Renovação de Requisição de Servidor|11569",
    "11572": "Minuta de Resolução|11557",
    "11573": "Requisição de Força Federal|11557",
    "11574": "Alistamento Eleitoral|11428",
    "11575": "Alistamento Eleitoral - Cancelamento|11574",
    "11576": "Alistamento Eleitoral - Domicílio Eleitoral|11574",
    "11577": "Alistamento Eleitoral - Duplicidade/Pluralidade|11574",
    "11578": "Alistamento Eleitoral - Exclusão|11574",
    "11579": "Alistamento Eleitoral - Inscrição Eleitoral|11574",
    "11580": "Impugnação - Alistamento Eleitoral|11574",
    "11581": "Impugnação - Inscrição Eleitoral|11580",
    "11582": "Impugnação - Transferência de Domicílio Eleitoral|11580",
    "11583": "Eleições|11428",
    "11584": "Candidatos|11583",
    "11585": "Condição de Elegibilidade|11584",
    "11586": "Condição de Elegibilidade - Alistamento Eleitoral|11585",
    "11587": "Condição de Elegibilidade - Domicílio Eleitoral na Circunscrição|11585",
    "11588": "Condição de Elegibilidade - Idade Mínima|11585",
    "11589": "Condição de Elegibilidade - Filiação Partidária|11585",
    "11590": "Condição de Elegibilidade - Nacionalidade Brasileira|11585",
    "11591": "Condição de Elegibilidade - Pleno Exercício dos Direitos Políticos|11585",
    "11592": "Condição de Elegibilidade - Quitação Eleitoral|11585",
    "11593": "Direito de Resposta|11584",
    "11594": "Identificação Numérica de Candidato|11584",
    "11595": "Inelegibilidade|11584",
    "11596": "Inelegibilidade - Abuso do Poder Econômico ou Político|11595",
    "11597": "Inelegibilidade - Analfabetismo|11595",
    "11598": "Inelegibilidade - Condenação Criminal Transitada em Julgado|11595",
    "11599": "Inelegibilidade - Declaração de Indignidade ou de Incompatibilidade com o Oficialato|11595",
    "11600": "Inelegibilidade - Desincompatibilização|11595",
    "11601": "Inelegibilidade - Inalistável|11595",
    "11602": "Inelegibilidade - Parentesco|11595",
    "11603": "Inelegibilidade - Reeleição|11595",
    "11604": "Inelegibilidade - Rejeição de Contas Públicas|11595",
    "11605": "Inelegibilidade - Representação ou Ação de Investigação Judicial Eleitoral Jugada Procedente pela Justiça Eleitoral|11595",
    "11606": "Inelegibilidade - Terceiro Mandato|11595",
    "11607": "Inelegibilidade - Vida Pregressa|11595",
    "11608": "Inelegibilidade - Vínculo Afetivo|11595",
    "11609": "Inelegibilidade - Vínculo Conjugal|11595",
    "11610": "Inelegibilidade - Vínculo em União Estável|11595",
    "11611": "Nome do Candidato|11584",
    "11612": "Nome do Candidato - Homonímia|11611",
    "11613": "Nome do Candidato - Variação Nominal|11611",
    "11614": "Prestação de Contas - De Candidato|11584",
    "11615": "Registro de Candidatura|11584",
    "11616": "Impugnação ao Registro de Candidatura|11615",
    "11617": "Registro de Candidatura - Preenchimento de Vaga Remanescente|11615",
    "11618": "Registro de Candidatura - RRC - Candidato|11615",
    "11619": "Registro de Candidatura - RRCI - Candidato Individual|11615",
    "11620": "Registro de Candidatura - Substituição de Candidato|11615",
    "11621": "Registro de Candidatura - Substituição de Candidato - Por Cancelamento de Registro|11620",
    "11622": "Registro de Candidatura - Substituição de Candidato - Por Cassação de Registro|11620",
    "11623": "Registro de Candidatura - Substituição de Candidato - Por Expulsão Partidária|11620",
    "11624": "Registro de Candidatura - Substituição de Candidato - Por Falecimento|11620",
    "11625": "Registro de Candidatura - Substituição de Candidato - Por Indeferimento de Registro|11620",
    "11626": "Registro de Candidatura - Substituição de Candidato - Por Ineligibilidade|11620",
    "11627": "Registro de Candidatura - Substituição de Candidato - Por Renúncia|11620",
    "11628": "Cargos|11583",
    "11629": "Cargo - Deputado Distrital|11628",
    "11630": "Cargo - Deputado Estadual|11628",
    "11631": "Cargo - Deputado Federal|11628",
    "11632": "Cargo - Governador|11628",
    "11633": "Cargo - Prefeito|11628",
    "11634": "Cargo - Presidente da República|11628",
    "11635": "Cargo - Primeiro Suplente de Senador|11628",
    "11636": "Cargo - Segundo Suplente de Senador|11628",
    "11637": "Cargo - Senador|11628",
    "11638": "Cargo - Vereador|11628",
    "11639": "Cargo - Vice-Governador|11628",
    "11640": "Cargo - Vice-Prefeito|11628",
    "11641": "Cargo - Vice-Presidente da República|11628",
    "11642": "Eleições - 1° Turno|11583",
    "11643": "Eleições - 2° Turno|11583",
    "11644": "Eleições - Anulação de Eleições|11583",
    "11645": "Eleições - Eleição Majoritária|11583",
    "11646": "Eleições - Eleição Proporcional|11583",
    "11647": "Eleições - Eleição Suplementar|11583",
    "11648": "Pesquisa Eleitoral|11583",
    "11649": "Pesquisa Eleitoral - Divulgação de Pesquisa Eleitoral Fraudulenta|11648",
    "11650": "Pesquisa Eleitoral - Divulgação de Pesquisa Eleitoral Sem Prévio Registro|11648",
    "11651": "Pesquisa Eleitoral - Registro de Pesquisa Eleitoral|11648",
    "11652": "Propaganda Política - Propaganda Eleitoral|11583",
    "11653": "Propaganda Política - Propaganda Eleitoral - Adesivo|11652",
    "11654": "Propaganda Política - Propaganda Eleitoral - Alto-falante/Amplificador de Som|11652",
    "11655": "Propaganda Política - Propaganda Eleitoral - Banner/Cartaz/Faixa|11652",
    "11656": "Propaganda Política - Propaganda Eleitoral - Bem Particular|11652",
    "11657": "Propaganda Política - Propaganda Eleitoral - Bem Particular de Uso Comum|11652",
    "11658": "Propaganda Política - Propaganda Eleitoral - Bem Público|11652",
    "11659": "Propaganda Política - Propaganda Eleitoral - Bem Tombado|11652",
    "11660": "Propaganda Política - Propaganda Eleitoral - Boca de Urna|11652",
    "11661": "Propaganda Política - Propaganda Eleitoral - Cavalete|11652",
    "11662": "Propaganda Política - Propaganda Eleitoral - Comício/Showmício|11652",
    "11663": "Propaganda Política - Propaganda Eleitoral - Conduta Vedada a Emissora de Rádio/Televisão na Programação Normal|11652",
    "11664": "Propaganda Política - Propaganda Eleitoral - Confecção, Utilização ou Distribuição de Brinde|11652",
    "11665": "Propaganda Política - Propaganda Eleitoral - Contrariedade à Lei de Postura Municipal|11652",
    "11666": "Propaganda Política - Propaganda Eleitoral - Debate Político|11652",
    "11667": "Propaganda Política - Propaganda Eleitoral - Extemporânea/Antecipada|11652",
    "11668": "Propaganda Política - Propaganda Eleitoral - Folhetos/Volantes/Santinhos/Impressos|11652",
    "11669": "Propaganda Política - Propaganda Eleitoral - Horário Eleitoral Gratuito|11652",
    "11670": "Propaganda Política - Propaganda Eleitoral - Distribuição de Tempo de Propaganda|11669",
    "11671": "Propaganda Política - Propaganda Eleitoral - Horário Eleitoral Gratuito/Inserções de Propaganda|11669",
    "11672": "Propaganda Política - Propaganda Eleitoral - Horário Eleitoral Gratuito/Programa em Bloco|11669",
    "11673": "Propaganda Política - Propaganda Eleitoral - Rádio|11669",
    "11674": "Propaganda Política - Propaganda Eleitoral - Rádio Comunitária|11669",
    "11675": "Propaganda Política - Propaganda Eleitoral - Televisão|11669",
    "11676": "Propaganda Política - Propaganda Eleitoral - Invasão de Horário Destinado a Outro Cargo/Partido/Coligação|11669",
    "11677": "Propaganda Política - Propaganda Eleitoral - Imprensa Escrita - Jornal/Revista/Tabloide|11652",
    "11678": "Propaganda Política - Propaganda Eleitoral - Inobservância do Limite Legal|11652",
    "11679": "Propaganda Política - Propaganda Eleitoral - Internet|11652",
    "11680": "Propaganda Política - Propaganda Eleitoral - Omissão de Informações Obrigatórias|11652",
    "11681": "Propaganda Política - Propaganda Eleitoral - Outdoors|11652",
    "11682": "Propaganda Política - Propaganda Eleitoral - Pintura em Muro|11652",
    "11683": "Propaganda Política - Propaganda Eleitoral - Truncagem, Montagem, Utilização de Gravação Externa, Computação Gráfica, Desenho Animado ou Efeito Especial|11652",
    "11684": "Recursos Financeiros de Campanha Eleitoral|11583",
    "11685": "Captação ou Gasto Ilícito de Recursos Financeiros de Campanha Eleitoral|11684",
    "11686": "Comitê Financeiro|11684",
    "11687": "Prestação de Contas - De Comitê Financeiro|11686",
    "11688": "Registro de Comitê Financeiro|11686",
    "11689": "Registro de Comitê Financeiro - Eleição Majoritária|11688",
    "11690": "Registro de Comitê Financeiro - Eleição Proporcional|11688",
    "11691": "Registro de Comitê Financeiro - Único|11688",
    "11692": "Contas|11684",
    "11693": "Contas - Apresentação de Contas|11692",
    "11694": "Contas - Aprovação|11693",
    "11695": "Contas - Aprovação das Contas|11694",
    "11696": "Contas - Aprovação das Contas com Ressalvas|11694",
    "11697": "Contas - Desaprovação/Rejeição das Contas|11693",
    "11698": "Contas - Não Apresentação das Contas|11692",
    "11699": "Doação de Recursos Acima do Limite Legal|11684",
    "11700": "Doação de Recursos Acima do Limite Legal - Pessoa Física|11699",
    "11701": "Doação de Recursos Acima do Limite Legal - Pessoa Jurídica|11699",
    "11702": "Garantias Eleitorais|11583",
    "11703": "Habeas Corpus|11702",
    "11704": "Habeas Corpus - Liberatório|11703",
    "11705": "Habeas Corpus - Preventivo|11703",
    "11706": "Impugnação|11702",
    "11707": "Impugnação - Identidade de Eleitor Admitido a Votar|11706",
    "11708": "Impugnação - Nomeação de Membro da Mesa Receptora|11706",
    "11709": "Impugnação - Nomeação de Membro da Junta Eleitoral|11706",
    "11710": "Impugnação - Sistema Eletrônico de Votação|11706",
    "11711": "Impugnação - Voto por Cédula|11706",
    "11712": "Registro de Candidatura - DRAP - Partido/Coligação|11583",
    "11713": "Resultados|11583",
    "11714": "Apuração/Totalização de Votos|11713",
    "11715": "Quociente Eleitoral/Partidário|11713",
    "11716": "Transgressões Eleitorais|11583",
    "11717": "Abuso|11716",
    "11718": "Abuso - De Poder Econômico|11717",
    "11719": "Abuso - De Poder Político/Autoridade|11717",
    "11720": "Abuso - Uso Indevido de Meio de Comunicação Social|11717",
    "11721": "Captação Ilícita de Sufrágio|11716",
    "11722": "Corrupção ou Fraude|11716",
    "11723": "Propaganda Política - Propaganda Institucional|11716",
    "11724": "Diplomação|11428",
    "11725": "Direitos Políticos|11428",
    "11726": "Direitos Políticos - Perda dos Direitos Políticos|11725",
    "11727": "Direitos Políticos - Restabelecimento dos Direitos Políticos|11725",
    "11728": "Direitos Políticos - Suspensão de Direitos Políticos|11725",
    "11729": "Execução|11428",
    "11730": "Execução - De Astreintes|11729",
    "11731": "Execução - De Multa Eleitoral|11729",
    "11732": "Execução de Julgado|11729",
    "11733": "Garantias Processuais|11428",
    "11734": "Cautelar Inominada|11733",
    "11735": "Cautelar Inominada - De Busca e Apreensão|11734",
    "11736": "Cautelar Inominada - De Produção Antecipada de Provas|11734",
    "11737": "Cautelar Inominada - Incidental|11734",
    "11738": "Cautelar Inominada - Preparatória|11734",
    "11739": "Exceção|11733",
    "11740": "Exceção - De Coisa Julgada|11739",
    "11741": "Exceção - De Ilegitimidade de Parte|11739",
    "11742": "Exceção - De Impedimento|11739",
    "11743": "Exceção - De Incompetência|11739",
    "11744": "Exceção - De Litispendência|11739",
    "11745": "Exceção - De Pré-Executividade|11739",
    "11746": "Exceção - De Suspeição|11739",
    "11747": "Partidos Políticos|11428",
    "11748": "Alteração Estatutária de Partido Político em Matéria Administrativa|11747",
    "11749": "Coligação Partidária|11747",
    "11750": "Coligação Partidária - Majoritária|11749",
    "11751": "Coligação Partidária - Majoritária e Proporcional|11749",
    "11752": "Coligação Partidária - Proporcional|11749",
    "11753": "Convenção Partidária|11747",
    "11754": "Filiação/Desfiliação|11747",
    "11755": "Filiação Partidária|11754",
    "11756": "Filiação Partidária - Cancelamento|11755",
    "11757": "Filiação Partidária - Duplicidade/Pluralidade|11755",
    "11758": "Filiação Partidária - Nulidade|11755",
    "11759": "Desfiliação Partidária|11754",
    "11760": "Justificação de Desfiliação Partidária|11759",
    "11761": "Perda de Cargo Eletivo por Desfiliação Partidária|11759",
    "11762": "Fusão de Partidos Políticos|11747",
    "11763": "Incorporação de Partidos Políticos|11747",
    "11764": "Órgão de Direção Partidária|11747",
    "11765": "Dissolução de Órgão de Direção Partidária|11764",
    "11766": "Partido Político - Órgão de Direção Estadual|11764",
    "11767": "Partido Político - Órgão de Direção Municipal|11764",
    "11768": "Partido Político - Órgão de Direção Nacional|11764",
    "11769": "Partido Político - Comissão Provisória|11747",
    "11770": "Propaganda Política|11747",
    "11771": "Propaganda Política - Propaganda Intrapartidária|11770",
    "11772": "Propaganda Política Partidária|11770",
    "11773": "Propaganda Política - Propaganda Partidária|11772",
    "11774": "Veiculação|11772",
    "11775": "Veiculação de Propaganda Partidária - Em Bloco|11774",
    "11776": "Veiculação de Propaganda Partidária - Em Inserções|11774",
    "11777": "Plebiscito|11428",
    "11778": "Requerimento|11428",
    "11779": "Contra o Ordenamento Urbano e o Patrimônio Cultural|9878",
    "11780": "Crimes contra o Ordenamento Urbano e o Patrimônio Cultural|3618",
    "11781": "Objetos de cartas precatórias/de ordem|8826",
    "11782": "Intimação|11781",
    "11783": "Citação|11781",
    "11784": "Oitiva|11781",
    "11785": "Diligências|11781",
    "11786": "Atos executórios|11781",
    "11797": "Entrada de aparelho telefônico de comunicação móvel em estabelecimento prisional|5874",
    "11802": "Ordem Urbanística|9985",
    "11803": "CONSELHOS|9985",
    "11804": "Programas de Arrendamento Residencial  PAR|4839",
    "11805": "Tabela Price|4839",
    "11806": "Empréstimo consignado|7752",
    "11807": "Tarifas|7752",
    "11808": "Capitalização e Previdência Privada|7771",
    "11809": "Transporte Aquaviário|7771",
    "11810": "Dever de Informação|1156",
    "11811": "Práticas Abusivas|1156",
    "11812": "Oferta e Publicidade|1156",
    "11814": "Transporte Ferroviário|7776",
    "11815": "Transporte Rodoviário|7776",
    "11816": "Infrações administrativas|9964",
    "11817": "Fundos|9964",
    "11818": "Medidas de proteção|9964",
    "11819": "Classificação indicativa|9964",
    "11820": "Entidades de atendimento|9964",
    "11821": "Conselhos tutelares|9964",
    "11822": "Mineração|10110",
    "11823": "Reserva legal|10110",
    "11824": "Recursos Hídricos|10110",
    "11825": "Poluição|10110",
    "11826": "Zoneamento Ecológico e Econômico|10110",
    "11827": "Zona Costeira|10110",
    "11828": "Área de Preservação Permanente|10110",
    "11829": "Produtos Controlados / Perigosos|10110",
    "11830": "Patrimônio Cultural|10110",
    "11831": "Controle Externo da atividade policial|9985",
    "11832": "Conselho do Idoso|11803",
    "11833": "Conselho da Comunidade|11803",
    "11834": "Conselho sobre Drogas|11803",
    "11835": "Conselho de Direitos da Criança e Adolescente|11803",
    "11836": "Parcelamento do Solo|11802",
    "11837": "Comércio Ambulante|11802",
    "11838": "Operações Urbanas Consorciadas|11802",
    "11839": "Posturas Municipais|11802",
    "11840": "Segurança em Edificações|11802",
    "11841": "Política Agrícola|9985",
    "11842": "Pessoa Idosa|9986",
    "11843": "Pessoas com deficiência|9986",
    "11844": "Minorias Étnicas|9986",
    "11845": "Comunicação Social|9986",
    "11846": "Moradia|9986",
    "11847": "Assistência Social|9986",
    "11848": "Alimentação|9986",
    "11849": "Não Discriminação|9986",
    "11851": "Terceirização do SUS|10064",
    "11852": "Financiamento do SUS|10064",
    "11853": "Vigilância Sanitária e Epidemiológica|10064",
    "11854": "Saúde Mental|10064",
    "11855": "Controle Social e Conselhos de Saúde|10064",
    "11856": "Hospitais e Outras Unidades de Saúde|10064",
    "11857": "Doação e Transplante de Órgãos; Tecidos e Partes do Corpo Humano|10064",
    "11858": "Recebimento de bolsa de estudos|10288",
    "11860": "Produto Impróprio|7771",
    "11861": "Combustíveis e derivados|7771",
    "11862": "Saneamento|10110",
    "11863": "Responsabilidade Fiscal|9985",
    "11864": "Irregularidade no atendimento|1156",
    "11865": "Vendas casadas|1156",
    "11866": "Jogos / Sorteios / Promoções comerciais|1156",
    "11867": "Produto Impróprio|6220",
    "11868": "Combustíveis e derivados|1156",
    "11869": "Saneamento|10110",
    "11870": "Utilização de bens públicos|10089",
    "11871": "Agências/órgãos de regulação|10136",
    "11872": "Sistema de proteção especial a vítimas e a testemunhas|9985",
    "11873": "Política fundiária e da reforma agrária|9985",
    "11874": "Responsabilidade fiscal|9985",
    "11883": "Tratamento Médico-Hospitalar|10064",
    "11884": "Fornecimento de Medicamentos|10064",
    "11885": "Unidade de terapia intensiva (UTI) ou unidade de cuidados intensivos (UCI)|11883",
    "11895": "Crimes Previstos no Estatuto do Torcedor|3603",
    "11896": "Previstos no Estatuto do Torcedor|9847",
    "11897": "Crédito Especial|10954",
    "11898": "Crédito Extraordinário|10954",
    "11899": "Ato Normativo|9997",
    "11900": "Resolução|11899",
    "11901": "Recomendação|11899",
    "11902": "Resolução Conjunta|11899",
    "11903": "Concurso para magistrado|10370",
    "11904": "Prova Objetiva|10370",
    "11905": "Prova Subjetiva|10370",
    "11906": "Prova Prática-Sentença|10370",
    "11907": "Prova Oral|10370",
    "11908": "Reserva de Vagas|10370",
    "11909": "Concurso para servidor|10370",
    "11910": "Concurso para serventia extrajudicial|10370",
    "11911": "Juizados Especiais|10187",
    "11912": "Juiz Leigo|11911",
    "11913": "Composição de Turmas Recursais|11911",
    "11914": "Licença|10187",
    "11915": "Participação Societária|10187",
    "11916": "Plantão Judiciário|10187",
    "11917": "Residência|10187",
    "11918": "Substituição/Convocação em Tribunal|10187",
    "11919": "Violação Prerrogativa Advogado|10187",
    "11920": "Eleição|10187",
    "11921": "Cargos de Direção|11920",
    "11922": "Órgão Especial|11920",
    "11923": "Fraude / Quebra de ordem cronológica|10672",
    "11924": "Pagamento|10672",
    "11925": "Vacância|10083",
    "11926": "Remoção|10083",
    "11927": "Desmembramento|10083",
    "11928": "Cumulação|10083",
    "11929": "Emolumentos|10083",
    "11930": "Fraude|10083",
    "11931": "Gratuidade|10083",
    "11932": "Princípio da Territorialidade|10083",
    "11933": "Provimento Irregular|10083",
    "11934": "Registro Civil de Nascimento|10083",
    "11935": "Registro de Imóveis|10083",
    "11936": "Cessão|10220",
    "11937": "Desvio de Função|10220",
    "11938": "Provimento de Cargos|10220",
    "11939": "Entrada de aparelho telefônico de comunicação móvel em estabelecimento prisional|9932",
    "11940": "Inclusão do 13º salário (gratificação natalina) no PBC|6127",
    "11941": "Art. 26 da Lei 8.870/1994|6120",
    "11942": "Art. 29, § 5º, da Lei 8.213/1991|6120",
    "11943": "Art. 29, II, da Lei 8.213/1991|6120",
    "11944": "Alteração do teto máximo para o valor do benefício previdenciário do RGPS (EC 20 e 41)|6120",
    "11945": "Revisão do valor do benefício no primeiro reajuste após a concessão (Art. 21, § 3º, da Lei 8.880/1994)|6149",
    "11946": "Deficiente|6114",
    "11947": "Idoso|6114",
    "11948": "Inspeção|10015",
    "11949": "Revisão/Desconstituição de Ato Administrativo|9997",
    "11950": "Morosidade no Julgamento do Processo|10187",
    "11951": "Investigação Patrimonial|10015",
    "11952": "Apuração de Infração Disciplinar|10187",
    "11954": "Acidente de Trabalho - Ressarcimento ao Erário|10439",
    "11958": "Difusão culposa de doença ou praga|3491",
    "11959": "Receptação culposa|3415",
    "11960": "Receptação culposa|9674",
    "11961": "Incêndio culposo|3491",
    "11962": "Incêndio culposo|9773",
    "11963": "Explosão culposa|3491",
    "11964": "Explosão culposa|9773",
    "11965": "Uso culposo de gás tóxico ou asfixiante|9773",
    "11966": "Uso culposo de gás tóxico ou asfixiante|3491",
    "11967": "Inundação culposa|3491",
    "11968": "Inundação culposa|9773",
    "11969": "Desabamento ou desmoronamento culposo|3491",
    "11970": "Desabamento ou desmoronamento culposo|9773",
    "11971": "Difusão culposa de doença ou praga|9773",
    "11972": "Desastre ferroviário culposo|3491",
    "11973": "Desastre ferroviário culposo|9773",
    "11974": "Cláusulas Abusivas|1156",
    "11977": "Alienação Parental|10577",
    "11978": "Invasão de Dispositivo Informático|3412",
    "11979": "Violência Doméstica Contra a Mulher|9634",
    "11980": "Usucapião Conjugal|10455",
    "11981": "Exploração Sexual|9964",
    "11982": "Separação de Corpos|9192",
    "11984": "Medidas Protetivas|10912",
    "11985": "Extinção das Obrigaões do Falido|4993",
    "11986": "Suspensão ou Extinção do Poder Familiar|10577",
    "11987": "Aborto|9635",
    "11988": "Conversão da união estável em casamento|5808",
    "11989": "Nulidade de ato administrativo|9997",
    "11990": "Usucapião de bem móvel|10455",
    "11991": "Adjudicação de herança|7673",
    "11992": "Registro Civil de Pessoas Jurídicas|7724",
    "11993": "Internação Compulsória|11854",
    "11994": "Internação Voluntária|11854",
    "11995": "Internação Involuntária|11854",
    "11996": "Encaminhamento aos pais ou responsável|11818",
    "11997": "Orientação e acompanhamento temporário|11818",
    "11998": "Matrícula e frequência obrigatória em escola oficial de ensino fundamental|11818",
    "11999": "Inclusão em programa de auxílio à família|11818",
    "12000": "Requisição de tratamento médico, psicológico ou psiquiátrico, em regime hospitalar ou ambulatorial|11818",
    "12001": "Inclusão em programa de auzílio, orientação e tratamento a alcoólatras e toxicômanos|11818",
    "12002": "Acolhimento institucional|11818",
    "12003": "Inclusão em programa de acolhimento familiar|11818",
    "12004": "Colocação em família substituta|11818",
    "12005": "Outras medidas de proteção|11818",
    "12006": "Evasão Escolar|9964",
    "12007": "Exploração Sexual ou Prostituição|9964",
    "12008": "Crimes da Lei do Planejamento Familiar|3603",
    "12009": "Roubo qualificado|9674",
    "12010": "Da Lei do Planejamento Familiar|9847",
    "12011": "Crimes Sobre Produção, Comércio e Transporte Clandestino de Açúcar e Álcool|3603",
    "12012": "Sobre Produção, Comércio e Transporte Clandestino de Açúcar e Álcool|9847",
    "12013": "Crime Relacionado à Exportação de Bens Sensíveis e Serviços Diretamente Relacionados|3603",
    "12014": "Relacionado à Exportação de Bens Sensíveis e Serviços Diretamente Relacionados|9847",
    "12015": "Crime da Lei Sobre Permissão de Lavra Garimpeira|3603",
    "12016": "da Lei Sobre Permissão de Lavra Garimpeira|9847",
    "12017": "Crimes da Lei que Proíbe Práticas Discriminatórias para Efeitos Trabalhistas|3603",
    "12018": "Da Lei que Proíbe Práticas Discriminatórias para Efeitos Trabalhistas|9847",
    "12019": "Crimes da Lei de Proteção à Propriedade Intelectual de Programa de Computador|3603",
    "12020": "Da Lei de Proteção à Propriedade Intelectual de Programa de Computador|9847",
    "12021": "Crimes da Lei de Locação de Imóveis Urbanos|3603",
    "12022": "Da Lei de Locação de Imóveis Urbanos|9847",
    "12023": "Crimes da Lei Geral da Copa|3603",
    "12024": "Da Lei Geral da Copa|9847",
    "12025": "Crimes do Estatuto do Torcedor|3603",
    "12026": "Estatuto do Torcedor|9847",
    "12027": "Composição Civil|4263",
    "12030": "Internação|10688",
    "12031": "Desapropriação para Regularização de Comunidade Quilombola / Dec. 4887/2003|10120",
    "12042": "Análise de Crédito|7779",
    "12043": "Registro de Candidatura %u2013 DRAP|11619",
    "12044": "Registro de Candidatura - DRAP Partido/Coligação|11615",
    "12045": "Prestação de Contas|11583",
    "12046": "Prestação de Contas - De Candidato|12045",
    "12047": "Prestação de Contas - De Comitê Financeiro|12045",
    "12048": "Prestação de Contas - De Exercício Financeiro|11747",
    "12049": "Impugnação|11583",
    "12050": "Impugnação - Identidade de Eleitor Admitido a Votar|12049",
    "12051": "Impugnação - Inscrição Eleitoral|12049",
    "12052": "Impugnação - Nomeação de Membro da Junta Eleitoral|12049",
    "12053": "Impugnação - Nomeação de Membro da Mesa Receptora|12049",
    "12054": "Impugnação - Sistema Eletrônico de Votação|12049",
    "12055": "Impugnação - Transferência de Domicílio Eleitoral|12049",
    "12056": "Impugnação - Voto por Cédula|12049",
    "12062": "Captação ou Gasto Ilícito de Recursos Financeiros de Campanha Eleitoral|11716",
    "12063": "Conduta Vedada ao Agente Público|11716",
    "12064": "Meios Processuais|11428",
    "12069": "Prestação de Contas - de Partido Político|11747",
    "12090": "Perda ou Modificação de Guarda|9964",
    "12091": "Feminicídio|3369",
    "12130": "Homicídio Agravado pela Prática de  Extermínio de Seres Humanos|3369",
    "12131": "Homicídio Agravado pelo Extermínio de Seres Humanos|9635",
    "12152": "Revisão de Tutela Antecipada Antecedente|8826"
  }
};
